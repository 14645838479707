import React, { useState } from 'react';
import { ReactComponent as ChevronRight } from '../../../../assets/icons/chevron-right.svg';
import { ReactComponent as AddDirectory } from '../../../../assets/icons/add-directory.svg';
import { ReactComponent as Edit } from '../../../../assets/icons/edit.svg';
import { ReactComponent as Plus } from '../../../../assets/icons/plus.svg';
import ButtonSVG from '../../../../components/ButtonSVG';
import ButtonPanel from '../../../../components/Button/components/ButtonPanel';
import FolderModal from '../../../../components/CustomModals/FolderModal';
import { Accordion } from '../../../../components/Accordion';
import { SidebarTab } from '../../../../components/SidebarTabs/SidebarTab';
import { InputSearch } from '../../../../components/InputSearch';
import { SidebarPanel } from '../../../../components/SidebarTabs/SidebarPanel';
import { AccordionHeader } from '../../../../components/Accordion/components/Header';
import { filterTab } from '../../../../utils/filterCategories';
import { translate } from '../../../../utils/translate';
import { useWebsitesTab } from '../../../../hooks/useWebsitesTab';
import { useModal } from '../../../../hooks/useModal';
import ModalWebsite from '../../../ModalWebsite';
import SingleContent from './components/SingleContent';
import WebsiteDropWrap from './components/WebsiteDropWrap';
import styles from './styles.module.scss';

export const WebsitesTab = ({ hide }) => {
  const [modalWebsite, openModalWebsite, closeModalWebsite] = useModal(false);

  const [modalFolder, openModalFolder, closeModalFolder] = useModal(false);

  const [search, setSearch] = useState('');

  const {
    websites: websitesList,
    categories: websiteCategories,
    onCreateCategory,
    onEditCategory,
    onRemoveCategory,
  } = useWebsitesTab();

  const makeWebsite = (website) => (
    <SingleContent
      website={website}
      openModal={openModalWebsite}
    />
  );

  const {
    items: filteredWebsites, categories: filteredCategories,
  } = filterTab(search, websitesList, websiteCategories, 'website_category');

  const AddWebsitesToCategory = filteredCategories.map((category) => ({
    ...category,
    websites: filteredWebsites
      .filter(({ website_category }) => website_category === category.id)
      .map((website) => ({
        content: makeWebsite(website),
      })),
  }));

  const nestCategory = (items, id = null, link = 'parent') => items.filter((item) => item[link] === id)
    .map(({ websites, ...category }) => {
      const categories = nestCategory(items, category.id);

      const lists = [...websites, ...categories];

      const hasChildren = lists.length > 0;

      return {
        category,
        header: (
          <AccordionHeader
            hasChildren={hasChildren}
            title={(
              <span title={category.name}>
                {category.name}
              </span>
            )}
            leftIcons={<ChevronRight width={24} height={24} style={{ fill: hasChildren ? undefined : '#5E5E5D' }} />}
            rightIcons={(
              <ButtonSVG
                id={category.id}
                className={styles.editButton}
                onClick={openModalFolder}
                title={translate('Edit Category')}
              >
                <Edit width={16} height={16} />
              </ButtonSVG>
            )}
          />
        ),
        content: hasChildren ? lists : null,
      };
    });

  const nestedCategory = nestCategory(AddWebsitesToCategory);

  const noCategoryWebsites = filteredWebsites
    .filter(({ website_category }) => !website_category)
    .map((website) => ({
      content: makeWebsite(website),
    }));

  return (
    <SidebarTab
      title={translate('Websites')}
      hide={hide}
      iconsContent={(
        <ButtonSVG
          onClick={openModalFolder}
          title={translate('Create Folder')}
        >
          <AddDirectory width={20} height={20} />
        </ButtonSVG>
      )}
      headerContent={(
        <InputSearch
          className={styles.search}
          value={search}
          onChange={setSearch}
          placeholder={translate('Search website')}
        />
      )}
    >
      <SidebarPanel>

        {[...nestedCategory, ...noCategoryWebsites].map((item, index) => (
          <Accordion
            item={item}
            key={index}
            withBorder
            wrapper={WebsiteDropWrap}
          />
        ))}

      </SidebarPanel>

      <ButtonPanel
        title={translate('Create Website')}
        onClick={openModalWebsite}
      >
        <Plus width={24} height={24} />
        {translate('Create a new Website')}
      </ButtonPanel>

      <ModalWebsite
        show={modalWebsite}
        onClose={closeModalWebsite}
      />

      <FolderModal
        show={modalFolder}
        directories={websiteCategories}
        onClose={closeModalFolder}
        onEdit={onEditCategory}
        onCreate={onCreateCategory}
        onRemove={onRemoveCategory}
      />

    </SidebarTab>
  );
};
