import { createAction } from 'redux-actions';
// import { toast } from 'react-toastify';
import { baseApi } from './apiService';
import appStore from './store';
// import { translate } from './translate';

const getActionsState = (action) => ({
  requestAction: () => appStore.dispatch(createAction(action.REQUEST)()),
  successAction: (response) => appStore.dispatch(createAction(action.SUCCESS)(response)),
  failureAction: (response) => appStore.dispatch(createAction(action.FAILURE)(response)),
});

export const dataWrapper = async (
  action,
  response,
  requestData,
  addDataToResponse = false,
) => {
  const stateActions = getActionsState(action);

  if (addDataToResponse) {
    stateActions.successAction({
      ...response,
      requestData,
    });
  } else {
    stateActions.successAction(response);
  }
  return response;
};

export const requestWrapper = async (
  action,
  url,
  method,
  validate,
  requestData,
  addDataToResponse = false,
  onSuccess = () => {},
  onFailure = () => {},
  options = {},
) => {
  const stateActions = getActionsState(action);
  // console.log('============================================== action', action, stateActions);
  let response;
  stateActions.requestAction();
  try {
    response = await baseApi({
      url, method, data: requestData, ...options,
    });
  } catch (e) {
    stateActions.failureAction(addDataToResponse
      ? {
        ...response,
        requestData,
      }
      : { ...response });
    if (e.status > 400 && e.status < 500) {
      /* const coastId = toast(e.message, {});
      console.log('coastId', coastId);
      toast.error(e.message); */
    } else if (e.status >= 500) {
      // const coastId = toast(e.message, {});
      // console.log('coastId222', coastId);
      // toast.error(translate('Don\'t worry. Something went wrong. Please try again later.'));
    }
    onFailure(e);
    console.log('==============================================');
    console.log(url);
    console.log('==============================================');
    console.error(e);
    console.log('==============================================');
    throw e.data;
  }

  if (validate) {
    if (validate(response)) {
      return response;
    }

    stateActions.failureAction({
      ...response,
      requestData,
    });

    /* eslint-disable prefer-promise-reject-errors */
    return Promise.reject('Receive Data Not Valid');
  }

  if (addDataToResponse) {
    stateActions.successAction({
      ...response,
      requestData,
    });
  } else {
    stateActions.successAction(response);
    onSuccess();
  }

  return response;
};
