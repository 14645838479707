import React from 'react';
import { useDrag } from 'react-dnd';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { ReactComponent as Edit } from '../../../../../../assets/icons/edit.svg';
import { ReactComponent as Transparent } from '../../../../../../assets/icons/transparent.svg';
import { ReactComponent as Users } from '../../../../../../assets/icons/users.svg';
import { ReactComponent as Settings } from '../../../../../../assets/icons/settings.svg';
import { ReactComponent as ChevronRight } from '../../../../../../assets/icons/chevron-right.svg';
import { ReactComponent as Page } from '../../../../../../assets/icons/page.svg';
import { AccordionHeader } from '../../../../../../components/Accordion/components/Header';
import ButtonSVG from '../../../../../../components/ButtonSVG';
import { dropTypes } from '../../../../../../utils/dropTypes';
import { translate } from '../../../../../../utils/translate';
import styles from '../../styles.module.scss';

const PageItemCategory = ({
  page, isOpen, openModalFolder, openModalPage,
}) => {
  const [{ isDragging }, drag] = useDrag({
    item: { type: dropTypes.PAGE, page },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const {
    is_folder, has_child, name, id, enable,
  } = page;

  const title = is_folder
    ? (
      <span
        className={cn(styles.link, styles.hover)}
      >

        <span
          className={styles.text}
          title={name}
        >
          {name}
        </span>

      </span>
    )
    : (
      <Link
        className={cn(styles.link, styles.hover)}
        to={`/website/${page.website}/page/${id}`}
      >
        <ButtonSVG className={styles.button}>
          <Page
            width={16}
            heigth={16}
          />
        </ButtonSVG>

        <span
          className={styles.text}
          title={name}
        >
          {name}
        </span>

      </Link>
    );

  const button = is_folder
    ? (
      <ButtonSVG
        className={styles.button}
        id={id}
        title={translate('Edit Category')}
        onClick={openModalFolder}
      >
        <Edit width={16} height={16} />
      </ButtonSVG>
    )
    : (
      <ButtonSVG
        className={styles.button}
        id={id}
        title={translate('Edit Page')}
        onClick={openModalPage}
      >
        <Settings width={16} height={16} />
      </ButtonSVG>
    );

  const indicator = !is_folder && enable
    ? <Users width={16} height={16} />
    : <Transparent width={16} height={16} />;

  const opacity = isDragging ? 0.4 : 1;

  return (
    <AccordionHeader
      ref={drag}
      style={{
        opacity,
      }}
      isOpen={isOpen}
      hasChildren={has_child}
      title={title}
      leftIcons={(
        <ChevronRight
          width={24}
          height={24}
          style={{ fill: has_child ? undefined : '#5E5E5D' }}
        />
      )}
      rightIcons={(
        <div className={styles.wrapIcons}>
          {button}

          <ButtonSVG
            className={styles.button}
          >
            {indicator}
          </ButtonSVG>

        </div>
      )}
    />
  );
};

export default PageItemCategory;
