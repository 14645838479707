import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const Mandatory = ({ title }) => (
  <span className={styles.container}>
    (
    {title}
    )
  </span>
);

Mandatory.defaultProps = {
  title: '*',
};

Mandatory.propTypes = {
  title: PropTypes.string,
};

export default Mandatory;
