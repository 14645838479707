import React from 'react';
import { ErrorMessage } from 'formik';
import Title from '../../../../components/Title/Title';
import Subtitle from '../../../../components/Subtitle/Subtitle';
import { translate } from '../../../../utils/translate';
import Mandatory from '../../../../components/Mandatory';
import { Input } from '../../../../components/Input';
import Select from '../../../../components/Select';
import getNestNameDirectory from '../../../../utils/getNestNameDirectory';
import { useWebsitesTab } from '../../../../hooks/useWebsitesTab';
import Row from '../Row';
import UploadIcon from '../UploadIcon';
import ErrorTooltip from '../ErrorTooltip';
import styles from './styles.module.scss';

const General = ({ domainOptions }) => {
  const {
    categories,
  } = useWebsitesTab();

  const folderOptions = getNestNameDirectory(categories);

  return (
    <div>

      <section>

        <Title
          className={styles.title}
        >
          { translate('General Info') }
        </Title>

        <Subtitle>
          { translate('Website name') }
          { ' ' }
          <Mandatory title={translate('Mandatory')} />
        </Subtitle>

        <Row
          first={(
            <Input
              type="text"
              name="name"
              id="name"
              placeholder={translate('Website name')}
              styleType="2"
            />
          )}
          second={<ErrorMessage name="name" component={ErrorTooltip} />}
        />

        <Subtitle>
          { translate('Subdomain') }
        </Subtitle>

        <Row
          first={(
            <div className={styles.rowDomain}>

              <Input
                type="text"
                name="sub_domain"
                id="sub_domain"
                placeholder={translate('Subdomain')}
                styleType="2"
              />

              <Select
                className={styles.selectDomain}
                name="main_domain"
                id="main_domain"
                options={domainOptions}
                placeholder={translate('Domain')}
                dark
              />

            </div>
          )}
          second={
            <ErrorMessage name="sub_domain" component={ErrorTooltip} />
          }
        />

        <Subtitle>{ translate('Folder') }</Subtitle>

        <Row
          first={(
            <Select
              name="website_category"
              id="website_category"
              options={folderOptions}
              placeholder={translate('Folder')}
              dark
              isClearable
            />
          )}
          second={(
            <ErrorMessage name="website_category" component={ErrorTooltip} />
          )}
        />

        <Row
          first={(
            <Input
              type="checkbox"
              name="use_sitemap"
              id="use_sitemap"
              label={translate('Generate sitemap')}
              placeholder={translate('Generate sitemap for this website')}
              invert
            />
          )}
          second={<ErrorMessage name="use_sitemap" component={ErrorTooltip} />}
        />

      </section>

      <hr className={styles.hr} />

      <section>

        <Title className={styles.title}>{ translate('Icons') }</Title>

        <Row
          first={(
            <UploadIcon
              label={(
                <>
                  { translate('Favicon') }
                  { ' ' }
                  <Mandatory title={translate('Mandatory')} />
                </>
              )}
              name="icon"
              description="Upload a 32 x 32 pixel ICO, PNG, GIF, or JPG to display in browser tabs."
              id="icon"
            />
          )}
          second={<ErrorMessage name="icon" component={ErrorTooltip} />}
        />

        <Row
          first={(
            <UploadIcon
              label={(
                <>
                  { translate('Webclip') }
                  { ' ' }
                  <Mandatory title={translate('Mandatory')} />
                </>
              )}
              name="web_clip"
              description="Upload a 256 x 256 pixel webclip image. This icon shows up when your website link is saved to an iPhone home screen."
              id="web_clip"
            />
          )}
          second={<ErrorMessage name="web_clip" component={ErrorTooltip} />}
        />

      </section>
    </div>
  );
};

export default General;
