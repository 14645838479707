import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-tabs/style/react-tabs.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-image-crop/dist/ReactCrop.css';
import 'react-toastify/dist/ReactToastify.css';

import store from './utils/store';
import Routes from './routes';
import history from './utils/history';

const App = () => (
  <Provider store={store} key="provider">
    <ConnectedRouter history={history}>
      <ToastContainer />
      <ReactTooltip
        id="tooltip-global"
        // type="info"
        wrapper="span"
        place="right"
        effect="solid"
        globalEventOff="click"
        multiline
        delayHide={300}
      />
      <Routes />
    </ConnectedRouter>
  </Provider>
);

export default App;
