import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as File } from '../../../../assets/icons/file.svg';
import { ReactComponent as Delete } from '../../../../assets/icons/trash.svg';
import ButtonSVG from '../../../../components/ButtonSVG';
import formatBytes from '../../../../utils/formatBytes';
import styles from './styles.module.scss';

const AssetFile = ({ item, onDelete }) => {
  const {
    id, name, extension, size,
  } = item;

  return (
    <div className={styles.container}>

      <div className={styles.icon}>
        <File />
      </div>

      <div className={styles.wrap}>

        <h3 className={styles.title}>

          <span>{`${name}.`}</span>

          {extension}

        </h3>

        <p className={styles.description}>
          {formatBytes(size)}
        </p>

      </div>

      <ButtonSVG
        id={id}
        className={styles.button}
        onClick={onDelete}
      >
        <Delete />
      </ButtonSVG>
    </div>
  );
};

AssetFile.propTypes = {
  onDelete: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    extension: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
  }).isRequired,
};

export default AssetFile;
