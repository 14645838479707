import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Close } from '../../../../../../../../assets/icons/close.svg';
import { ReactComponent as ChevronDown } from '../../../../../../../../assets/icons/chevron-down.svg';
import { InputUnControlled } from '../../../../../../../../components/Input';
import ButtonSVG from '../../../../../../../../components/ButtonSVG';
import { usePage } from '../../../../../../../../hooks/usePage';
import { useWebsite } from '../../../../../../../../hooks/useWebsite';
import styles from './styles.module.scss';

const PageSelectView = ({
  field, placeholder, webId, onOpen, onClear,
}) => {
  const { currentPage } = usePage(field.value, webId);

  const { currentWebsite } = useWebsite(webId);

  const parentName = useMemo(() => {
    if (currentPage) {
      const tree = currentPage?.location;

      if (tree) {
        return `${currentWebsite.domain}/${tree}/`;
      }
    }

    return `${currentWebsite.domain}/`;
  }, [currentWebsite, currentPage]);

  return (
    <div className={styles.container} onClick={onOpen}>

      <InputUnControlled
        placeholder={placeholder}
        styleType="2"
        disabled
        value={parentName}
        classNameControl={styles.input}
      />

      { field.value
        ? (
          <ButtonSVG
            className={styles.button}
            onClick={onClear}
          >
            <Close width={24} height={24} />
          </ButtonSVG>
        )
        : (
          <ButtonSVG
            className={styles.button}
            onClick={onOpen}
          >
            <ChevronDown width={22} height={22} />
          </ButtonSVG>
        )}

    </div>
  );
};

PageSelectView.defaultProps = {
  placeholder: '',
  field: {},
};

PageSelectView.propTypes = {
  placeholder: PropTypes.string,
  field: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }),
  onOpen: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default PageSelectView;
