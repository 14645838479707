import React, { useEffect, useRef, useMemo } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.module.scss';

const modalElement = document.getElementById('modal');

const Modal = ({
  className, classNameContainer, show, children, onClose, closeOnClickOutside = true,
}) => {
  const element = useMemo(() => document.createElement('div'), []);
  const stamp = useMemo(() => Date.now(), []);

  useEffect(() => {
    element.setAttribute('id', `modal-${stamp}`);
    modalElement.appendChild(element);

    return () => {
      modalElement.removeChild(element);
    };
  }, [element, stamp]);

  const modal = useRef(null);

  const handleClick = (e) => {
    if (closeOnClickOutside && e.target === modal.current) onClose();
  };

  const content = show ? (
    <div
      ref={modal}
      className={cn(styles.modal, className)}
      onClick={handleClick}
    >

      <div className={cn(styles.content, classNameContainer)}>
        {children}
      </div>

    </div>
  ) : '';

  return createPortal(content, element);
};

Modal.defaultProps = {
  className: '',
  classNameContainer: '',
};

Modal.propTypes = {
  className: PropTypes.string,
  classNameContainer: PropTypes.string,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Modal;
