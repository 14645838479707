import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '../../utils/translate';
import styles from './styles.module.scss';

const EmptyPage = ({ title }) => (
  <div className={styles.container}>{translate(title)}</div>
);

EmptyPage.propTypes = {
  title: PropTypes.string.isRequired,
};

export default EmptyPage;
