import React, { useState, useMemo, useCallback } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createSelector } from 'reselect';
import { ReactComponent as Snippet } from '../../assets/icons/snippet.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg';
// import { ReactComponent as NavigationIcon } from '../../assets/icons/navigation.svg';
import ButtonSVG from '../../components/ButtonSVG';
import ModalPage from '../ModalPage';
import SnippetsList from './components/SnippetsList';
import SnippetProps from './components/SnippetProps';
import { translate } from '../../utils/translate';
import { useModal } from '../../hooks/useModal';
import { useWebsite } from '../../hooks/useWebsite';
import { URL_QUERY_PARAMS } from '../../constants/ui';
import {
  selectedWebsiteIdSelector, selectedPageIdSelector, selectedSnippetAtPageIdSelector,
} from '../../services/selectors/ui';
import styles from './styles.module.scss';

const selector = createSelector(
  selectedWebsiteIdSelector,
  selectedPageIdSelector,
  selectedSnippetAtPageIdSelector,
  (websiteId, pageId, snippetAtPageId) => ({
    websiteId,
    pageId,
    snippetAtPageId,
  }),
);

const RightSidebar = () => {
  const [activeTab, setActiveTab] = useState('list');

  const [modal, openModal, closeModal] = useModal(false);

  const { websiteId, pageId, snippetAtPageId } = useSelector(selector);

  const { currentWebsite } = useWebsite(+websiteId);

  const history = useHistory();

  const handleClick = (id) => setActiveTab(id);

  const { search, pathname } = history.location;

  const params = new URLSearchParams(search);

  const openConfigureSnippet = useCallback((id) => {
    params.set(URL_QUERY_PARAMS.SNIPPET_AT_PAGE, id);

    history.push(`${pathname}?${params.toString()}`);

    setActiveTab('configure');
  }, [history, params, pathname]);

  const closeConfigureSnippet = useCallback(() => {
    setActiveTab('list');

    params.delete(URL_QUERY_PARAMS.SNIPPET_AT_PAGE);

    history.push(`${pathname}?${params.toString()}`);
  }, [history, params, pathname]);

  const snippetProps = useMemo(() => (
    <SnippetProps
      websiteId={+websiteId}
      snippetAtPageId={snippetAtPageId}
      onClose={closeConfigureSnippet}
      hidden={!snippetAtPageId}
    />
  ), [websiteId, snippetAtPageId, closeConfigureSnippet]);

  const list = useMemo(() => (
    <SnippetsList
      pageId={+pageId}
      onConfigure={openConfigureSnippet}
      hidden={!!snippetAtPageId}
    />
  ), [pageId, openConfigureSnippet, snippetAtPageId]);

  const pageModal = useMemo(() => (
    <ModalPage
      show={modal}
      currentWebsite={currentWebsite}
      onClose={closeModal}
    />
  ), [modal, currentWebsite, closeModal]);

  return (
    <aside className={styles.sidebar}>

      {pageModal}

      <header className={styles.header}>

        <ButtonSVG
          id="list"
          title={translate('List')}
          onClick={handleClick}
          className={cn(styles.link, { [styles.active]: activeTab === 'list' })}
        >
          <Snippet />
        </ButtonSVG>

        {/*<ButtonSVG
          id="navigation"
          title={translate('Navigation')}
          onClick={handleClick}
          className={cn(styles.link, { [styles.active]: activeTab === 'navigation' })}
        >
          <NavigationIcon />
        </ButtonSVG>*/}

        <ButtonSVG
          id={+pageId}
          title={translate('Page Modal')}
          onClick={openModal}
          className={styles.link}
        >
          <SettingsIcon width={24} height={24} />
        </ButtonSVG>

      </header>

      <div className={styles.content}>

        {snippetProps}

        {list}

      </div>

    </aside>
  );
};

export default RightSidebar;
