import omit from 'lodash/omit';
import { editWebsite, editCategory as editWebsiteCategory } from '../services/actions/websites';
import { editPage } from '../services/actions/pages';
import { editSnippet, editCategory as editSnippetCategory } from '../services/actions/snippets';
import { editMedia, editCategory as editMediaCategory } from '../services/actions/media';
import { dropTypes } from '../utils/dropTypes';

const useLeftSidebar = () => (dragItem) => {
  const {
    WEBSITE, WEBSITE_DIRECTORY, PAGE, SNIPPET, SNIPPET_CONTAINER,
    SNIPPET_DIRECTORY, MEDIA, MEDIA_DIRECTORY,
  } = dropTypes;

  // eslint-disable-next-line default-case
  switch (dragItem.type) {
    case WEBSITE: {
      const { icon, web_clip, ...website } = dragItem.website;

      if (website.website_category) {
        editWebsite({
          ...website,
          website_category: '',
        });
      }
    }
      break;

    case WEBSITE_DIRECTORY: {
      const { category } = dragItem;

      if (category.parent) {
        editWebsiteCategory({
          ...category,
          parent: '',
        });
      }
    }
      break;

    case PAGE: {
      const { page } = dragItem;

      if (page.parent) {
        const p = omit(page, ['main_image']);
        editPage({
          ...p,
          parent: '',
        });
      }
    }
      break;

    case SNIPPET_CONTAINER:
    case SNIPPET: {
      const { preview, icon, ...snippet } = dragItem.snippet;

      if (snippet.snippet_category) {
        editSnippet({
          ...snippet,
          snippet_category: '',
        });
      }
    }
      break;

    case SNIPPET_DIRECTORY: {
      const { category } = dragItem;

      if (category.parent) {
        editSnippetCategory({
          ...category,
          parent: '',
        });
      }
    }
      break;

    case MEDIA: {
      const { media } = dragItem;

      if (media.parent) {
        editMedia({
          oldMedia: media,
          name: media.path,
          path: '/',
          old_name: media.path,
          old_path: media.parent || '/',
          relative_path: media.relative_path,
        });
      }
    }
      break;

    case MEDIA_DIRECTORY: {
      const { category } = dragItem;

      if (category.parent) {
        editMediaCategory({
          name: category.name,
          path: '/',
          old_name: category.name,
          old_path: category.parent || '/',
          relative_path: category.relative_path,
        });
      }
    }
      break;
  }
};

export default useLeftSidebar;
