import React, {
  useState, useMemo, useEffect, useRef,
  useCallback,
} from 'react';
import cn from 'classnames';
import Fullscreen from 'react-full-screen';
import { useRouteMatch } from 'react-router-dom';
import { PAGE_EDITOR_ROUTE } from '../../constants/routes';
import usePageLanguage from '../../hooks/usePageLanguage';
import FooterPanel from './components/FooterPanel';
import HeaderPanel from './components/HeaderPanel';
import Iframe from './components/Iframe';
import styles from './styles.module.scss';

const HEIGHT_PANEL = 40;

class Observer {
  constructor(callback) {
    this._observer = null;
    this._callback = callback;
    // eslint-disable-next-line no-undef
    this._observer = new ResizeObserver(callback);
  }

  _init(el) {
    this._observer.observe(el);
  }

  _destruct() {
    this._observer.disconnect();
  }
}

const PageEditor = () => {
  let webId;
  let pageId;
  let tEl;

  const match = useRouteMatch(PAGE_EDITOR_ROUTE);

  if (match) {
    webId = match.params.webId;
    pageId = match.params.pageId;
  }

  const {
    language,
    languages,
    defaultLanguage,
    onChangeLanguage,
  } = usePageLanguage();

  useEffect(() => {
    if (
      defaultLanguage && languages.length > 0
      && (
        !language
        || !languages.find((lang) => lang === defaultLanguage)
        || !languages.find((lang) => lang === language)
      )
    ) {
      onChangeLanguage(defaultLanguage);
    }
  }, [language, languages, defaultLanguage, onChangeLanguage]);

  const [device, setDevice] = useState('desktop');

  const [fullScreen, setFullScreen] = useState(false);

  const handleFullScreen = (state) => {
    const currentState = !!state;

    if (currentState) {
      setDevice(' ');
    } else if (device === ' ') {
      setDevice('desktop');
    }

    setFullScreen(currentState);
  };

  const obsRef = useRef();

  useEffect(() => {
    if (obsRef.current) {
      obsRef.current._observer.observe(document.body);
    }
  }, [device, fullScreen]);

  const processZoom = useCallback((el, reset) => {
    if (!el) return;
    // return 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    tEl = el;
    const zoomEl = el.children[1];
    const iframeEl = el.querySelector('iframe');

    if (reset || el._device !== device) {
      zoomEl.style.transform = '';
      zoomEl.style.marginLeft = '';
      zoomEl.style.marginTop = '';
      iframeEl.style.height = '';
      iframeEl.style.width = '';
      iframeEl.style.margin = '';
    }

    const { width, height } = el.getBoundingClientRect();

    // eslint-disable-next-line no-unused-vars
    const { width: widthZoomEl, height: heightZoomEl } = zoomEl.getBoundingClientRect();

    if (widthZoomEl > width && !zoomEl.style.transform) {
      const scale = width / widthZoomEl;

      const iframeHeight = (height - HEIGHT_PANEL * 2) / scale;
      const dYfix = (iframeHeight - height) / 2 + HEIGHT_PANEL;

      iframeEl.style.height = `${iframeHeight}px`;
      iframeEl.style.width = `${width / scale}px`;

      const dx = (widthZoomEl - width) / 2;
      const dy = dYfix;

      zoomEl.style.transform = `scale(${scale})`;
      zoomEl.style.marginLeft = `-${dx}px`;
      zoomEl.style.marginTop = `${-dy}px`;
      iframeEl.style.margin = '0 auto'; // for full screen
      // eslint-disable-next-line no-param-reassign
      el._device = device;
    }
  }, [device]);

  useEffect(() => {
    const obs = new Observer((entries) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const entry of entries) {
        processZoom(entry.target, true);
      }
    });

    obsRef.current = obs;

    obs._init(tEl);

    return () => {
      obs._destruct();
    };
  }, [processZoom, tEl]);

  const frame = useMemo(() => (
    <Iframe
      className={cn(styles.fullScreen)}
      pageId={pageId}
      language={language}
    />
  ), [pageId, language]);

  return (
    <Fullscreen
      enabled={fullScreen}
      onChange={handleFullScreen}
    >

      <div
        ref={processZoom}
        className={styles.container}
      >

        <HeaderPanel
          webId={+webId}
          pageId={+pageId}
        />

        <div
          className={cn(styles.wrap, styles[device])}
        >

          {frame}

        </div>

        <FooterPanel
          fullScreen={fullScreen}
          device={device}
          languages={languages}
          language={language}
          handleFullScreen={handleFullScreen}
          setDevice={setDevice}
          onChangeLanguage={onChangeLanguage}
        />

      </div>

    </Fullscreen>
  );
};

export default PageEditor;
