import React, { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Button } from '../../../../../../../components/Button';
import { translate } from '../../../../../../../utils/translate';
import styles from './styles.module.scss';
import Subtitle from '../../../../../../../components/Subtitle/Subtitle';
import MediaSelect
  from '../../../../../../../components/CustomModals/MediaModal/components/MediaSelect';

const DirectoryForm = ({ onSelect }) => {
  const initValue = {
    directory: '',
  };

  const schema = Yup.object().shape({
    directory: Yup.mixed(),
  });

  const handleSubmit = useCallback(({ directory }) => {
    onSelect(directory);
  }, [onSelect]);

  const mediaSelect = useMemo(() => (
    <div className={styles.row}>

      <Subtitle className={styles.subtitle}>{translate('Directory')}</Subtitle>

      <MediaSelect
        name="directory"
        id="directory"
        placeholder={translate('Directory')}
      />

    </div>
  ), []);

  return (
    <Formik
      initialValues={initValue}
      validationSchema={schema}
      onSubmit={handleSubmit}
      enableReinitialize
    >

      <Form>

        {mediaSelect}

        <Button
          type="submit"
          className={styles.button}
        >

          {translate('Submit')}

        </Button>

      </Form>

    </Formik>
  );
};

export default DirectoryForm;
