import React from 'react';
import { useFormikContext } from 'formik';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { ReactComponent as Transparent } from '../../../../assets/icons/transparent.svg';
import { ReactComponent as Users } from '../../../../assets/icons/users.svg';
import { ReactComponent as Trash } from '../../../../assets/icons/trash.svg';
import { ReactComponent as File } from '../../../../assets/icons/file.svg';
import { ReactComponent as Save } from '../../../../assets/icons/save.svg';
import Select from '../../../../components/Select';
import { Button } from '../../../../components/Button';
import { promptOptions } from '../../../../utils/promptOptions';
import { translate } from '../../../../utils/translate';
import { useModal } from '../../../../hooks/useModal';
import { PAGES } from '../../../../constants/type';
import createLoadingSelector from '../../../../services/selectors/loading';
import ModalTemplate from '../../../ModalTemplate';
import styles from '../ContentModal/styles.module.scss';

const STATUS = [
  {
    label: (
      <div className={styles.item}>
        <Users className={styles.itemIcon} />
        <span>{translate('Public')}</span>
      </div>
    ),
    value: true,
  },
  {
    label: (
      <div className={styles.item}>
        <Transparent className={styles.itemIcon} />
        <span>{translate('Draft')}</span>
      </div>
    ),
    value: false,
  },
];

const selector = createSelector(
  createLoadingSelector(PAGES.CREATE.base),
  createLoadingSelector(PAGES.EDIT.base),
  (createLoader, editLoader) => createLoader.loading || editLoader.loading,
);

const HeaderModal = ({
  page, isEdit, onRemove, onClose,
}) => {
  const loading = useSelector(selector);

  const { isValid, dirty } = useFormikContext();

  const [modal, openModal, closeModal] = useModal(false);

  const handleOpen = () => {
    openModal(true);
  };

  const onChangeEnable = (changeValue) => {
    confirmAlert(promptOptions({
      buttons: [
        {
          label: 'No',
        },
        {
          label: 'Yes',
          color: 'danger',
          onClick: () => changeValue(),
        },
      ],
    }));
  };

  const handleRemove = () => {
    confirmAlert(promptOptions({
      buttons: [
        {
          label: 'No',
        },
        {
          label: 'Yes',
          color: 'danger',
          onClick: () => onRemove(page.id, { onSuccess: onClose }),
        },
      ],
    }));
  };

  return (
    <div className={styles.wrap}>

      <Select
        name="enable"
        id="enable"
        options={STATUS}
        onChange={onChangeEnable}
        className={styles.select}
        classNamePrefix="react-select"
      />

      <div className={styles.wrapButtons}>

        {
          isEdit && (
            <>

              <Button
                color="danger"
                style={{ width: 104 }}
                onClick={handleRemove}
              >
                <Trash width={24} height={24} />
                {translate('Remove')}
              </Button>

              <Button
                style={{ width: 174 }}
                onClick={handleOpen}
              >
                <File width={24} height={24} />
                {translate('Save as a template')}
              </Button>

              <ModalTemplate
                pageId={page.id}
                show={modal}
                onClose={closeModal}
                onCloseModal={onClose}
              />

            </>
          )
        }

        <Button type="submit" style={{ width: 104 }} disabled={!(isValid && dirty)} loading={loading}>
          <Save width={24} height={24} />
          { translate('Save') }
        </Button>

      </div>

    </div>
  );
};

export default HeaderModal;
