import React from 'react';
import { useFormikContext } from 'formik';
import { Input } from '../../../../Input';

/* eslint-disable react/jsx-props-no-spreading */
const GeneralLink = (props) => {
  const { setFieldValue } = useFormikContext();

  const onChange = (e, setValue) => {
    setFieldValue('site', '');
    setFieldValue('page', '');
    setValue();
  };

  return (
    <Input
      id="link-general"
      {...props}
      onChange={onChange}
    />
  );
};

export default GeneralLink;
