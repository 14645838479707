import React, { useMemo } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { confirmAlert } from 'react-confirm-alert';
import Subtitle from '../../../../Subtitle/Subtitle';
import { Input } from '../../../../Input';
import Select from '../../../../Select';
import { Button } from '../../../../Button';
import { translate } from '../../../../../utils/translate';
import { promptOptions } from '../../../../../utils/promptOptions';
import getNestNameDirectory from '../../../../../utils/getNestNameDirectory';
import styles from './styles.module.scss';

const ContentModal = ({
  show, isEdit, directories, onClose, onCreate, onEdit, onRemove,
}) => {
  const initValue = useMemo(() => {
    if (isEdit) {
      const item = directories.find((directory) => directory.id === show);

      if (item) return item;
    }

    return {
      id: '',
      name: '',
      parent: '',
    };
  }, [show, isEdit, directories]);

  const options = useMemo(
    () => getNestNameDirectory(directories, initValue.id),
    [directories, initValue.id],
  );

  const schema = Yup.object().shape({
    id: Yup.mixed().nullable(),
    name: Yup.string().required(),
    parent: Yup.mixed().nullable(),
  });

  const handleSubmit = (data, { setFieldError }) => {
    const action = data.id ? onEdit : onCreate;

    action(data, { setFieldError, onSuccess: onClose });
  };

  const handleRemove = async () => {
    confirmAlert(promptOptions({
      buttons: [
        {
          label: 'No',
        },
        {
          label: 'Yes',
          color: 'danger',
          onClick: () => onRemove(initValue.id, { onSuccess: onClose }),
        },
      ],
    }));
  };

  return (
    <Formik
      initialValues={initValue}
      validationSchema={schema}
      onSubmit={handleSubmit}
      enableReinitialize
    >

      <Form>

        <div className={styles.row}>

          <Subtitle className={styles.subtitle}>{translate('Name')}</Subtitle>

          <Input
            type="text"
            name="name"
            id="name"
            placeholder={translate('Folder name')}
            styleType={2}
          />

        </div>

        <div className={styles.row}>

          <Subtitle className={styles.subtitle}>{translate('Location')}</Subtitle>

          <Select
            name="parent"
            id="parent"
            options={options}
            placeholder={translate('../')}
            isClearable
            dark
          />

        </div>

        <div className={styles.wrapButtons}>
          {(onRemove && initValue.id) && <Button onClick={handleRemove} color="danger">{translate('Remove')}</Button>}

          <Button type="submit">{translate('Submit')}</Button>
        </div>

      </Form>

    </Formik>
  );
};

export default ContentModal;
