import React, { useCallback, useMemo } from 'react';
import {
  Tabs, TabList, Tab, TabPanel,
} from 'react-tabs';
import { Formik, Form } from 'formik';
import { Button } from '../../../../Button';
import { Input } from '../../../../Input';
import Subtitle from '../../../../Subtitle/Subtitle';
import Tooltip from '../../../../Tooltip';
import PageInputName from '../../../../../modules/ModalPage/components/PageInputName';
import { translate } from '../../../../../utils/translate';
import GeneralLink from '../GeneralLink';
import InnerLink from '../InnerLink';
import styles from './styles.module.scss';
import Select from '../../../../Select';
import RedirectLink from '../RedierctLink';

const redirectOptions = [
  {
    label: '302',
    value: false,
  },
  {
    label: '301',
    value: true,
  },
];

const RedirectForm = ({
  isEdit, schema, initValue, currentWebsite, currentRedirect, onRemove, onSubmit,
}) => {
  const getSelectedRedirect = useCallback((isPermanently) => {
    if (!isPermanently) return redirectOptions[0];

    return {
      label: isPermanently ? '301' : '302',
      value: !!isPermanently,
    };
  }, []);

  const redirectSelect = useMemo(() => (
    <Select
      id="permanent"
      name="permanent"
      options={redirectOptions}
      selectedOption={getSelectedRedirect(currentRedirect?.permanent)}
      isClearable
      dark
    />
  ), [getSelectedRedirect, currentRedirect]);

  const redirectPath = useMemo(() => (
    <Input
      type="text"
      name="source"
      id="source"
      placeholder={translate('Path')}
      styleType="2"
    />
  ), []);

  return (
    <Formik
      initialValues={initValue}
      validationSchema={schema}
      onSubmit={onSubmit}
      enableReinitialize
    >

      <Form>

        <div className={styles.row}>

          <Subtitle className={styles.subtitle}>

            {translate('Name')}

            <Tooltip
              title={translate('Give this redirect a name to define its purpose. Example: "Redirect from dryft.se/index"')}
            />

          </Subtitle>

          <PageInputName
            editingSlug={false}
          />

        </div>

        <div className={styles.row}>

          <Subtitle className={styles.subtitle}>

            {translate('Path')}

            <Tooltip
              title={translate('Use only path from url (without domain name)')}
            />

          </Subtitle>

          {redirectPath}

        </div>

        <div className={styles.row}>

          <Subtitle className={styles.subtitle}>

            {translate('From')}

            <Tooltip
              title={translate('Check that final link "From" is the same that a link on unavailable page, from which user should be redirected to other page.')}
            />

          </Subtitle>

          <RedirectLink
            currentWebsite={currentWebsite}
            placeholder={translate('From')}
          />

        </div>

        <div className={styles.row}>

          <Subtitle className={styles.subtitle}>

            {translate('To')}

            <Tooltip
              title={translate('Choose inner link from CMS websites or provide external link. This link will be used as a destination page where user will be redirected to.')}
            />

          </Subtitle>

          <Tabs
            defaultIndex={!isEdit || isNaN(currentRedirect?.target) ? 0 : 1}
          >

            <TabList>

              <Tab>{translate('General')}</Tab>

              <Tab>{translate('Inner')}</Tab>

            </TabList>

            <TabPanel>

              <GeneralLink
                name="url"
                placeholder={translate('Url')}
                styleType={2}
              />

            </TabPanel>

            <TabPanel>

              <InnerLink />

            </TabPanel>

          </Tabs>

        </div>

        <div className={styles.row}>

          <Subtitle className={styles.subtitle}>
            {translate('Redirection status')}
          </Subtitle>

          {redirectSelect}

        </div>

        <div className={styles.row}>

          <Subtitle className={styles.subtitle}>

            {translate('Public')}

            <Tooltip
              title={translate('Make redirection public to launch it working online.')}
            />

          </Subtitle>

          <Input
            type="checkbox"
            id="enable"
            name="enable"
          />

        </div>

        <div className={styles.wrapButtons}>

          {(onRemove && initValue.id) && <Button onClick={onRemove} color="danger">{translate('Remove')}</Button>}

          <Button type="submit">{translate('Submit')}</Button>

        </div>

      </Form>

    </Formik>
  );
};

export default RedirectForm;
