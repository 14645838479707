import { useState } from 'react';

export const useModal = (initialState) => {
  const [state, setState] = useState(initialState);

  const openModal = (id = true) => setState(() => (
    typeof id === 'number' || typeof id === 'string'
      ? id
      : true
  ));

  const closeModal = () => setState(() => false);

  return [state, openModal, closeModal];
};
