import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import ButtonSVG from '../ButtonSVG';
import styles from './styles.module.scss';

const Tooltip = ({ title }) => {
  useEffect(() => {
    setTimeout(() => ReactTooltip.rebuild(), 0);
  });

  return (
    <span className={styles.wrap}>

      <ButtonSVG
        className={styles.button}
        data-tip={title}
        data-for="tooltip-global"
      >
        i
      </ButtonSVG>

    </span>
  );
};

Tooltip.defaultProps = {
  title: 'Tooltip text',
};

Tooltip.propTypes = {
  title: PropTypes.string,
};

export default Tooltip;
