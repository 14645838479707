import { REDIRECTS } from '../../constants/type';

export const defaultState = {
  redirects: {},
};

export default (state = defaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    /* REDIRECTS */
    case REDIRECTS.GET_LIST.SUCCESS: {
      return {
        ...state,
        redirects: {
          ...state.redirects,
          [payload.requestData.webId]: payload.data,
        },
      };
    }
    default:
      return state;
  }
};
