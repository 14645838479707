import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.module.scss';

export const SidebarTab = ({
  hide, title, headerContent, iconsContent, children,
}) => (
  <div className={cn([styles.sidebarTab_container, hide && styles.sidebarTab_hide])}>

    <header className={styles.sidebarTab_header}>

      <div className={styles.sidebarTab_titleWrap}>

        <div>

          <h2 className={styles.sidebarTab_title}>{title}</h2>

        </div>

        <div className={styles.icons}>

          {iconsContent}

        </div>

      </div>

      <div>
        {headerContent}
      </div>

    </header>

    <div className={styles.sidebarTab_content}>{children}</div>

  </div>
);

SidebarTab.defaultProps = {
  headerContent: null,
  iconsContent: null,
};

SidebarTab.propTypes = {
  title: PropTypes.string.isRequired,
  headerContent: PropTypes.element,
  iconsContent: PropTypes.element,
  // children: PropTypes.arrayOf(PropTypes.element).isRequired,
};
