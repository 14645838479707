import React from 'react';
import Modal from '../../Modal/Modal';
import Title from '../../Title/Title';
import { translate } from '../../../utils/translate';
import ModalContent from './components/ModalContent';
import styles from './styles.module.scss';

const BigMediaModal = ({ files, directory, onClose }) => (
  <Modal
    show={Boolean(files)}
    classNameContainer={styles.modal}
    onClose={() => {}}
  >

    <Title className={styles.title}>{translate('Loading')}</Title>

    <ModalContent
      files={files}
      directory={directory}
      onClose={onClose}
    />

  </Modal>
);

export default BigMediaModal;
