import React, {
  useState, useMemo, useCallback,
} from 'react';
import slugify from 'slugify';
import { useHistory } from 'react-router';
import cn from 'classnames';
import MonacoEditor from 'react-monaco-editor';
import { confirmAlert } from 'react-confirm-alert';
import FullScreen from 'react-full-screen';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { createAction } from 'redux-actions';
import { toast } from 'react-toastify';
import { dencodeURIRedirect } from 'utils/encodeURIRedirect';
import { ReactComponent as Brackets } from '../../assets/icons/brackets.svg';
import { ReactComponent as BracketsJS } from '../../assets/icons/brackets-js.svg';
import { ReactComponent as BracketsCSS } from '../../assets/icons/brackets-css.svg';
import { ReactComponent as Trash } from '../../assets/icons/trash.svg';
import { ReactComponent as Save } from '../../assets/icons/save.svg';
import { ReactComponent as Play } from '../../assets/icons/play.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import ButtonSVG from '../../components/ButtonSVG';
import { promptOptions } from '../../utils/promptOptions';
import { translate } from '../../utils/translate';
import useSnippetHelper from '../../hooks/useSnippetHelper';
import useSnippet from '../../hooks/useSnippet';
import { UI_ACTIONS, LEFT_MENU } from '../../constants/ui';
import { SNIPPET_EDITOR_ROUTE } from '../../constants/routes';
import RenderSnippet from './components/RenderSnippet';
import styles from './styles.module.scss';


const SnippetEditor = () => {
  let snippetId;

  const match = useRouteMatch(SNIPPET_EDITOR_ROUTE);
  const dispatch = useDispatch();
  const actionSelectLeftMenu = createAction(UI_ACTIONS.SELECT_LEFT_MENU);
  const actionSelectedSnippet = createAction(UI_ACTIONS.SELECTED_SNIPPET);

  if (match) {
    snippetId = match.params.snippetId;
  }

  const currentSnippetId = snippetId ? +snippetId : undefined;

  const history = useHistory();

  const [isFullScreen, setIsFullScreen] = useState(false);

  const [codeTab, setCodeTab] = useState(1);

  const {
    snippet,
    onCreate,
    onEdit,
    onRemove,
  } = useSnippet(currentSnippetId);

  const {
    helperValues,
    helperActions,
    helperDisable,
    helperDisableTxt
  } = useSnippetHelper();

  const isEdit = typeof currentSnippetId === 'number' && !isNaN(currentSnippetId);

  const handleChange = useCallback((newValue) => {
    helperActions.setFieldTouched('template_code', true);
    helperActions.setFieldValue('template_code', newValue);
  }, [helperActions]);

  const handleChangeJs = useCallback((newValue) => {
    helperActions.setFieldTouched('js_code', true);
    helperActions.setFieldValue('js_code', newValue);
  }, [helperActions]);

  const handleChangeCss = useCallback((newValue) => {
    helperActions.setFieldTouched('css_code', true);
    helperActions.setFieldValue('css_code', newValue);
  }, [helperActions]);

  const handleChangeCssType = useCallback((newValue) => {
    helperActions.setFieldTouched('css_code_type', true);
    helperActions.setFieldValue('css_code_type', newValue);
  }, [helperActions]);

  const onSuccess = (type) => {
    const { search } = history.location;

    const redirectLink = dencodeURIRedirect(new URLSearchParams(search).get('redirect'));
    if (redirectLink) {
        history.push(redirectLink);
    }
    dispatch(actionSelectLeftMenu(LEFT_MENU.SNIPPETS));
    dispatch(actionSelectedSnippet());
    toast.success(translate(`Snippet ${type}`));
  };

  const onFailure = (type) => {
    console.log('onFailure', type);
  };

  const onClose = () => {
    const { search } = history.location;
    const redirectLink = dencodeURIRedirect(new URLSearchParams(search).get('redirect'));
    if (redirectLink) {
      history.push(redirectLink);
    }
    dispatch(actionSelectLeftMenu(LEFT_MENU.SNIPPETS));
    dispatch(actionSelectedSnippet());
  };

  const handleSave = () => {
    const id = snippet?.id || '';

    const {
      name, short_name, public_status, snippet_category, description, icon, template_code, 
      js_code, css_code, css_code_type
    } = helperValues;

    const action = isEdit ? onEdit : onCreate;

    const data = new FormData();

    data.append('id', id);
    data.append('name', name);
    data.append('short_name', short_name);
    data.append('slug', slugify(name, {
      replacement: '_',
      lower: true,
      remove: /[*+~.()'"!:@]/g,
      strict: true,
    }));
    data.append('public_status', public_status);
    data.append('snippet_category', snippet_category || '');
    data.append('description', description);
    data.append('js_code', js_code);
    data.append('css_code', css_code);
    data.append('css_code_type', css_code_type);
    data.append('template_code', template_code);

    if (
      (isEdit && icon && icon !== snippet.icon)
      || !isEdit
    ) data.append('icon', icon);

    action(data, {
      ...helperActions,
      onSuccess: () => onSuccess(isEdit ? 'edit' : 'create'),
    });
  };

  const handleRemove = () => {
    confirmAlert(promptOptions({
      buttons: [
        {
          label: 'No',
        },
        {
          label: 'Yes',
          color: 'danger',
          onClick: () => onRemove(snippet?.id, {
            onSuccess: () => onSuccess('remove'),
            onFailure: () => onFailure('remove'),
          }),
        },
      ],
    }));
  };

  const handleOpenRender = () => setIsFullScreen(() => true);

  const handleDidMount = (editor/* , monaco */) => {
    /* monaco.languages.register({ id: 'customTags' });

    monaco.languages.setMonarchTokensProvider('customTags', customTags);

    monaco.editor.defineTheme('customTags', customTheme);

    monaco.editor.setTheme('customTags'); */

    editor.focus();
  };

  const value = useMemo(() => (helperValues && helperValues.template_code) || '', [helperValues]);
  const valueJs = useMemo(() => (helperValues && helperValues.js_code) || '', [helperValues]);
  const valueCss = useMemo(() => (helperValues && helperValues.css_code) || '', [helperValues]);
  const cssCodeYype = useMemo(() => (helperValues && helperValues.css_code_type) || 'css', [helperValues]);

  const editor = useMemo(() => (
    <MonacoEditor
      key={`${currentSnippetId}${codeTab}`}
        // language="customTags"
      language={codeTab === 3 ? 'css' : (codeTab === 2 ? 'javascript' : 'html')}
      theme="vs-dark"
      options={{
        selectOnLineNumbers: true,
        cursorSmoothCaretAnimation: true,
        dragAndDrop: true,
        fontFamily: '"Roboto Condensed", sans-serif',
        fontSize: '14px',
        scrollBeyondLastLine: false,
      }}
      value={codeTab === 3 ? valueCss : (codeTab === 2 ? valueJs : value)}
      onChange={codeTab === 3 ? handleChangeCss : (codeTab === 2 ? handleChangeJs : handleChange)}
      editorDidMount={handleDidMount}
    />
  ), [currentSnippetId, value, valueJs, valueCss, codeTab, handleChange, handleChangeJs, handleChangeCss]);

  return (
    <section className={styles.container}>

      <header className={styles.header}>

        <div className={styles.icons}>

          <ButtonSVG
            onClick={() => setCodeTab(1)}
            title={translate('Code')}
            className={cn(styles.icon, codeTab === 1 ? styles.active : null)}
          >
            <Brackets
              width={24}
              height={24}
            />
          </ButtonSVG>

          <ButtonSVG
            onClick={() => setCodeTab(2)}
            title={translate('JS Code')}
            className={cn(styles.icon, codeTab === 2 ? styles.active : null)}
          >
            <BracketsJS
              width={24}
              height={24}
            />
          </ButtonSVG>

          <ButtonSVG
            onClick={() => setCodeTab(3)}
            title={translate('CSS Code')}
            className={cn(styles.icon, codeTab === 3 ? styles.active : null)}
          >
            <BracketsCSS
              width={24}
              height={24}
            />
          </ButtonSVG>

          <ButtonSVG
            onClick={handleOpenRender}
            className={cn(styles.icon)}
            title={translate('View Snippet')}
          >
            <Play
              width={24}
              height={24}
            />
          </ButtonSVG>

          { isEdit && (
            <ButtonSVG
              id={currentSnippetId}
              title={translate('Remove Snippet')}
              onClick={handleRemove}
              className={cn(styles.icon)}
            >
              <Trash
                width={24}
                height={24}
              />
            </ButtonSVG>
          )}

          <ButtonSVG
            onClick={handleSave}
            title={helperDisableTxt ? helperDisableTxt : translate('Save Snippet')}
            className={cn(styles.icon)}
            disabled={helperDisable}
          >
            <Save
              width={24}
              height={24}
            />
          </ButtonSVG>

          {true && ( // FIXME need use status !isEdit
            <ButtonSVG
              id={currentSnippetId}
              title={translate('Close Snippet')}
              onClick={onClose}
              className={cn(styles.icon)}
            >
              <CloseIcon
                width={24}
                height={24}
              />
            </ButtonSVG>
          )}

        </div>

      </header>

      <div className={styles.wrap}>
        { codeTab === 3 ? <div className={styles.buttons}>
          <button 
            onClick={() => handleChangeCssType('css')}
            className={cn(styles.button, cssCodeYype === 'css' ? styles.active : null)}
          >
            CSS
          </button>
          <button 
            onClick={() => handleChangeCssType('scss')}
            className={cn(styles.button, cssCodeYype === 'scss' ? styles.active : null)}
          >
            SCSS
          </button>
          <button 
            onClick={() => handleChangeCssType('less')}
            className={cn(styles.button, cssCodeYype === 'less' ? styles.active : null)}
          >
            LESS
          </button>
        </div> : null}
        { editor }

      </div>

      <div className={styles.onepxh}>
      <FullScreen
        enabled={isFullScreen}
        onChange={setIsFullScreen}
      >

        { isFullScreen ? <RenderSnippet code={value} codeJs={valueJs} codeCss={valueCss} cssCodeType={cssCodeYype} /> : '' }

      </FullScreen></div>

    </section>
  );
};

export default SnippetEditor;
