import React from 'react';
import { TabList, Tab, Tabs, TabPanel } from 'react-tabs';
import { SnippetWrap } from '../../../../../../components/SnippetProperties/SnippetWrap';
import Subtitle from '../../../../../../components/Subtitle/Subtitle';
import { translate } from '../../../../../../utils/translate';
import styles from './styles.module.scss';
import InnerSource from './components/InnerSource';
import ExternalSource from './components/ExternalSource';

const BlockSource = ({
  id, item: {
    name, values, info, group, errors, type,
  }, language, onChange,
}) => {
  const handleChange = (inputValue) => {
    const data = {
      id,
      group,
      language,
      ...inputValue,
    };

    onChange(data);
  };

  const error = (errors && errors[language]) || false;

  const value = values[language] || '';

  const currentInfo = info[language];

  const index = currentInfo?.mode === 'external' ? 1 : 0;

  return (
    <SnippetWrap>

      <Subtitle className={styles.title} title={name}>{name}</Subtitle>

      <Tabs defaultIndex={index}>

        <TabList>

          <Tab>{translate('Inner source')}</Tab>

          <Tab>{translate('External source')}</Tab>

        </TabList>

        <div className={styles.content}>

          <TabPanel>
            <InnerSource
              id={id}
              type={type}
              error={error}
              value={value}
              info={currentInfo}
              onChange={handleChange}
            />
          </TabPanel>

          <TabPanel>
            <ExternalSource
              id={id}
              error={error}
              value={value}
              info={currentInfo}
              onChange={handleChange}
            />
          </TabPanel>

        </div>

      </Tabs>

    </SnippetWrap>
  );
};

export default BlockSource;
