import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { InputUnControlled } from '../../../../Input';
import getWebsiteLink from '../../../../../modules/ModalPage/getWebsiteLink';

const RedirectLink = ({ currentWebsite, placeholder }) => {
  const { values: { source } } = useFormikContext();

  const websiteURL = getWebsiteLink(currentWebsite);

  const [value, setValue] = useState('');

  useEffect(() => {
    const path = `${websiteURL}${source || ''}`;

    setValue(path);
  }, [websiteURL, source]);

  return (
    <InputUnControlled
      type="text"
      id="redirect_link"
      placeholder={placeholder}
      styleType="2"
      disabled
      value={value}
    />
  );
};

export default RedirectLink;
