export const dropTypes = {
  WEBSITE: 'website',
  WEBSITE_DIRECTORY: 'website_directory',
  PAGE: 'page',
  SNIPPET: 'snippet',
  SNIPPET_CONTAINER: 'snippet_container',
  SNIPPET_DIRECTORY: 'snippet_directory',
  SNIPPET_AT_PAGE: 'snippet_at_page',
  SNIPPET_AT_PAGE_CHILD: 'snippet_at_page_child',
  SNIPPET_AT_PAGE_CONTAINER: 'snippet_at_page_container',
  SNIPPET_AT_PAGE_CONTAINER_CHILD:  'snippet_at_page_container_child',
  MEDIA: 'media',
  MEDIA_DIRECTORY: 'media_directory',
};
