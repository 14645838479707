import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  createCategory, editCategory, removeCategory,
} from '../services/actions/media';
import { currentCategorySelector } from '../services/selectors/media';

export const useMediaCategory = (id) => {
  const selector = useCallback((store) => currentCategorySelector(store, id), [id]);

  const currentCategory = useSelector(selector);

  const onCreate = createCategory;
  const onEdit = editCategory;
  const onRemove = removeCategory;

  return {
    currentCategory,
    onCreate,
    onEdit,
    onRemove,
  };
};
