import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { selectedWebsiteIdSelector, selectedPageIdSelector } from '../services/selectors/ui';
import { selectPage } from '../services/actions/ui';
import useAction from '../utils/useAction';

const selector = createSelector(
  selectedWebsiteIdSelector,
  selectedPageIdSelector,
  (selectedWebsiteId, selectedPageId) => ({
    selectedWebsiteId,
    selectedPageId,
  }),
);

const useSiteSelect = () => {
  const data = useSelector(selector);

  const onSelectPage = useAction(selectPage);

  return {
    ...data,
    onSelectPage,
  };
};

export default useSiteSelect;
