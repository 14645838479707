import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.module.scss';

const Title = ({ tag: Tag, className, children }) => (
  <Tag
    className={cn(styles.title, className)}
  >
    {children}
  </Tag>
);

Title.defaultProps = {
  tag: 'h2',
  className: '',
};

Title.propTypes = {
  tag: PropTypes.string,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
};

export default Title;
