import React, {
  useMemo, useCallback, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Edit } from '../../../../../../assets/icons/edit.svg';
import { ReactComponent as Trash } from '../../../../../../assets/icons/trash.svg';
import { ReactComponent as ImageIcon } from '../../../../../../assets/icons/image.svg';
import Subtitle from '../../../../../../components/Subtitle/Subtitle';
import ButtonSVG from '../../../../../../components/ButtonSVG';
import CropModal from '../../../../../../components/CustomModals/CropModal';
import AssetsModal from '../../../../../../components/CustomModals/AssetsModal';
import { SnippetWrap } from '../../../../../../components/SnippetProperties/SnippetWrap';
import Icon from '../../../../../ModalWebsite/components/Icon';
import { useModal } from '../../../../../../hooks/useModal';
import styles from './styles.module.scss';

const BlockImage = ({
  id, item: {
    name, values, props, group, errors,
  }, language, onChange,
}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    const currentValue = values[language] || '';

    setValue(currentValue);
  }, [values, language]);

  const [assets, openAssets, closeAssets] = useModal(false);

  const [crop, openCrop, closeCrop] = useModal(false);

  const handleChose = ({ relative_path }) => {
    const data = {
      id,
      group,
      language,
      value: relative_path,
    };

    onChange(data);
  };

  const onCrop = useCallback((cropValue) => {
    const data = {
      id,
      group,
      language,
      value: values[language],
      props: cropValue,
    };

    onChange(data);
  }, [id, values, group, language, onChange]);

  const handleDelete = () => {
    const data = {
      id,
      group,
      language,
      value: null,
    };

    onChange(data);
  };

  const error = (errors && errors[language]) || false;

  const cropModal = useMemo(() => {
    const src = value ? `${value}?r=${Math.random()}` : value;

    return (
      <CropModal
        show={crop}
        src={src}
        props={props}
        onClose={closeCrop}
        onEdit={onCrop}
      />
    );
  }, [crop, value, props, closeCrop, onCrop]);

  return (
    <SnippetWrap>

      <Subtitle className={styles.title} title={name}>{name}</Subtitle>

      <Icon file={value} error={error} className={styles.image} />

      <AssetsModal
        show={assets}
        onClose={closeAssets}
        onChose={handleChose}
        filter="image"
      />

      { cropModal }

      <div className={styles.container}>

        <div>

          <ButtonSVG
            className={styles.button}
            onClick={openAssets}
          >
            <ImageIcon width={16} height={16} />
          </ButtonSVG>

        </div>

        <div>

          <ButtonSVG
            className={styles.button}
            onClick={openCrop}
            disabled={!value || !props?.crop}
          >
            <Edit width={16} height={16} />
          </ButtonSVG>

          <ButtonSVG
            className={styles.button}
            onClick={handleDelete}
            disabled={!value}
          >
            <Trash width={16} height={16} />
          </ButtonSVG>

        </div>

      </div>

    </SnippetWrap>
  );
};

BlockImage.propTypes = {
  id: PropTypes.string.isRequired,
  item: PropTypes.shape({
    name: PropTypes.string,
    values: PropTypes.object,
  }).isRequired,
  language: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default BlockImage;
