import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { categoriesSelector, listSelector } from '../services/selectors/websites';
import {
  createWebsite, editWebsite, geList,
  createCategory, editCategory, removeCategory, getCategories,
} from '../services/actions/websites';
import createLoadingSelector from '../services/selectors/loading';
import { WEBSITES } from '../constants/type';

const selector = createSelector(
  listSelector,
  createLoadingSelector(WEBSITES.GET_LIST.base),
  categoriesSelector,
  createLoadingSelector(WEBSITES.GET_CATEGORIES.base),
  (websites, websitesLoader, categories, categoriesLoader) => ({
    websites,
    websitesLoader,
    categories,
    categoriesLoader,
  }),
);

export const useWebsitesTab = () => {
  const data = useSelector(selector);

  useEffect(() => {
    const { loading, loaded, error } = data.websitesLoader;

    if (!loading && !loaded && !error) {
      geList();
    }
  }, [
    data.websitesLoader,
  ]);

  useEffect(() => {
    const { loading, loaded, error } = data.categoriesLoader;

    if (!loading && !loaded && !error) {
      getCategories();
    }
  }, [data.categoriesLoader]);

  const onCreate = createWebsite;
  const onEdit = editWebsite;
  const onCreateCategory = createCategory;
  const onEditCategory = editCategory;
  const onRemoveCategory = removeCategory;

  return {
    ...data,
    onCreate,
    onEdit,
    onCreateCategory,
    onEditCategory,
    onRemoveCategory,
  };
};
