import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const BlockPropsLanguage = ({ language }) => (
  <div className={styles.container}>
    {language}
  </div>
);

BlockPropsLanguage.defaultProps = {
  language: '',
};

BlockPropsLanguage.propTypes = {
  language: PropTypes.string,
};

export default BlockPropsLanguage;
