import React from 'react';
import Modal from '../../Modal/Modal';
import ModalHeader from '../../Modal/componets/ModalHeader';
import ModalContent from '../../Modal/componets/ModalContent';
import PageModalContent from './components/PageModalContent';
import { translate } from '../../../utils/translate';
import styles from '../styles.module.scss';

/* eslint-disable react/jsx-props-no-spreading */
const VirtualPageModal = (props) => {
  const { show, onClose } = props;

  const isEdit = show && typeof show === 'number';

  const title = isEdit ? 'Edit folder' : 'New folder';

  return (
    <Modal
      show={Boolean(show)}
      classNameContainer={styles.modal}
      onClose={onClose}
    >

      <ModalHeader
        className={styles.header}
        title={translate(title)}
        onClose={onClose}
      />

      <ModalContent
        className={styles.content}
        tag="div"
      >

        <PageModalContent
          {...props}
          isEdit={isEdit}
        />

      </ModalContent>

    </Modal>
  );
};

export default VirtualPageModal;
