import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { InputUnControlled } from '../../../../../../components/Input';
import { SnippetWrap } from '../../../../../../components/SnippetProperties/SnippetWrap';
import SnippetRow from '../../../../../../components/SnippetProperties/SnippetWrap/components/SnippetRow';
import BlockPropsLanguage from '../BlockProps/components/BlockPropsLanguage';

const BlockNumber = ({
  id, item: {
    name, values, group, errors, type,
  }, language, onChange,
}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    const currentValue = values[language] || '';

    setValue(currentValue);
  }, [values, language]);

  const handleBlur = useCallback((e) => {
    const data = {
      id,
      group,
      language,
      value: e.target.value,
    };

    onChange(data);
  }, [id, group, language, onChange]);

  const handleChange = (e) => {
    const inputValue = e.target.value;

    setValue((oldValue) => (
      (isNaN(+inputValue))
        ? (oldValue || '')
        : (typeof type === 'number'
          ? inputValue
          : parseInt(inputValue)
        )
    ));
  };

  const error = (errors && errors[language]) || false;

  return (
    <SnippetWrap>

      <SnippetRow
        first={name}
        second={(
          <InputUnControlled
            id={id}
            name={id}
            error={error}
            onBlur={handleBlur}
            onChange={handleChange}
            value={value}
          />
        )}
        third={(
          <BlockPropsLanguage language={language} />
        )}
      />

    </SnippetWrap>
  );
};

BlockNumber.propTypes = {
  id: PropTypes.string.isRequired,
  item: PropTypes.shape({
    name: PropTypes.string,
    values: PropTypes.object,
  }).isRequired,
  language: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default BlockNumber;
