import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { snippetAtPageSelector } from '../services/selectors/snippet-at-page';

const useSnippetAtPage = (id) => {
  const selector = useCallback((state) => snippetAtPageSelector(state, id), [id]);

  return useSelector((state) => selector(state));
};

export default useSnippetAtPage;
