import React, { useMemo } from 'react';
// import { Loader } from '../../components/Loader';
import { AppRoutes } from './components/AppRoutes';
import { AuthRoutes } from './components/AuthRoutes';
import { useRoutes } from '../hooks/useRoutes';

const Routes = () => {
  const {
    isAuthorized,
  } = useRoutes();

  return useMemo(() => (isAuthorized ? <AppRoutes /> : <AuthRoutes />), [isAuthorized]);
};

export default Routes;
