import React, { useMemo } from 'react';
import { SnippetWrap } from '../../../../../../components/SnippetProperties/SnippetWrap';
import { SelectUnControlled } from '../../../../../../components/Select';
import SnippetRow from '../../../../../../components/SnippetProperties/SnippetWrap/components/SnippetRow';

const BlockSelect = ({
  id, item: {
    name, values, props, group,
  }, language, onChange,
}) => {
  const options = useMemo(() => (
    Object.keys(props).map((key) => ({
      label: props[key],
      value: key,
    }))
  ), [props]);

  const handleChange = ({ value }) => {
    const data = {
      id,
      group,
      language,
      value,
    };

    onChange(data);
  };

  const value = values[language]
    ? options.find((option) => option.value === values[language])
    : '';

  return (
    <SnippetWrap>

      <SnippetRow
        first={name}
        second={(
          <SelectUnControlled
            name={id}
            options={options}
            value={value}
            onChange={handleChange}
            dark
            small
            menuPosition="fixed"
          />
        )}
      />

    </SnippetWrap>
  );
};

export default BlockSelect;
