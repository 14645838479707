import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.module.scss';

export const AccordionHeader = forwardRef(({
  title, isOpen, leftIcons, rightIcons, hasChildren, style, className,
}, ref) => useMemo(() => (
  <div
    ref={ref}
    className={cn(
      styles.header,
      { [styles.open]: isOpen && hasChildren },
      className,
    )}
    style={style}
  >

    <div className={styles.left}>

      <span className={styles.icon}>
        {leftIcons}
      </span>

      <div className={styles.title}>
        {title}
      </div>

    </div>

    <div className={styles.right}>
      {rightIcons}
    </div>

  </div>
), [isOpen, title, leftIcons, rightIcons, hasChildren, style, className, ref]));

AccordionHeader.defaultProps = {
  isOpen: false,
  hasChildren: false,
  title: null,
  leftIcons: null,
  rightIcons: null,
  className: '',
};

AccordionHeader.propTypes = {
  leftIcons: PropTypes.element,
  rightIcons: PropTypes.element,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]),
  isOpen: PropTypes.bool,
  hasChildren: PropTypes.bool,
  className: PropTypes.string,
};
