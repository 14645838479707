import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import Select from '../../../../../../Select';
import Subtitle from '../../../../../../Subtitle/Subtitle';
import { Button } from '../../../../../../Button';
import { translate } from '../../../../../../../utils/translate';
import { useWebsitesTab } from '../../../../../../../hooks/useWebsitesTab';
import Pages from '../Pages';
import styles from './styles.module.scss';

const ModalForm = ({ value, onClose, onChange }) => {
  const { websites } = useWebsitesTab();

  const webOptions = useMemo(() => websites.map((website) => ({
    label: website.name,
    value: website.id,
  })), [websites]);

  let initVal;

  if (value) {
    const { site, page } = value;

    initVal = {
      site,
      page,
    };
  } else {
    initVal = {
      site: '',
      page: '',
    };
  }

  const schema = Yup.object().shape({
    site: Yup.number().required(),
    page: Yup.number().required(),
  });

  const handleSubmit = (values) => {
    onChange(values);

    onClose();
  };

  return (
    <Formik
      initialValues={initVal}
      validationSchema={schema}
      onSubmit={handleSubmit}
      enableReinitialize
    >

      {({ values, setFieldValue }) => (
        <Form>

          <div className={styles.row}>

            <Subtitle className={styles.subtitle}>{translate('Website')}</Subtitle>

            <Select
              name="site"
              id="site"
              options={webOptions}
              onChange={(changeSelect) => {
                setFieldValue('page', '');

                changeSelect();
              }}
              placeholder={translate('Website')}
              dark
            />

          </div>

          {values.site && <Pages />}

          <Button type="submit">{translate('Submit')}</Button>

        </Form>
      )}

    </Formik>
  );
};

export default ModalForm;
