import React, { useMemo } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useRouteMatch } from 'react-router-dom';
import { ReactComponent as Directory } from '../../../../assets/icons/add-directory.svg';
import { SidebarPanel } from '../../../../components/SidebarTabs/SidebarPanel';
import { SnippetWrap } from '../../../../components/SnippetProperties/SnippetWrap';
import { SidebarTab } from '../../../../components/SidebarTabs/SidebarTab';
import FolderModal from '../../../../components/CustomModals/FolderModal';
import ButtonSVG from '../../../../components/ButtonSVG';
import Mandatory from '../../../../components/Mandatory';
import { Text } from '../../../../components/SnippetProperties/Text';
import Select from '../../../../components/Select';
import Image from '../../../../components/SnippetProperties/Image';
import { translate } from '../../../../utils/translate';
import { useSnippets } from '../../../../hooks/useSnippets';
import { useModal } from '../../../../hooks/useModal';
import useSnippet from '../../../../hooks/useSnippet';
import { SNIPPET_EDITOR_ROUTE } from '../../../../constants/routes';
import SetHelpers from './components/SetHelpers';
import styles from './styles.module.scss';

const SnippetEditorTab = ({ hide }) => {
  const [modalFolder, openModalFolder, closeModalFolder] = useModal(false);

  let snippetId;
  const match = useRouteMatch(SNIPPET_EDITOR_ROUTE);
  if (match) {
    snippetId = match.params.snippetId;
  }

  const currentSnippetId = snippetId ? +snippetId : undefined;

  const {
    categories: snippetCategories,
    onCreateCategory,
    onEditCategory,
    onRemoveCategory,
  } = useSnippets();

  const {
    snippet,
  } = useSnippet(currentSnippetId);

  const initValue = useMemo(() => {
    if (snippet) {
      const {
        name,
        short_name,
        public_status = false,
        description,
        snippet_category,
        icon,
        template_code,
        css_code_type,
        css_code,
        js_code,
      } = snippet;

      return {
        name: name || '',
        short_name: short_name || '',
        public_status,
        description: description || '',
        snippet_category: snippet_category || '',
        icon,
        template_code,
        css_code_type,
        css_code,
        js_code,
      };
    }

    return {
      name: '',
      short_name: '',
      public_status: false,
      description: '',
      snippet_category: '',
      icon: '',
      template_code: '',
      css_code_type: 'css',
      css_code: '',
      js_code: '',

    };
  }, [snippet]);

  const directories = useMemo(() => snippetCategories.map(({ name, id }) => ({
    label: name,
    value: id,
  })), [snippetCategories]);

  const schema = Yup.object().shape({
    name: Yup.string()
      .min(3)
      .required(),
    short_name: Yup.string()
      .min(3)
      .required(),
    public_status: Yup.bool(),
    description: Yup.string()
      .min(3)
      .required(),
    snippet_category: Yup.number().nullable(),
    icon: Yup.lazy((valueInput) => {
      switch (typeof valueInput) {
        case 'string':
          return Yup.string().required();

        case 'object':
          return Yup.mixed()
            .test('fileType', 'Unsupported File Format',
              (value) => value && ['image/gif', 'image/jpeg', 'image/png', 'image/vnd.microsoft.icon'].includes(value.type))
            .test('fileSize', 'Max File size', (value) => value.size <= 1000000)
            .required();
        default:
          return Yup.mixed().required();
      }
    }),
    template_code: Yup.string(),
    js_code: Yup.string(),
    css_code: Yup.string(),
    css_code_type: Yup.string().required(),
  });

  return (
    <SidebarTab
      title={translate('Snippet editor')}
      hide={hide}
    >

      <FolderModal
        show={modalFolder}
        directories={snippetCategories}
        onClose={closeModalFolder}
        onCreate={onCreateCategory}
        onEdit={onEditCategory}
        onRemove={onRemoveCategory}
      />

      <Formik
        initialValues={initValue}
        validationSchema={schema}
        onSubmit={() => {}}
        enableReinitialize
      >

        <Form
          className={styles.container}
          noValidate
        >

          <SetHelpers />

          <SidebarPanel>

            <Text
              id="name"
              type="text"
              name="name"
              label={(
                <>
                  {translate('Full name')}
                  { ' ' }
                  <Mandatory title={translate('Mandatory')} />
                </>
              )}
              placeholder="Full name"
              styleType={2}
            />

            <Text
              id="short_name"
              type="text"
              name="short_name"
              label={(
                <>
                  {translate('Short name')}
                  { ' ' }
                  <Mandatory title={translate('Mandatory')} />
                </>
              )}
              placeholder="Short name"
              styleType={2}
            />

            <Text
              id="public_status"
              type="checkbox"
              name="public_status"
              label={translate('Public')}
              placeholder="Public"
            />

            <SnippetWrap>

              <div className={styles.selectContainer}>

                <div className={styles.selectLabel}>

                  {translate('Folder')}

                  <ButtonSVG
                    title={translate('Create Category')}
                    className={styles.selectButton}
                    onClick={openModalFolder}
                  >
                    <Directory width={16} height={16} />
                  </ButtonSVG>

                </div>

                <Select
                  id="snippet_category"
                  name="snippet_category"
                  options={directories}
                  className={styles.selectWrap}
                  isClearable
                  dark
                />

              </div>

            </SnippetWrap>

            <Image
              id="icon"
              name="icon"
              title={(
                <>
                  {translate('Icon')}
                  { ' ' }
                  <Mandatory title={translate('Mandatory')} />
                </>
              )}
            />

            <Text
              type="textarea"
              name="description"
              label={(
                <>
                  {translate('Description')}
                  { ' ' }
                  <Mandatory title={translate('Mandatory')} />
                </>
              )}
              placeholder="Description"
              styleType={2}
            />
          </SidebarPanel>

        </Form>

      </Formik>

    </SidebarTab>
  );
};

export default SnippetEditorTab;
