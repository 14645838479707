import React, { useRef, useMemo } from 'react';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import { Link, useRouteMatch } from 'react-router-dom';
import cn from 'classnames';
import { useDrag, useDrop } from 'react-dnd';
import { ReactComponent as Transparent } from '../../../../assets/icons/transparent.svg';
import { ReactComponent as Settings } from '../../../../assets/icons/settings.svg';
import { ReactComponent as Users } from '../../../../assets/icons/users.svg';
import { ReactComponent as Page } from '../../../../assets/icons/page.svg';
import ButtonSVG from '../../../../components/ButtonSVG';
import { dropTypes } from '../../../../utils/dropTypes';
import { translate } from '../../../../utils/translate';
import { PAGE_EDITOR_ROUTE } from '../../../../constants/routes';
import styles from './styles.module.scss';

const PageItem = ({
  page, onEdit, onOpenModalEdit,
}) => {
  const {
    website, id, enable, name,
  } = page;

  const ref = useRef(null);

  let selPageId;
  const match = useRouteMatch(PAGE_EDITOR_ROUTE);
  if (match) {
    selPageId = match.params.pageId;
  }

  const [{ isDragging }, drag] = useDrag({
    item: { type: dropTypes.PAGE, page },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOverCurrent }, drop] = useDrop({
    accept: [dropTypes.PAGE],
    drop(dragItem, monitor) {
      const didDrop = monitor.didDrop();

      if (didDrop) return;

      if (dragItem.type === dropTypes.PAGE
        && dragItem.page.parent !== page.id
        && dragItem.page.id !== page.id
      ) {
        const p = omit(dragItem.page, ['main_image']);
        onEdit({
          ...p,
          parent: page.id,
        });
      }
    },
    collect: (monitor) => ({
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;

  const backgroundColor = isOverCurrent ? 'rgba(0,0,0,.2)' : 'transparent';

  drag(drop(ref));

  const icon = useMemo(() => (
    enable
      ? <Users width={16} heigth={16} />
      : <Transparent width={16} heigth={16} />
  ), [enable]);

  return (
    <div
      ref={ref}
      style={{
        opacity,
        backgroundColor,
      }}
      className={styles.container}
    >

      <Link
        to={`/website/${website}/page/${id}`}
        className={cn(styles.link, styles.hover, `${id}` === `${selPageId}` && styles.active, !enable && styles.disabled)}
      >

        <ButtonSVG className={styles.button}>
          <Page
            width={16}
            heigth={16}
          />
        </ButtonSVG>

        <span className={styles.text} title={name}>
          {name}
        </span>

      </Link>

      <div className={styles.wrap}>

        <ButtonSVG
          id={id}
          className={styles.button}
          title={translate('Edit Page')}
          onClick={onOpenModalEdit}
        >
          <Settings width={16} heigth={16} />
        </ButtonSVG>

        <ButtonSVG className={styles.button}>
          {icon}
        </ButtonSVG>

      </div>

    </div>
  );
};

PageItem.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onOpenModalEdit: PropTypes.func.isRequired,
};

export default PageItem;
