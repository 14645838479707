import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.module.scss';

const Subtitle = ({
  tag: Tag, className, style, children,
  title,
}) => (
  <Tag
    className={cn(styles.subtitle, className)}
    title={title}
    style={style}
  >
    {children}
  </Tag>
);

Subtitle.defaultProps = {
  tag: 'p',
  className: '',
  style: {},
  title: '',
};

Subtitle.propTypes = {
  tag: PropTypes.string,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  style: PropTypes.object,
  title: PropTypes.string,
};

export default Subtitle;
