import React, { useMemo } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import slugify from 'slugify';
import { confirmAlert } from 'react-confirm-alert';
import Subtitle from '../../../../Subtitle/Subtitle';
import { Input } from '../../../../Input';
import { Button } from '../../../../Button';
import PageSelect from '../../../../../modules/LeftSidebar/components/PagesTab/components/PageSelect';
import { translate } from '../../../../../utils/translate';
import { promptOptions } from '../../../../../utils/promptOptions';
import { usePage } from '../../../../../hooks/usePage';
import styles from './styles.module.scss';

const PageModalContent = ({
  show, isEdit, currentWebsite, onClose,
}) => {
  const {
    currentPage,
    onCreate,
    onEdit,
    onRemove,
  } = usePage(show);

  const initValue = useMemo(() => {
    let value = {
      id: '',
      name: '',
      parent: '',
    };

    if (isEdit && currentPage && currentPage.id) {
      const {
        id, name, parent,
      } = currentPage;

      value = {
        id,
        name,
        parent: parent || '',
      };
    }

    return value;
  }, [isEdit, currentPage]);

  const schema = Yup.object().shape({
    id: Yup.number().nullable(),
    name: Yup.string().min(2).required(),
    parent: Yup.string().nullable(),
  });

  const handleSubmit = ({
    id, name, parent,
  }, { setFieldError }) => {
    const data = new FormData();

    data.append('id', id);
    data.append('website', currentWebsite.id);
    data.append('name', name);
    data.append('slug', slugify(name, {
      replacement: '-',
      remove: /[*+~.()'"!:@]/g,
      lower: true,
      strict: true,
    }));
    data.append('parent', parent);
    data.append('is_folder', true);

    const action = id ? onEdit : onCreate;

    action(data, { setFieldError, onSuccess: onClose });
  };

  const handleRemove = () => {
    confirmAlert(promptOptions({
      buttons: [
        {
          label: 'No',
        },
        {
          label: 'Yes',
          color: 'danger',
          onClick: () => onRemove(initValue.id, { onSuccess: onClose }),
        },
      ],
    }));
  };

  const pageSelect = useMemo(() => (
    <PageSelect
      name="parent"
      placeholder={translate('Location')}
      webId={currentWebsite?.id}
      pageId={currentPage?.id}
      filterChild
    />
  ), [currentPage, currentWebsite]);

  return (
    <Formik
      initialValues={initValue}
      validationSchema={schema}
      onSubmit={handleSubmit}
      enableReinitialize
    >

      <Form>

        <div className={styles.row}>

          <Subtitle className={styles.subtitle}>{translate('Name')}</Subtitle>

          <Input
            id="name"
            name="name"
            type="text"
            styleType="2"
            placeholder={translate('Folder name')}
          />

        </div>

        <div className={styles.row}>

          <Subtitle className={styles.subtitle}>{translate('Location')}</Subtitle>

          {pageSelect}

        </div>

        <div className={styles.wrapButtons}>
          {(onRemove && initValue.id) && <Button onClick={handleRemove} color="danger">{translate('Remove')}</Button>}

          <Button type="submit">{translate('Submit')}</Button>
        </div>

      </Form>

    </Formik>
  );
};

export default PageModalContent;
