import { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { websiteFilesSelector } from '../services/selectors/websites';
import { geFiles, createFile, removeFile } from '../services/actions/websites';

export const useWebsiteStatic = (id) => {
  const selector = useCallback((store) => websiteFilesSelector(store, id), [id]);

  const files = useSelector((store) => selector(store));

  const onCreateFile = createFile;
  const onRemoveFile = removeFile;

  useEffect(() => {
    if (typeof id === 'number' && !files) geFiles(id);
  }, [id, files]);

  return {
    files: files || [],
    onCreateFile,
    onRemoveFile,
  };
};
