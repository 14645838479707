import React from 'react';
import cn from 'classnames';
import { ReactComponent as TabletLandscape } from '../../../../assets/icons/tablet-landscape.svg';
import { ReactComponent as Desktop } from '../../../../assets/icons/desktop.svg';
import { ReactComponent as Tablet } from '../../../../assets/icons/tablet.svg';
import { ReactComponent as Mobile } from '../../../../assets/icons/mobile.svg';
import { ReactComponent as Close } from '../../../../assets/icons/close.svg';
import { ReactComponent as World } from '../../../../assets/icons/world.svg';
import { ReactComponent as Eye } from '../../../../assets/icons/eye.svg';
import ButtonSVG from '../../../../components/ButtonSVG';
import Devices from '../../../../components/Devices';
import Device from '../../../../components/Devices/Device';
import Panel from '../../../../components/Panel';
import styles from './styles.module.scss';

const FooterPanel = ({
  fullScreen, device, languages, language, handleFullScreen, setDevice, onChangeLanguage,
}) => {
  const onFullScreen = () => handleFullScreen(!fullScreen);

  return (
    <Panel className={cn(styles.container, 'footer')}>

      <ButtonSVG
        onClick={onFullScreen}
        className={styles.eye}
      >
        {fullScreen
          ? (<Close />)
          : (<Eye />)}
      </ButtonSVG>

      <Devices>

        {
          fullScreen && (
            <Device
              id=" "
              device={device}
              onClick={setDevice}
            >
              <World />
            </Device>
          )
        }

        <Device
          id="desktop"
          device={device}
          onClick={setDevice}
        >
          <Desktop />
        </Device>

        <Device
          id="tabletLandscape"
          device={device}
          onClick={setDevice}
        >
          <TabletLandscape />
        </Device>

        <Device
          id="tablet"
          device={device}
          onClick={setDevice}
        >
          <Tablet />
        </Device>

        <Device
          id="mobile"
          device={device}
          onClick={setDevice}
        >
          <Mobile />
        </Device>

      </Devices>

      { languages.length > 0 && language && languages.map((lang) => (
        <ButtonSVG
          key={lang}
          id={lang}
          onClick={onChangeLanguage}
          className={cn(
            [styles.langButton],
            { [styles.active]: lang === language },
          )}
        >
          {lang}
        </ButtonSVG>
      ))}

    </Panel>
  );
};

export default FooterPanel;
