import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import Modal from '../../components/Modal/Modal';
import { usePage } from '../../hooks/usePage';
import ContentModal from './components/ContentModal';

const ModalPage = ({
  show, currentWebsite, onClose,
}) => {
  const {
    currentPage,
    onCreate,
    onEdit,
    onRemove,
  } = usePage(show);

  const contentTypes = [
    {
      label: 'text/html',
      value: 'text/html',
    },
    {
      label: 'text/plain',
      value: 'text/plain',
    },
    {
      label: 'text/xml',
      value: 'text/xml',
    },
  ];

  const isEdit = typeof show === 'number';

  let initValue = {
    id: null,
    enable: false,
    name: '',
    slug: '',
    parent: '',
    content_type: 'text/html',
    show_in_menu: false,
    allow_iframe: false,
    label: '',
    title: '',
    seo: '',
    description: '',
    canonical: '',
    template: null,
  };

  if (isEdit && typeof currentPage?.id === 'number') {
    const {
      id,
      enable,
      name,
      slug,
      parent,
      content_type,
      show_in_menu = false,
      allow_iframe = false,
      label,
      seo_title,
      seo_keywords,
      main_image,
      seo_description,
      seo_canonical,
    } = currentPage;

    initValue = {
      id,
      enable,
      name,
      slug,
      parent: parent || '',
      content_type: content_type || '',
      show_in_menu,
      allow_iframe,
      label: label || '',
      title: seo_title || '',
      seo: seo_keywords || '',
      web_clip: main_image || null,
      description: seo_description || '',
      canonical: seo_canonical || '',
      template: null,
    };
  }

  const schema = Yup.object().shape({
    id: Yup.number()
      .nullable(),
    enable: Yup.bool(),
    name: Yup.string()
      .min(2)
      .required(),
    slug: Yup.string()
      .min(2)
      .required(),
    parent: Yup.string().nullable(),
    content_type: Yup.string().nullable(),
    show_in_menu: Yup.bool(),
    allow_iframe: Yup.bool(),
    label: Yup.string()
      .when('show_in_menu', {
        is: true,
        then: Yup.string().min(2).required(),
        otherwise: Yup.string(),
      }),
    title: Yup.string()
      .required(),
    seo: Yup.string(),
    description: Yup.string(),
    canonical: Yup.string().nullable(),
    template: Yup.number()
      .nullable(),
  });

  const onSubmit = (
    {
      id, enable, name, slug, parent, content_type, show_in_menu, allow_iframe,
      label, title, seo, description, template, web_clip, canonical,
    },
    { setFieldError, setFieldTouched },
  ) => {
    const data = new FormData();

    data.append('website', currentWebsite.id);
    data.append('id', id);
    data.append('enable', enable);
    data.append('name', name);
    data.append('slug', slug);
    data.append('parent', parent);
    data.append('content_type', content_type);
    data.append('show_in_menu', show_in_menu);
    data.append('allow_iframe', allow_iframe);
    data.append('label', label);
    data.append('seo_title', title || '');
    data.append('seo_keywords', seo || '');
    if (web_clip && web_clip instanceof File) {
      data.append('main_image', web_clip);
    }
    data.append('seo_description', description || '');
    data.append('seo_canonical', canonical || '');
    data.append('template', template || '');

    const action = isEdit ? onEdit : onCreate;

    action(data, { setFieldError, setFieldTouched, onSuccess: onClose });
  };

  const content = useMemo(() => (
    <ContentModal
      isEdit={isEdit}
      currentPage={currentPage}
      contentTypes={contentTypes}
      currentWebsite={currentWebsite}
      onRemove={onRemove}
      onClose={onClose}
    />
  ), [
    isEdit, currentPage, contentTypes, currentWebsite, onRemove,
    onClose,
  ]);

  return (
    <Modal show={Boolean(show)} onClose={onClose}>

      <Formik
        initialValues={initValue}
        validationSchema={schema}
        onSubmit={onSubmit}
        enableReinitialize
      >

        <Form noValidate>

          {content}

        </Form>

      </Formik>

    </Modal>
  );
};

ModalPage.defaultProps = {
  show: false,
};

ModalPage.propTypes = {
  show: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  onClose: PropTypes.func.isRequired,
};

export default ModalPage;
