import React, { useRef } from 'react';
import { useParams } from 'react-router';
import { useDrop, useDrag } from 'react-dnd';
import { dropTypes } from '../../../../../../utils/dropTypes';
import useSnippetAtPageDND from '../../../../../../hooks/useSnippetAtPageDND';

const SnippetAtPageDropWrap = ({
  item, children,
}) => {
  const ref = useRef(null);

  const { pageId } = useParams();

  const {
    onCreate,
    onEdit,
  } = useSnippetAtPageDND();

  const { SNIPPET, SNIPPET_AT_PAGE, SNIPPET_AT_PAGE_CONTAINER } = dropTypes;

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: [SNIPPET, SNIPPET_AT_PAGE, SNIPPET_AT_PAGE_CONTAINER],
    canDrop(itemDrag) {
      switch (itemDrag.type) {
        case SNIPPET:
          return itemDrag.snippet.public_status;

        default:
          return true;
      }
    },
    drop(dragItem, monitor) {
      const didDrop = monitor.didDrop();

      if (didDrop) return;

      if (dragItem.type === SNIPPET) {
        // console.log(`CREATE CHILD ${SNIPPET}`);
        const data = {
          parent: item.id,
          page: +pageId,
          snippet: dragItem.snippet.id,
        };

        onCreate(data);
      }

      if (dragItem.type === SNIPPET_AT_PAGE || dragItem.type === SNIPPET_AT_PAGE_CONTAINER) {
        // console.log('SET CHILD', SNIPPET_AT_PAGE, dragItem.snippetAtPage, item);
        const data = {
          ...dragItem.snippetAtPage,
          parent: item.id,
          order: null,
        };

        onEdit(data);
      }
    },
    collect: (monitor) => ({
      // isOverCurrent: monitor.isOver({ shallow: true }),
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: dropTypes.PAGE,
      page: item.page,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;

  const backgroundColor = isOver && canDrop ? 'rgba(0,0,0,.2)' : 'transparent';

  drag(drop(ref));

  return (
    <div
      ref={ref}
      style={{
        opacity,
        backgroundColor,
      }}
    >
      {children}
    </div>
  );
};

export default SnippetAtPageDropWrap;
