import React from 'react';
import slugify from 'slugify';
import { useFormikContext } from 'formik';
import { Input } from '../../../../components/Input';
import { translate } from '../../../../utils/translate';

const PageInputName = ({ editingSlug }) => {
  const { setFieldValue } = useFormikContext();

  const handleChange = (e, setValue) => {
    if (editingSlug) {
      const currentValue = slugify(e.target.value, {
        replacement: '-',
        remove: /[*+~.()'"!:@]/g,
        lower: true,
        strict: true,
      });

      setFieldValue('slug', currentValue);
    }

    setValue();
  };

  return (
    <Input
      type="text"
      name="name"
      id="name"
      placeholder={translate('Page name')}
      onChange={handleChange}
      styleType="2"
    />
  );
};

export default PageInputName;
