import React, { useRef } from 'react';
import omit from 'lodash/omit';
import { useDrop, useDrag } from 'react-dnd';
import { dropTypes } from '../../../../../../utils/dropTypes';
import { editPage } from '../../../../../../services/actions/pages';

const PageDropWrap = ({
  item, children,
}) => {
  const ref = useRef(null);

  const [{ isOverCurrent }, drop] = useDrop({
    accept: [dropTypes.PAGE],
    drop(dragItem, monitor) {
      const didDrop = monitor.didDrop();

      if (didDrop) return;

      if (dragItem.type === dropTypes.PAGE && dragItem.page.parent !== item.page.id) {
        const { tree, ...page } = dragItem.page;
        const p = omit(page, ['main_image']);

        editPage({
          ...p,
          parent: item.page.id,
        });
      }
    },
    collect: (monitor) => ({
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
  });

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: dropTypes.PAGE,
      page: item.page,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;

  const backgroundColor = isOverCurrent ? 'rgba(0,0,0,.2)' : 'transparent';

  drag(drop(ref));

  return (
    <div
      ref={ref}
      style={{
        opacity,
        backgroundColor,
      }}
    >
      {children}
    </div>
  );
};

export default PageDropWrap;
