import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Close } from '../../../../../../../assets/icons/close.svg';
import { ReactComponent as ChevronDown } from '../../../../../../../assets/icons/chevron-down.svg';
import { InputUnControlled } from '../../../../../../Input';
import ButtonSVG from '../../../../../../ButtonSVG';
import styles from './styles.module.scss';
import { useMediaTab } from '../../../../../../../hooks/useMediaTab';

const MediaSelectView = ({
  field, placeholder, onOpen, onClear,
}) => {
  const {
    categories,
  } = useMediaTab();

  const value = useMemo(() => {
    if (!field.value) return '';

    const currentCategory = categories
      .find((category) => category.relative_path === field.value);

    return currentCategory && currentCategory.name ? currentCategory.name : '';
  }, [field.value, categories]);

  return (
    <div className={styles.container} onClick={onOpen}>

      <InputUnControlled
        placeholder={placeholder}
        styleType="2"
        disabled
        value={value}
        classNameControl={styles.input}
      />

      { value
        ? (
          <ButtonSVG
            className={styles.button}
            onClick={onClear}
          >
            <Close width={24} height={24} />
          </ButtonSVG>
        )
        : (
          <ButtonSVG
            className={styles.button}
            onClick={onOpen}
          >
            <ChevronDown width={22} height={22} />
          </ButtonSVG>
        )}

    </div>
  );
};

MediaSelectView.defaultProps = {
  placeholder: '',
};

MediaSelectView.propTypes = {
  placeholder: PropTypes.string,
  field: PropTypes.object.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default MediaSelectView;
