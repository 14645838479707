import React, {
  useState, useCallback, useMemo, useEffect,
} from 'react';
import md5 from 'js-md5';
import axios from 'axios';
import slugify from 'slugify';
import { toast } from 'react-toastify';
import { Button } from '../../../../Button';
import { translate } from '../../../../../utils/translate';
import formatBytes from '../../../../../utils/formatBytes';
import useBigMedia from '../../../../../hooks/useBigMedia';
import styles from '../../styles.module.scss';

const CHUNK_SIZE = 1000000;

const { CancelToken } = axios;
const source = CancelToken.source();

const ModalContent = ({ files, directory = '', onClose }) => {
  const [currentSize, setCurrentSize] = useState(0);

  const [fileIndex, setFileIndex] = useState(0);

  const {
    getAllByPath,
    onCreate,
    onCreateBig,
    onCancelBig,
  } = useBigMedia();

  const cutFile = useCallback((file) => {
    const chunkLength = Math.ceil(file.byteLength / CHUNK_SIZE);

    return Array(chunkLength).fill(0).map((_, index) => {
      const currentWay = CHUNK_SIZE * (index + 1);
      return file.slice(currentWay - CHUNK_SIZE, currentWay);
    });
  }, []);

  const nextFile = useCallback(() => setFileIndex((i) => i + 1), []);

  const addSize = useCallback((size) => setCurrentSize((acc) => acc + size), []);

  const renameFile = useCallback((file) => {
    const { name, type, lastModified } = file;

    const fileArray = name.split('.');

    const fileExtension = fileArray.pop();

    const fileName = fileArray.join('.');

    const newFileName = slugify(fileName, {
      replacement: '_',
      lower: true,
      strict: true,
      remove: /[*+~.()'"!:@]/g,
    });

    return new window.File(
      [file],
      `${newFileName}.${fileExtension}`,
      {
        type,
        lastModified,
      },
    );
  }, []);

  const uploadFile = useCallback((currentFile) => {
    if (currentFile.type.match(process.env.REACT_APP_UPLOAD_MEDIA_TYPE)) {
      const file = renameFile(currentFile);

      if (file.size <= CHUNK_SIZE) {
        const data = new FormData();

        data.append('file', file);
        data.append('directory_path', `/${directory}`);

        onCreate(data).then(() => {
          addSize(file.size);
          nextFile();
        });
      } else {
        const fileReader = new FileReader();

        fileReader.onload = () => {
          const hash = md5.create();

          hash.update(fileReader.result);

          const md5file = hash.hex();

          const chunks = cutFile(fileReader.result);

          const sendChunk = (item = 0, size = 0) => {
            addSize(size);

            if (chunks.length > item) {
              const chunk = chunks[item];

              const { name, type, lastModified } = file;

              const newFile = new window.File([chunk], name, {
                lastModified,
                type,
              });

              const data = new FormData();

              data.append('file', newFile);
              data.append('path', `/${directory}`);
              data.append('name', file.name);
              data.append('md5', md5file);
              data.append('x_start_byte', `${item === 0 ? item : item * CHUNK_SIZE}`);

              onCreateBig(data, {
                cancelToken: source.token,
              })
                .then(() => {
                  sendChunk(item + 1, chunk.byteLength);
                }).catch(() => {
                  onCancelBig({
                    directory_path: file.name,
                  });

                  getAllByPath(directory);

                  onClose(false);
                });
            } else {
              nextFile();
            }
          };

          sendChunk();
        };

        fileReader.readAsArrayBuffer(file);
      }
    } else {
      toast.error(translate(`File (${currentFile.name}) has wrong extension`));

      addSize(currentFile.size);

      nextFile();
    }
  }, [
    directory, nextFile, cutFile, onCreate, onCreateBig, addSize, renameFile,
    getAllByPath, onCancelBig, onClose,
  ]);

  const totalSize = useMemo(
    () => (files ? files.reduce((accumulator, file) => accumulator + file.size, 0) : 0),
    [files],
  );

  const onEnd = useCallback(() => {
    onClose(false);

    getAllByPath(directory);
  }, [directory, onClose, getAllByPath]);

  const handleClose = useCallback(() => {
    source.cancel('Operation canceled by the user.');
  }, []);

  useEffect(() => {
    if (files && fileIndex < files.length) {
      uploadFile(files[fileIndex]);
    } else {
      onEnd();
    }
  }, [files, fileIndex, uploadFile, onEnd]);

  const status = useMemo(() => (
    `${fileIndex} / ${files.length} files – ${formatBytes(currentSize)} / ${formatBytes(totalSize)}`
  ), [fileIndex, files.length, currentSize, totalSize]);

  const progress = useMemo(() => {
    const percent = Math.floor((currentSize / totalSize) * 100);

    return `${percent} %`;
  }, [currentSize, totalSize]);

  const loaderItems = useMemo(() => {
    const length = Math.floor((currentSize / totalSize) * 53);

    return Array(Number.isInteger(length) ? length : 0).fill(0);
  }, [currentSize, totalSize]);

  return (
    <>

      <div className={styles.loader}>

        {
          loaderItems.length > 0 && loaderItems.map((_, index) => (
            <div key={index} className={styles.progressItem} />
          ))
        }

        <span className={styles.progress}>{progress}</span>

      </div>

      <div className={styles.wrap}>

        <p>{status}</p>

        <Button
          className={styles.button}
          onClick={handleClose}
        >
          {translate('Cancel')}
        </Button>

      </div>

    </>
  );
};

export default ModalContent;
