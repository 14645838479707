import React, {
  useState, useRef, useMemo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import MediaSelectView from './components/MediaSelectView';
import MediaSelectModal from './components/MediaSelectModal';
import styles from './styles.module.scss';

const MediaSelect = ({
  name, placeholder, currentCategory,
}) => {
  const ref = useRef();

  const [field, , helpers] = useField(name);

  const [open, setOpen] = useState(false);

  const onOpen = () => setOpen(true);

  const onClose = () => setOpen(false);

  const onClear = useCallback(() => helpers.setValue(''), [helpers]);

  const modal = useMemo(() => {
    const onSelect = (value) => {
      helpers.setValue(value);

      onClose();
    };

    return (open && (
      <MediaSelectModal
        divRef={ref}
        currentCategory={currentCategory}
        value={field.value}
        onSelect={onSelect}
        onClose={onClose}
      />
    ));
  },
  [open, currentCategory, helpers, field.value]);

  const view = useMemo(() => (
    <MediaSelectView
      placeholder={placeholder}
      field={field}
      onOpen={onOpen}
      onClear={onClear}
    />
  ), [placeholder, field, onClear]);

  return (
    <div ref={ref} className={styles.container}>

      {view}

      {modal}

    </div>
  );
};

MediaSelect.defaultProps = {
  placeholder: '',
};

MediaSelect.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default MediaSelect;
