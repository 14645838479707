import React from 'react';
import { ErrorMessage } from 'formik';
import Title from '../../../../components/Title/Title';
import Subtitle from '../../../../components/Subtitle/Subtitle';
import { Input } from '../../../../components/Input';
import Row from '../Row';
import ErrorTooltip from '../ErrorTooltip';
import { translate } from '../../../../utils/translate';
import styles from './styles.module.scss';

const WebsiteDefaults = () => (
  <section className={styles.container}>

    <Title className={styles.title}>{translate('Website defaults')}</Title>

    <Subtitle>
      {translate('Title')}
      {' '}
      {/* <Mandatory title={translate('Mandatory')} /> */}
    </Subtitle>
    <Row
      first={(
        <Input
          type="text"
          name="title"
          id="title"
          placeholder={translate('Title')}
          styleType="2"
        />
      )}
      second={<ErrorMessage name="title" component={ErrorTooltip} />}
    />

    <Subtitle>{translate('Keywords')}</Subtitle>
    <Row
      first={(
        <Input
          type="textarea"
          name="seo"
          id="seo"
          placeholder={translate('Keywords')}
          styleType="2"
        />
      )}
      second={<ErrorMessage name="seo" component={ErrorTooltip} />}
    />

    <Subtitle>
      {translate('Description')}
      {' '}
      {/* <Mandatory title={translate('Mandatory')} /> */}
    </Subtitle>
    <Row
      first={(
        <Input
          type="textarea"
          name="seo_description"
          id="seo_description"
          placeholder={translate('Description')}
          styleType="2"
        />
      )}
      second={<ErrorMessage name="seo_description" component={ErrorTooltip} />}
    />

  </section>
);

export default WebsiteDefaults;
