import { requestWrapper } from '../../utils/requestWrapper';
import { wrapWebsite, wrapSimple, wrapCategory } from '../../utils/actionWraps';
import { WEBSITES } from '../../constants/type';
import { API_WEBSITE } from '../../constants/api';

// export const setWebsitesListAction = createAction(types.NAVBAR.SET_WEBSITE_LIST);

export const geFiles = (webId) => {
  const request = () => requestWrapper(
    WEBSITES.GET_FILES,
    `${API_WEBSITE.FILES_URL}?website=${webId}`,
    'get',
    false,
    { webId },
    true,
  );

  wrapSimple(request);
};

export const createFile = (requestData) => {
  const request = () => requestWrapper(
    WEBSITES.CREATE_FILE,
    API_WEBSITE.FILES_URL,
    'post',
    false,
    requestData,
  );

  wrapSimple(request);
};

export const removeFile = (requestData) => {
  const { id, webId } = requestData;

  const request = () => requestWrapper(
    WEBSITES.REMOVE_FILE,
    `${API_WEBSITE.FILES_URL}${webId}/${id}/`,
    'delete',
    false,
    requestData,
    true,
  );

  wrapSimple(request);
};

export const geList = () => {
  const request = () => requestWrapper(
    WEBSITES.GET_LIST,
    API_WEBSITE.LIST_URL,
    'get',
    false,
  );

  wrapSimple(request);
};

export const createWebsite = (requestData, actions) => {
  const request = () => requestWrapper(
    WEBSITES.CREATE,
    API_WEBSITE.LIST_URL,
    'post',
    false,
    requestData,
  );

  wrapWebsite(request, actions);
};

export const editWebsite = (requestData, actions) => {
  const id = requestData.get ? requestData.get('id') : requestData.id;

  const request = () => requestWrapper(
    WEBSITES.EDIT,
    `${API_WEBSITE.LIST_URL}${id}/`,
    'put',
    false,
    requestData,
  );

  wrapWebsite(request, actions);
};

export const getCurrentWebsite = (id) => {
  const request = () => requestWrapper(
    WEBSITES.GET_CURRENT_WEBSITE,
    `${API_WEBSITE.LIST_URL}${id}/`,
    'get',
  );

  wrapSimple(request);
};

export const getCategories = () => {
  const request = () => requestWrapper(
    WEBSITES.GET_CATEGORIES,
    API_WEBSITE.CATEGORIES_URL,
    'get',
    false,
  );

  wrapSimple(request);
};

export const createCategory = (requestData, actions) => {
  const request = () => requestWrapper(
    WEBSITES.CREATE_CATEGORY,
    API_WEBSITE.CATEGORIES_URL,
    'post',
    false,
    requestData,
  );

  wrapCategory(request, actions);
};

export const editCategory = (requestData, actions) => {
  const { id } = requestData;

  const request = () => requestWrapper(
    WEBSITES.EDIT_CATEGORY,
    `${API_WEBSITE.CATEGORIES_URL}${id}/`,
    'put',
    false,
    requestData,
  );

  wrapCategory(request, actions);
};

export const removeCategory = (id, actions) => {
  const request = () => requestWrapper(
    WEBSITES.REMOVE_CATEGORY,
    `${API_WEBSITE.CATEGORIES_URL}${id}/`,
    'delete',
  );

  wrapCategory(request, actions);
};

export const getLanguages = () => {
  const request = () => requestWrapper(
    WEBSITES.GET_LANGUAGES,
    API_WEBSITE.LANGUAGES_URL,
    'get',
    false,
  );

  wrapSimple(request);
};

export const getDomain = () => {
  const request = () => requestWrapper(
    WEBSITES.GET_DOMAIN,
    API_WEBSITE.DOMAIN_URL,
    'get',
    false,
  );

  wrapSimple(request);
};
