import { Tab } from 'react-tabs';
import React from 'react';
import styles from './styles.module.scss';

const ModalTab = ({ key, children }) => (
  <Tab
    key={key}
    className={styles.tab}
    selectedClassName={styles.active}
  >
    {children}
  </Tab>
);

export default ModalTab;
