import React, { useRef, useState, useMemo } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { ReactComponent as Upload } from '../../../../assets/icons/upload.svg';
import { ReactComponent as ChevronRight } from '../../../../assets/icons/chevron-right.svg';
import Title from '../../../../components/Title/Title';
import { Button } from '../../../../components/Button';
import { Accordion } from '../../../../components/Accordion';
import { InputSearch } from '../../../../components/InputSearch';
import { AccordionHeader } from '../../../../components/Accordion/components/Header';
import { InputUploadUnControlled } from '../../../../components/inputUpload/inputUpload';
import { translate } from '../../../../utils/translate';
import { promptOptions } from '../../../../utils/promptOptions';
import { useWebsiteStatic } from '../../../../hooks/useWebsiteStatic';
import AssetForm from '../AssetForm';
import styles from './styles.module.scss';

const FileManager = ({ webId }) => {
  const [search, setSearch] = useState('');

  const {
    files,
    onCreateFile,
    onRemoveFile,
  } = useWebsiteStatic(webId);

  const inputUploadRef = useRef(null);

  const directories = [
    {
      title: 'CSS',
      value: 'css',
    },
    {
      title: 'JS',
      value: 'js',
    },
    {
      title: 'JSON',
      value: 'json',
    },
    {
      title: 'TXT',
      value: 'txt',
    },
    {
      title: 'Other',
      value: 'other',
    },
  ];

  const onUpload = (e) => {
    const file = e.currentTarget.files[0];

    if (file) {
      const slugArr = file.name.split('.');

      const data = new FormData();

      data.append('field_type', slugArr.pop().toLowerCase());
      data.append('slug', slugArr.join('.'));
      data.append('website', webId);
      data.append('content', file);

      onCreateFile(data);

      e.currentTarget.value = null;
    }
  };

  const triggerUploadInput = () => {
    inputUploadRef.current.click();
  };

  const options = useMemo(
    () => {
      const onDelete = (id) => {
        confirmAlert(promptOptions({
          buttons: [
            {
              label: 'No',
            },
            {
              label: 'Yes',
              color: 'danger',
              onClick: () => onRemoveFile({ webId, id }),
            },
          ],
        }));
      };

      const searchValue = search.trim().toLowerCase();

      return directories
        .map((directory) => {
          const directoryFiles = files
            .filter(({ slug, field_type }) => (searchValue.length > 2
              ? (slug.toLowerCase().indexOf(searchValue) !== -1)
              : true) && (
              directory.value === 'other'
                ? (field_type !== 'js' && field_type !== 'css' && field_type !== 'txt' && field_type !== 'json')
                : (field_type === directory.value)
            ))
            .map(({
              id, size, slug, field_type,
            }) => ({
              id,
              size,
              extension: field_type,
              name: slug,
            }));

          return {
            ...directory,
            items: directoryFiles,
          };
        })
        .filter((directory) => directory.items.length > 0)
        .map(({ title, value, items }) => ({
          header: <AccordionHeader
            hasChildren
            title={title}
            leftIcons={<ChevronRight width={24} height={24} />}
          />,
          content: (
            <AssetForm
              items={items}
              name={value}
              onDelete={onDelete}
            />
          ),
        }));
    },
    [webId, directories, search, files, onRemoveFile],
  );

  return (
    <section className={styles.container}>

      <Title className={styles.title}>{translate('File Manager')}</Title>

      <div className={styles.wrap}>

        <div className={styles.buttons}>

          <InputUploadUnControlled
            name="file-upload"
            ref={inputUploadRef}
            onChange={onUpload}
          />

          <Button
            type="button"
            className={styles.button}
            onClick={triggerUploadInput}
          >
            <Upload />
            {translate('Upload')}
          </Button>

        </div>

        <InputSearch
          className={styles.search}
          placeholder={translate('Search file')}
          onChange={setSearch}
          value={search}
        />

      </div>

      <div className={styles.content}>

        {options.length > 0 && options.map((item, index) => (
          <Accordion
            noGap
            withBorder={false}
            item={item}
            key={index}
          />
        ))}

      </div>

    </section>
  );
};

export default FileManager;
