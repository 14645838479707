import { requestWrapper } from '../../utils/requestWrapper';
import { wrapSimple, wrapCategory } from '../../utils/actionWraps';
import { API_MEDIA } from '../../constants/api';
import { MEDIA } from '../../constants/type';

export const getAll = async () => {
  const request = () => requestWrapper(
    MEDIA.GET_ALL,
    `${API_MEDIA.ALL_URL}?dir_path=/`,
    'get',
  );

  wrapSimple(request);
};

export const getAllByPath = async (path) => {
  const request = () => requestWrapper(
    MEDIA.GET_ALL_BY_PATH,
    `${API_MEDIA.ALL_URL}?dir_path=/${path}`,
    'get',
    null,
    {
      parent: path,
    },
    true,
  );

  wrapSimple(request);
};

export const createMedia = async (requestData) => {
  const request = () => requestWrapper(
    MEDIA.CREATE,
    API_MEDIA.ALL_URL,
    'post',
    false,
    requestData,
  );

  return request();
};

export const createBigMedia = async (requestData, options) => {
  const request = () => requestWrapper(
    MEDIA.CREATE,
    API_MEDIA.BIG_MEDIA,
    'post',
    false,
    requestData,
    false,
    undefined,
    undefined,
    options,
  );

  return request();
};

export const cancelBigMedia = async (requestData) => {
  const request = () => requestWrapper(
    MEDIA.CANCEL,
    API_MEDIA.ALL_URL,
    'delete',
    false,
    requestData,
  );

  wrapSimple(request);
};

export const editMedia = async (requestData) => {
  const request = () => requestWrapper(
    MEDIA.EDIT,
    API_MEDIA.CATEGORIES_URL,
    'put',
    false,
    requestData,
    true,
  );

  wrapSimple(request);
};

export const removeMedia = async (requestData) => {
  /*const request = () => requestWrapper(
    MEDIA.REMOVE,
    API_MEDIA.ALL_URL,
    'delete',
    false,
    requestData,
  );

  wrapSimple(request);*/
};

export const createCategory = async (requestData, actions) => {
  const request = () => requestWrapper(
    MEDIA.CREATE_CATEGORY,
    API_MEDIA.CATEGORIES_URL,
    'post',
    false,
    requestData,
    true,
  );

  wrapCategory(request, actions);
};

export const editCategory = async (requestData, actions) => {
  const request = () => requestWrapper(
    MEDIA.EDIT_CATEGORY,
    API_MEDIA.CATEGORIES_URL,
    'put',
    null,
    requestData,
    true,
  );

  wrapCategory(request, actions);
};

export const removeCategory = async (id, actions) => {
  const request = () => requestWrapper(
    MEDIA.REMOVE_CATEGORY,
    `${API_MEDIA.CATEGORIES_URL}${id}/`,
    'delete',
  );

  wrapCategory(request, actions);
};
