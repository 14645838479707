import React from 'react';
import IMAGE from '../../../../../assets/images/asset_img.jpeg';
import formatBytes from '../../../../../utils/formatBytes';
import styles from './styles.module.scss';

const AssetItem = ({ item, onChose }) => {
  const {
    absolute_path, preview, name, extension, size,
  } = item;

  const handleChose = () => onChose(item);

  return (
    <div className={styles.container} onClick={handleChose}>

      <figure className={styles.image}>
        <img src={preview || absolute_path || IMAGE} alt="asset" />
      </figure>

      <div>

        <h3 className={styles.title}>
          {name}
          <span>{`.${extension}`}</span>
        </h3>

        {/* <p className={styles.description}>{`${ratio} • ${size}`}</p> */}

        <p className={styles.description}>{`${formatBytes(size)}`}</p>

      </div>

    </div>
  );
};

export default AssetItem;
