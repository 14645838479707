import React from 'react';
import { baseApi } from '../../../../utils/apiService';
import { translate } from '../../../../utils/translate';
import { API_PAGE_PREVIEW } from '../../../../constants/api';

const Iframe = ({ pageId, language, className }) => {
  const languageIso = language || '';

  const src = pageId && languageIso
    ? `${API_PAGE_PREVIEW(pageId, baseApi.AddToken)}&lang=${languageIso}&r=${Math.random()}`
    : '';

  return (
    <iframe
      className={className}
      src={src}
      title={translate('Page ')}
    />
  );
};

export default Iframe;
