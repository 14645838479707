import React, { useState, useMemo } from 'react';
import cn from 'classnames';
import { ReactComponent as Desktop } from '../../../../assets/icons/desktop.svg';
import { ReactComponent as Mobile } from '../../../../assets/icons/mobile.svg';
import { ReactComponent as Tablet } from '../../../../assets/icons/tablet.svg';
import { ReactComponent as TabletLandscape } from '../../../../assets/icons/tablet-landscape.svg';
import Panel from '../../../../components/Panel';
import Devices from '../../../../components/Devices';
import Device from '../../../../components/Devices/Device';
import { baseApi } from '../../../../utils/apiService';
import { translate } from '../../../../utils/translate';
import useRenderSnippet from '../../../../hooks/useRenderSnippet';
import { API_SNIPPET_PREVIEW } from '../../../../constants/api';
import styles from './styles.module.scss';

const RenderSnippet = ({ code, codeJs, codeCss, cssCodeType, className }) => {
  const key = useRenderSnippet(code, codeJs, codeCss, cssCodeType);

  const [device, setDevice] = useState('desktop');

  const frame = useMemo(() => (
    <iframe
      src={`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_API_URL}${API_SNIPPET_PREVIEW}?id=${key}&key=${baseApi.AddToken}`}
      style={{
        width: '100%',
        height: '100%',
        display: 'block',
      }}
      title={translate('Snippet preview')}
    />
  ), [key]);

  return (
    <div className={cn(styles.container, className)}>

      <Panel>

        <Devices>

          <Device
            id="desktop"
            device={device}
            onClick={setDevice}
          >
            <Desktop />
          </Device>

          <Device
            id="tabletLandscape"
            device={device}
            onClick={setDevice}
          >
            <TabletLandscape />
          </Device>

          <Device
            id="tablet"
            device={device}
            onClick={setDevice}
          >
            <Tablet />
          </Device>

          <Device
            id="mobile"
            device={device}
            onClick={setDevice}
          >
            <Mobile />
          </Device>

        </Devices>

      </Panel>

      <div
        className={cn(styles.wrap, styles[device])}
      >

        { frame }

      </div>

    </div>
  );
};

export default RenderSnippet;
