import React, { forwardRef } from 'react';
import { useField } from 'formik';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

/* eslint-disable react/jsx-props-no-spreading */
const customStyles = ({
  error = false, touched = false, dark, small,
}) => ({
  control: (provided) => ({
    ...provided,
    fontFamily: "'Roboto Condensed', sans-serif",
    fontSize: small ? 12 : 14,
    borderRadius: 0,
    borderWidth: 1,
    borderColor: error && touched ? 'red' : 'transparent',
    minHeight: small ? 24 : 32,
    backgroundColor: dark ? '#2B2B2B' : '#404040',
    color: small ? '#BEBEBE' : '#F6F6F6',
    boxShadow: 'none',
    '&:hover': {
      borderColor: 'transparent',
      boxShadow: 'none',
    },
  }),
  menu: (provided) => ({
    ...provided,
    fontFamily: "'Roboto Condensed', sans-serif",
    fontSize: small ? 12 : 14,
    borderRadius: 0,
    backgroundColor: '#2B2B2B',
    color: small ? '#BEBEBE' : '#F6F6F6',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: (state.isFocused || state.isSelected) && '#121212',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: small ? '#BEBEBE' : '#F6F6F6',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: 'transparent',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: small ? 0 : 4,
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: small ? 0 : 4,
  }),
});

const Select = forwardRef((props, ref) => {
  const [field = {}, meta = {}, helpers = {}] = useField(props);
  const { error, touched } = meta;

  const {
    selectedOption,
    onChange,
    options,
    dark,
    small,
  } = props;

  const handleChange = (option) => {
    const setValue = () => (helpers.setValue(option ? option.value : option));

    if (onChange) {
      onChange(setValue);
    } else {
      setValue();
    }
  };

  const handleBlur = () => {
    helpers.setTouched(true);
  };

  const value = field.value !== undefined
    ? options.find((option) => option.value === field.value)
    : selectedOption;

  return (
    <ReactSelect
      {...props}
      name={field.name}
      ref={ref}
      options={options}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      styles={customStyles({
        error,
        touched,
        dark,
        small,
      })}
    />
  );
});

export const SelectUnControlled = forwardRef((props, ref) => {
  const {
    selectedOption,
    onChange,
    options,
    value,
    dark,
    small,
  } = props;

  return (
    <div className={styles.select}>
      <ReactSelect
        value={value || selectedOption}
        onChange={onChange}
        options={options}
        {...props}
        ref={ref}
        styles={customStyles({
          dark,
          small,
        })}
      />
    </div>
  );
});

SelectUnControlled.defaultProps = {
  name: '',
};

SelectUnControlled.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  })).isRequired,
};

Select.defaultProps = {
  selectedOption: null,
  name: '',
};

Select.propTypes = {
  selectedOption: PropTypes.shape({
    label: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  }),
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.any.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ).isRequired,
};

export default Select;
