import React from 'react';
import { ErrorMessage } from 'formik';
import Title from '../../../../components/Title/Title';
import { Input } from '../../../../components/Input';
import Subtitle from '../../../../components/Subtitle/Subtitle';
import Row from '../../../ModalWebsite/components/Row';
import ErrorTooltip from '../../../ModalWebsite/components/ErrorTooltip';
import Mandatory from '../../../../components/Mandatory';
import { translate } from '../../../../utils/translate';
import styles from './styles.module.scss';
import UploadIcon from '../../../ModalWebsite/components/UploadIcon';

const Seo = () => (
  <section>

    <Title className={styles.title}>{translate('SEO')}</Title>

    <Subtitle>
      {translate('Title')}
      {' '}
      <Mandatory title={translate('Mandatory')} />
    </Subtitle>
    <Row
      first={(
        <Input
          type="text"
          name="title"
          id="title"
          placeholder={translate('Title')}
          styleType="2"
        />
      )}
      second={<ErrorMessage name="title" component={ErrorTooltip} />}
    />

    <Subtitle>
      {translate('Keywords')}
      {' '}
      {/* <Mandatory title={translate('Mandatory')} /> */}
    </Subtitle>
    <Row
      first={(
        <Input
          type="textarea"
          name="seo"
          id="seo"
          placeholder={translate('Keywords')}
          styleType="2"
        />
      )}
      second={<ErrorMessage name="seo" component={ErrorTooltip} />}
    />

    <Subtitle>
      {translate('Description')}
      {' '}
      {/* <Mandatory title={translate('Mandatory')} /> */}
    </Subtitle>
    <Row
      first={(
        <Input
          type="textarea"
          name="description"
          id="description"
          placeholder={translate('Description')}
          styleType="2"
        />
      )}
      second={<ErrorMessage name="description" component={ErrorTooltip} />}
      className={styles.lastInput}
    />


    <Subtitle>
      {translate('Canonical (URL)')}
      {' '}
    </Subtitle>
    <Row
      first={(
        <Input
          type="text"
          name="canonical"
          id="canonical"
          placeholder={translate('Canonical')}
          styleType="2"
        />
      )}
      second={<ErrorMessage name="canonical" component={ErrorTooltip} />}
    />

    <Row
      first={(
        <UploadIcon
          label={(
            <>
              { translate('Main image') }
              { ' ' }
            </>
          )}
          name="web_clip"
          description="Upload an optional image for page"
          id="web_clip"
        />
      )}
      second={<ErrorMessage name="description" component={ErrorTooltip} />}
      className={styles.lastInput}
    />

  </section>
);

export default Seo;
