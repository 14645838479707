import React, { useRef } from 'react';
import { useDrop, useDrag } from 'react-dnd';
import { dropTypes } from 'utils/dropTypes';
import { useMediaTab } from '../../../../../../hooks/useMediaTab';

const MediaDropWrap = ({
  item, children, onHoverHeader = () => {},
}) => {
  const ref = useRef(null);

  const {
    onEdit,
    onEditCategory,
  } = useMediaTab();

  const [{ isOverCurrent }, drop] = useDrop({
    accept: [dropTypes.MEDIA_DIRECTORY, dropTypes.MEDIA],
    drop(dragItem, monitor) {
      const didDrop = monitor.didDrop();

      if (didDrop) return;

      if (
        dragItem.type === dropTypes.MEDIA_DIRECTORY
        && dragItem.category.parent !== item.category.relative_path
        && dragItem.category.relative_path !== item.category.relative_path
      ) {
        onEditCategory({
          name: dragItem.category.name,
          path: item.category.relative_path,
          old_name: dragItem.category.name,
          old_path: dragItem.category.parent || '/',
          relative_path: dragItem.category.relative_path,
        });
      }

      if (
        dragItem.type === dropTypes.MEDIA
        && item.category
        && dragItem.media.parent !== item.category.relative_path
      ) {
        onEdit({
          oldMedia: dragItem.media,
          name: dragItem.media.path,
          path: item.category.relative_path,
          old_name: dragItem.media.path,
          old_path: dragItem.media.parent || '/',
          relative_path: dragItem.media.relative_path,
        });
      }
    },
    collect: (monitor) => ({
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
    hover: (item2, monitor) => {
      if (item.header === true || item.bottom === true) {
        onHoverHeader(item, item2);
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: dropTypes.MEDIA_DIRECTORY,
      category: item.category,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;

  const backgroundColor = isOverCurrent ? 'rgba(0,0,0,.2)' : 'transparent';

  drag(drop(ref));

  return (
    <div
      ref={ref}
      style={{
        flex: 1,
        opacity,
        backgroundColor,
      }}
    >
      {children}
    </div>
  );
};

export default MediaDropWrap;
