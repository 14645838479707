import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.module.scss';

const Row = ({ first, second, className }) => (
  <div className={cn(styles.row, className)}>
    <div className={styles.first}>{first}</div>
    <div className={styles.second}>{second}</div>
  </div>
);

Row.defaultProps = {
  first: '',
  second: '',
};

Row.propTypes = {
  first: PropTypes.node,
  second: PropTypes.node,
};

export default Row;
