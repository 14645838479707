import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import useTemplates from '../../../../../../hooks/useTemplates';
import TemplateItem from '../TemplateItem';

const ContentTemplates = ({ openModal, search }) => {
  const { templates } = useTemplates();

  const list = useMemo(() => {
    if (search) {
      return templates.filter((template) => (
        template.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
      ));
    }

    return templates;
  }, [search, templates]);

  return (
    <div>
      {
        list.length > 0 && list.map((template) => (
          <TemplateItem
            key={template.id}
            item={template}
            onEdit={openModal}
          />
        ))
      }
    </div>
  );
};

ContentTemplates.defaultProps = {
  search: '',
};

ContentTemplates.propTypes = {
  openModal: PropTypes.func.isRequired,
  search: PropTypes.string,
};

export default ContentTemplates;
