import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { snippetSelector } from '../services/selectors/snippets';
import { editSnippet, removeSnippet, createSnippet, getSnippet } from '../services/actions/snippets';
import createLoadingSelector from '../services/selectors/loading';
import { SNIPPETS } from '../constants/type';
import { createSelector } from 'reselect';

const useSnippet = (id) => {

  const selector = createSelector(
    snippetSelector,
    createLoadingSelector(SNIPPETS.GET_CATEGORIES.base),
    (snippet, snippetLoader) => ({
      snippet,
      snippetLoader,
    }),
  );

  let {snippet, snippetLoader} = useSelector(selector);

  useEffect(() => {
    let { loading, loaded, error } = snippetLoader;

    if (snippet && snippet.id !== id && !loading) {
      loaded = false;
    }

    if (!loading && !loaded && !error) getSnippet(id);
  }, [snippetLoader, snippet, id]);

  const onCreate = createSnippet;
  const onEdit = editSnippet;
  const onRemove = removeSnippet;

  if (snippet === undefined) {
    snippet ={};
  }

  return {
    snippet,
    onCreate,
    onEdit,
    onRemove,
  };
};

export default useSnippet;
