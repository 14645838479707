import React from 'react';
import cn from 'classnames';
import { Button } from '../../index';
import styles from './styles.module.scss';

/* eslint-disable react/jsx-props-no-spreading */
const ButtonPanel = ({ className, ...props }) => (
  <Button {...props} className={cn(styles.btnPanel, className)} />
);

export default ButtonPanel;
