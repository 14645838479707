import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { PAGE_EDITOR_ROUTE } from '../../../../constants/routes';
import { LeftSidebar } from '../../../LeftSidebar';
import RightSidebar from '../../../RightSidebar';
import styles from '../styles.module.scss';

export const AppLayout = ({ children }) => (
  <DndProvider backend={HTML5Backend}>
    <div className={styles.mainWrapper}>
      <LeftSidebar />
      <main className={styles.mainContent}>
        {children}
      </main>
      <Switch>
        <Route
          path={PAGE_EDITOR_ROUTE}
          component={RightSidebar}
        />
      </Switch>
    </div>
  </DndProvider>
);

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
