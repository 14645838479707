import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useDrag } from 'react-dnd';
import cn from 'classnames';
import { ReactComponent as Website } from '../../../../../../assets/icons/website.svg';
import { ReactComponent as Settings } from '../../../../../../assets/icons/settings.svg';
import ButtonSVG from '../../../../../../components/ButtonSVG';
import { translate } from '../../../../../../utils/translate';
import { dropTypes } from '../../../../../../utils/dropTypes';
import useAction from '../../../../../../utils/useAction';
import { selectLeftMenu, selectPage } from '../../../../../../services/actions/ui';
import { PAGES_ROUTE } from '../../../../../../constants/routes';
import { LEFT_MENU } from '../../../../../../constants/ui';
import styles from './styles.module.scss';

const SingleContent = ({
  website, openModal,
}) => {
  const { id, name } = website;

  const onSelectLeftMenu = useAction(selectLeftMenu);
  const onSelectPage = useAction(selectPage);

  let selWebId;

  const match = useRouteMatch(PAGES_ROUTE);

  if (match) {
    selWebId = match.params.webId;
  }

  const [{ isDragging }, drag] = useDrag({
    item: {
      website,
      type: dropTypes.WEBSITE,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const onClick = () => {
    onSelectLeftMenu(LEFT_MENU.PAGES);

    onSelectPage();
  };

  const opacity = isDragging ? 0.4 : 1;

  return (
    <div
      ref={drag}
      className={styles.wrap}
      style={{
        opacity,
      }}
      onClick={onClick}
    >
      <Link
        to={`/website/${id}`}
        className={(cn([styles.link, `${id}` === `${selWebId}` && styles.active]))}
      >

        <ButtonSVG className={styles.button}>
          <Website fill="" width={16} height={16} />
        </ButtonSVG>

        <span className={styles.text} title={name}>
          {name}
        </span>

      </Link>

      <ButtonSVG
        id={id}
        className={styles.button}
        onClick={openModal}
        title={translate('Edit Website')}
      >
        <Settings width={16} height={16} />
      </ButtonSVG>

    </div>
  );
};

export default SingleContent;
