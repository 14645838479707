import { combineReducers } from 'redux';
import { connectRouter as router } from 'connected-react-router';

import history from '../../utils/history';
import auth from './auth';
import websites from './websites';
import templates from './templates';
import pages from './pages';
import media from './media';
import snippets from './snippets';
import snippetsAtPage from './snippet-at-page';
import loading from './loading';
import ui from './ui';
import redirects from './redirects';

const rootReducer = combineReducers({
  router: router(history),
  // -- start connecting reducers
  auth,
  websites,
  pages,
  templates,
  snippets,
  snippetsAtPage,
  media,
  loading,
  ui,
  redirects,
  // -- end connecting reducers
});

export default rootReducer;
