import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../components/Modal/Modal';
import FormModal from './components/FormModal';

const ModalWebsite = ({
  show, onClose, defaultTab,
}) => {
  const isEdit = typeof show === 'number';

  return (
    <Modal show={Boolean(show)} onClose={onClose}>

      <FormModal
        defaultTab={defaultTab}
        show={show}
        isEdit={isEdit}
        onClose={onClose}
      />

    </Modal>
  );
};

ModalWebsite.defaultProps = {
  show: false,
  defaultTab: 0,
};

ModalWebsite.propTypes = {
  show: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  onClose: PropTypes.func.isRequired,
  defaultTab: PropTypes.number,
};

export default ModalWebsite;
