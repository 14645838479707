import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import styles from '../ModalForm/styles.module.scss';
import PageSelect
  from '../../../../../../../modules/LeftSidebar/components/PagesTab/components/PageSelect';
import Subtitle from '../../../../../../Subtitle/Subtitle';
import { translate } from '../../../../../../../utils/translate';

const Pages = () => {
  const { values: { site, page } } = useFormikContext();

  const select = useMemo(() => (
    <PageSelect
      name="page"
      placeholder={translate('Location')}
      webId={site}
      pageId={page}
    />
  ), [site, page]);

  return (
    <div className={styles.row}>

      <Subtitle className={styles.subtitle}>{translate('Page')}</Subtitle>

      {select}

    </div>
  );
};

export default Pages;
