import React, { useMemo } from 'react';
import cn from 'classnames';
import { ReactComponent as ChevronRight } from '../../../../../../../../assets/icons/chevron-right.svg';
import styles from './styles.module.scss';

const BlockPropsTitle = ({ title, isOpen }) => useMemo(() => (
  <div className={styles.container}>
    <div className={cn(styles.indicator, { [styles.open]: isOpen })}>
      <ChevronRight width={24} height={24} />
    </div>
    <span className={styles.title}>{title}</span>
  </div>
), [title, isOpen]);

export default BlockPropsTitle;
