import React from 'react';
import cn from 'classnames';
import ButtonSVG from '../../ButtonSVG';
import styles from './styles.module.scss';

const Device = ({
  id, className, children, device, ...props
}) => (
  <ButtonSVG
    id={id}
    className={cn(
      styles.device,
      className,
      { [styles.active]: id === device },
    )}
    {...props}
  >
    {children}
  </ButtonSVG>
);

export default Device;
