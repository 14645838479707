import { LEFT_MENU, UI_ACTIONS } from '../../constants/ui';
import { UI, PAGES } from '../../constants/type';

export const defaultState = {
  leftMenu: LEFT_MENU.SITES,
  selectedWebsiteId: undefined,
  selectedPageId: undefined,
  selectedSnippetId: undefined,
  selectedSnippetAtPageId: undefined,
  selectedLanguage: undefined,
};

const ui = (state = defaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UI.SELECT_LEFT_MENU.base:
    case UI_ACTIONS.SELECT_LEFT_MENU: {
      return {
        ...state,
        leftMenu: payload,
      };
    }
    case UI_ACTIONS.SELECTED_WEBSITE: {
      return {
        ...state,
        selectedWebsiteId: payload,
      };
    }
    case UI.SELECTED_PAGE.base:
    case UI_ACTIONS.SELECTED_PAGE: {
      return {
        ...state,
        selectedPageId: payload,
      };
    }
    case UI_ACTIONS.SELECTED_SNIPPET: {
      return {
        ...state,
        selectedSnippetId: payload,
      };
    }
    case UI_ACTIONS.SELECTED_SNIPPET_AT_PAGE: {
      return {
        ...state,
        selectedSnippetAtPageId: payload,
      };
    }
    case UI_ACTIONS.PARSE_PATH: {
      return {
        ...state,
        selectedWebsiteId: payload.siteId || state.selectedWebsiteId,
        selectedPageId: payload.pageId || state.selectedPageId,
        selectedSnippetId: payload.snippetId || state.selectedSnippetId,
      };
    }
    case PAGES.REMOVE.SUCCESS: {
      return {
        ...state,
        selectedPageId: undefined,
        selectedSnippetId: undefined,
      };
    }
    case PAGES.EDIT.SUCCESS: {
      return {
        ...state,
        selectedSnippetAtPageId: undefined,
      };
    }
    case UI.CHANGE_LANGUAGE.base: {
      return {
        ...state,
        selectedLanguage: payload,
      };
    }
    default:
      return state;
  }
};

export default ui;
