import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styles from './styles.module.scss';

export const SidebarPanel = ({ children, containerRef = () => {} }) => (
  <PerfectScrollbar
    className={styles.sidebarPanel}
    containerRef={containerRef}
  >
    {children}
  </PerfectScrollbar>
);
