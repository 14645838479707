import React, { /* useState, */useMemo, useCallback } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import cn from 'classnames';
import { ReactComponent as ArrowLeft } from '../../../../assets/icons/arrow-left.svg';
import ButtonSVG from '../../../../components/ButtonSVG';
import Title from '../../../../components/Title/Title';
import { sortByName } from '../../../../utils/sortFunction';
// import { splitLangs } from '../../../../utils/convLang';
import { translate } from '../../../../utils/translate';
import useSnippetAtPageProps from '../../../../hooks/useSnippetAtPageProps';
import useSnippetAtPage from '../../../../hooks/useSnippetAtPage';
// import { useWebsite } from '../../../../hooks/useWebsite';
import BlockTextarea from './components/BlockTextarea';
import LanguageList from './components/LanguageList';
import BlockNumber from './components/BlockNumber';
import BlockSelect from './components/BlockSelect';
import BlockSource from './components/BlockSource';
import BlockProps from './components/BlockProps';
import BlockImage from './components/BlockImage';
import BlockRadio from './components/BlockRadio';
import BlockRich from './components/BlockRich';
import BlockText from './components/BlockText';
import BlockLink from './components/BlockLink';
import BlockDate from './components/BlockDate';
import Preview from './components/Preview';
import styles from './styles.module.scss';
import usePageLanguage from '../../../../hooks/usePageLanguage';

const SnippetProps = ({
  websiteId, snippetAtPageId, onClose, hidden,
}) => {
  /* const { currentWebsite } = useWebsite(websiteId); */

  const snippetAtPage = useSnippetAtPage(snippetAtPageId);

  const {
    snippetProps,
    onSetSnippetProp,
    onEditSnippetProp,
  } = useSnippetAtPageProps(snippetAtPageId);

  const {
    language,
    languages,
    onChangeLanguage,
  } = usePageLanguage();

  const onChange = useCallback((data) => onSetSnippetProp({
    ...data,
    snippetAtPage: snippetAtPageId,
  }), [onSetSnippetProp, snippetAtPageId]);

  const makeField = useCallback((id, props) => {
    const { type } = props;

    let Block;
    console.log('Type', type, language);
    switch (type) {
      case 'integer':
      case 'number': Block = BlockNumber;
        break;

      case 'string': Block = BlockText;
        break;

      case 'richtext': Block = BlockRich;
        break;

      case 'image': Block = BlockImage;
        break;

      case 'radio': Block = BlockRadio;
        break;

      case 'choice': Block = BlockSelect;
        break;

      case 'url': Block = BlockLink;
        break;

      case 'date': Block = BlockDate;
        break;

      case 'video':
      case 'audio':
      case 'attach': Block = BlockSource;
        break;

      case 'text':
      default: Block = BlockTextarea;
    }

    return (
      <Block
        key={id}
        id={id}
        item={props}
        language={language}
        onChange={onChange}
        onEdit={onEditSnippetProp}
      />
    );
  }, [language, onChange, onEditSnippetProp]);

  const makeBlock = useCallback((id, fields) => {
    const nameBlock = id === '-' ? 'Main' : id;

    const fieldKeys = Object.keys(fields).sort(sortByName);

    const inputs = fieldKeys.map((key) => makeField(key, fields[key]));

    return <BlockProps key={id} title={nameBlock}>{inputs.length > 0 && inputs}</BlockProps>;
  }, [makeField]);

  const propsStructure = useMemo(() => {
    if (!snippetProps) return '';

    const keys = Object.keys(snippetProps).sort(sortByName);

    const sortedKeys = (keys.includes('-')) ? ['-', ...keys.filter((key) => key !== '-')] : keys;

    const blocks = sortedKeys.map((key) => makeBlock(key, snippetProps[key]));

    return blocks.length > 0 && blocks;
  }, [snippetProps, makeBlock]);

  return (
    <div className={cn([styles.container, hidden && styles.hidden])}>

      <div className={styles.static}>

        <div className={styles.wrap}>

          <ButtonSVG
            className={styles.button}
            title={translate('Go back')}
            onClick={onClose}
          >
            <ArrowLeft />
          </ButtonSVG>

          <Title className={styles.title}>
            {translate('Snippet properties')}
          </Title>

        </div>

        {snippetAtPage ? <Preview
          snippetAtPage={snippetAtPage}
        /> : null}

        <LanguageList
          websiteId={websiteId}
          language={language}
          languages={languages}
          onChangeLanguage={onChangeLanguage}
        />

      </div>

      <PerfectScrollbar className={styles.dynamic}>
        {propsStructure}
      </PerfectScrollbar>

    </div>
  );
};

export default SnippetProps;
