import { createSelector } from 'reselect';

export const pagesSelector = (state) => state.pages;

export const noParentSelector = createSelector(
  pagesSelector,
  (pages) => pages.noParent,
);

export const byParentSelector = createSelector(
  pagesSelector,
  (pages) => pages.byParent,
);

export const webIdSelector = (state, webId) => webId;
export const pageIdSelector = (state, webId, pageId) => pageId;

export const pagesWebsiteSelector = createSelector(
  noParentSelector,
  byParentSelector,
  webIdSelector,
  (noParent, byParent, webId) => {
    const pagesNoParent = noParent[webId] || [];
    const pagesByParent = byParent[webId] || [];

    return {
      pages: noParent[webId] || byParent[webId] ? [...pagesNoParent, ...pagesByParent] : undefined,
      loaded: typeof noParent[webId] !== 'undefined',
    };
  },
);

export const pageWebsiteSelector = createSelector(
  noParentSelector,
  byParentSelector,
  webIdSelector,
  pageIdSelector,
  (noParent, byParent, webId, pageId) => {
    const pagesNoParent = noParent[webId] || [];
    const pagesByParent = byParent[webId] || [];

    return [...pagesNoParent, ...pagesByParent].find((page) => page.id === pageId);
  },
);
