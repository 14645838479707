import React, { useState, useEffect } from 'react';
import { ErrorMessage, useField } from 'formik';
import { translate } from '../../../../utils/translate';
import Title from '../../../../components/Title/Title';
import Subtitle from '../../../../components/Subtitle/Subtitle';
import { Input } from '../../../../components/Input';
import ErrorTooltip from '../../../ModalWebsite/components/ErrorTooltip';
import LanguageList from '../../../ModalWebsite/components/LanguageList';
import Row from '../../../ModalWebsite/components/Row';
import styles from './styles.module.scss';

const Props = ({ languages }) => {
  const [field,, helpers] = useField({ name: 'languages' });

  const [defaultLanguage, setDefaultLanguage] = useState('');

  const { value: items } = field;

  useEffect(() => {
    const findDefault = items.find((item) => item[0] === '**');

    if (!defaultLanguage || (items.length && !findDefault)) {
      if (findDefault) {
        setDefaultLanguage(findDefault[1]);
      }
    }
  }, [items, defaultLanguage, helpers, setDefaultLanguage]);

  return (
    <div>

      <Title className={styles.title}>{ translate('Page SEO') }</Title>

      <Subtitle>{translate('Title')}</Subtitle>

      <Row
        first={(
          <Input
            type="text"
            name="seo_title"
            id="seo_title"
            placeholder={translate('Title')}
            styleType="2"
            disabled
          />
        )}
        second={<ErrorMessage name="seo_title" component={ErrorTooltip} />}
      />

      <Subtitle>{translate('Keywords')}</Subtitle>

      <Row
        first={(
          <Input
            type="text"
            name="seo_keywords"
            id="seo_keywords"
            placeholder={translate('Keywords')}
            styleType="2"
            disabled
          />
        )}
        second={<ErrorMessage name="seo_keywords" component={ErrorTooltip} />}
      />

      <Subtitle>{translate('Description')}</Subtitle>

      <Row
        first={(
          <Input
            type="text"
            name="seo_description"
            id="seo_description"
            placeholder={translate('Description')}
            styleType="2"
            disabled
          />
        )}
        second={<ErrorMessage name="seo_description" component={ErrorTooltip} />}
      />

      <Title className={styles.title}>{ translate('Website') }</Title>

      <Subtitle>{translate('Template origin')}</Subtitle>

      <Row
        first={(
          <Input
            type="text"
            name="website"
            id="website"
            placeholder={translate('Template origin')}
            styleType="2"
            disabled
          />
        )}
        second={<ErrorMessage name="website" component={ErrorTooltip} />}
      />

      <Subtitle>{translate('Languages')}</Subtitle>

      <LanguageList
        valueDefaultLang={defaultLanguage}
        languagesList={languages}
        items={items}
      />

    </div>
  );
};

export default Props;
