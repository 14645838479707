const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${window.parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export default formatBytes;

/* TODO: fileloader
function import_video(in_file) {
  var name = in_file.name;
  window.video_uploading = true;
  if ($('#img_item_preloader').length === 0) {
    $(".img-list").append(
      $('<div id="img_item_preloader" class="img-item img-item-upload">' +
        '<span class="checkbox"><p><span id="n_img">Вычисляем..</span></p></span>' +
        '</div>')
    );
  } else {
    $('#n_img').text('Вычисляем..');
  }
  var fr = new FileReader();
  var fr_md5 = new FileReader();
  var CHUNK_SIZE = 1024 * (1024 + 512);
  var MD5_CHUNK_SIZE = CHUNK_SIZE * 20;
  var size = 0;
  var total = 0;
  var md5_val = '';
  var md5_cacl = md5.create();
  function makeRequest(rez, size, total_size) {
    var csrftoken = $("[name=csrfmiddlewaretoken]").val();
    var headers = {
      "X-Content-Name": name,
      "X-For-YouTube": true,
      "X-Content-Range": 'bytes ' + size + '-' + (size + CHUNK_SIZE) + '/' + total_size + '',
      'Content-Type': 'application/octet-stream',
      'X-Content-MD5': md5_val,
      "X-CSRFToken": csrftoken
    };
    total += rez.byteLength;
    $.ajax({
      type: "POST",
      url: '/' + location.pathname.split('/')[1] + '/upload-youtube-video/',
      headers: headers,
      data: rez,
      processData: false,
      success: function (data) {
        if (data && data.url) {
          $('#n_img').text(name);
          $('.file-upload-name').val(md5_val);
          window.video_uploading = false;
          return;
        }
        if (data && data.length) {
          var cc = Math.floor(data.length / total_size * 10000);
          $('#n_img').text(Math.floor(cc/100) + '.' + (cc % 100) + "%");
        }
        if (data && data.reset_length) {
          total = data.reset_length;
        }
        rez = null;
        setTimeout(seek, 50);
      },
      error: function(data) {
        window.video_uploading = false;
      }
    });
  }
  fr.onload = function () {
    var rez = fr.result;
    makeRequest(fr.result, total, in_file.size);
  };
  function seek() {
    var start =  total;
    var end = total + CHUNK_SIZE < in_file.size ? total + CHUNK_SIZE : in_file.size;
    var slice = in_file.slice(start, end);
    console.log(slice);
    fr.readAsArrayBuffer(slice);
  }
  fr_md5.onload = function () {
    md5_cacl.update(fr_md5.result);
    size = size + fr_md5.result.byteLength;
    setTimeout(seek_md5, 1);
  };
  function seek_md5() {
    if (in_file.size === size) {
      md5_val = md5_cacl.hex();
      console.log(md5_val);
      setTimeout(seek, 25);
      $('#n_img').text('...%');
      return;
    }
    var start =  size;
    var end = size + MD5_CHUNK_SIZE < in_file.size ? size + MD5_CHUNK_SIZE : in_file.size;
    var slice = in_file.slice(start, end);
    fr_md5.readAsArrayBuffer(slice);
  }
  seek_md5();
} */
