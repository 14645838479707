import { SNIPPETS_AT_PAGE, PAGES } from '../../constants/type';

export const defaultState = {
  list: [],
  page: null,
  props: undefined,
};

export default (state = defaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    /* SNIPPETS AT PAGE */
    case SNIPPETS_AT_PAGE.GET_LIST.SUCCESS: {
      return {
        ...state,
        page: payload.requestData.pageId,
        list: payload.data,
      };
    }
    case SNIPPETS_AT_PAGE.SET_SNIPPET.SUCCESS: {
      const { requestData, ...rest } = payload;

      return {
        ...state,
        list: [
          ...state.list,
          rest,
        ],
      };
    }
    case SNIPPETS_AT_PAGE.GET_PROPS.SUCCESS: {
      return {
        ...state,
        props: payload,
      };
    }
    case SNIPPETS_AT_PAGE.SET_PROPS.SUCCESS: {
      const {
        group, id, lang, value, info,
      } = payload;

      return {
        ...state,
        props: {
          ...state.props,
          [group]: {
            ...state.props[group],
            [id]: {
              ...state.props[group][id],
              values: {
                ...state.props[group][id].values,
                [lang]: value,
              },
              info: {
                ...state.props[group][id].info,
                [lang]: info,
              },
              errors: {},
            },
          },
        },
      };
    }
    case SNIPPETS_AT_PAGE.SET_PROPS.FAILURE: {
      const {
        requestData: {
          group, id, language,
        },
      } = payload;

      return {
        ...state,
        props: {
          ...state.props,
          [group]: {
            ...state.props[group],
            [id]: {
              ...state.props[group][id],
              errors: {
                [language]: true,
              },
            },
          },
        },
      };
    }
    case PAGES.REMOVE.SUCCESS: {
      return {
        ...state,
        props: undefined,
      };
    }
    default:
      return state;
  }
};
