import React from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import { dropTypes } from '../../../../utils/dropTypes';
import useSnippetAtPageDND from '../../../../hooks/useSnippetAtPageDND';

const SnippetDnD = ({
  className, pageId, children,
}) => {
  const {
    onCreate,
    onEdit,
  } = useSnippetAtPageDND();

  const { SNIPPET, SNIPPET_CONTAINER, SNIPPET_AT_PAGE_CHILD } = dropTypes;

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [SNIPPET, SNIPPET_CONTAINER, SNIPPET_AT_PAGE_CHILD],
    canDrop(item) {
      switch (item.type) {
        case SNIPPET:
        case SNIPPET_CONTAINER:
          return item.snippet.public_status;

        default:
          return true;
      }
    },
    drop(dragItem, monitor) {
      const didDrop = monitor.didDrop();

      if (didDrop) return;

      if (
        dragItem.type === SNIPPET
        || dragItem.type === SNIPPET_CONTAINER
      ) {
        // console.log('CREATE', dragItem.type);
        const data = {
          parent: '',
          page: pageId,
          snippet: dragItem.snippet.id,
        };

        onCreate(data);
      }

      if (dragItem.type === SNIPPET_AT_PAGE_CHILD) {
        // console.log('TO NULL PARENT', dragItem);
        const data = {
          ...dragItem.snippetAtPage,
          parent: '',
          order: null,
        };

        onEdit(data);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const backgroundColor = (canDrop && isOver && 'rgba(0,0,0,.1)')
    || (canDrop && 'rgba(0,0,0,.2)')
    || 'transparent';

  return (
    <div
      ref={drop}
      className={className}
      style={{ backgroundColor }}
    >
      {children}
    </div>
  );
};

SnippetDnD.defaultProps = {
  className: '',
  children: '',
};

SnippetDnD.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  pageId: PropTypes.number.isRequired,
};

export default SnippetDnD;
