import React, { useMemo } from 'react';
import { useRedirects } from '../../../../hooks/useRedirects';
import styles from './styles.module.scss';
import Title from '../../../../components/Title/Title';
import { translate } from '../../../../utils/translate';
import { Button } from '../../../../components/Button';
import RedirectItem from '../RedirectItem';
import { useModal } from '../../../../hooks/useModal';
import RedirectModal from '../../../../components/CustomModals/RedirectModal';
import { useWebsite } from '../../../../hooks/useWebsite';

const Redirects = ({ webId }) => {
  const {
    redirectList,
  } = useRedirects(webId);

  const [modalRedirect, openModalRedirect, closeModalRedirect] = useModal(false);

  const { currentWebsite } = useWebsite(+webId);

  const redirectModal = useMemo(() => (
    <RedirectModal
      show={modalRedirect}
      currentWebsite={currentWebsite}
      onClose={closeModalRedirect}
    />
  ), [modalRedirect, currentWebsite, closeModalRedirect]);

  return (
    <section className={styles.container}>

      <Title className={styles.title}>{translate('Redirects')}</Title>

      <div className={styles.wrap}>

        <div className={styles.buttons}>

          <Button
            type="button"
            className={styles.button}
            onClick={openModalRedirect}
          >
            {translate('New')}
          </Button>

        </div>

      </div>

      <div className={styles.content}>

        {redirectList.length > 0 && redirectList.map((item) => (
          <RedirectItem
            key={item.id}
            item={item}
            webId={webId}
            openModalRedirectEdit={() => openModalRedirect(item.id)}
          />
        ))}

      </div>

      {redirectModal}

    </section>
  );
};

export default Redirects;
