import React, { useState, useRef, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField, ErrorMessage } from 'formik';
import Title from '../../../../components/Title/Title';
import Subtitle from '../../../../components/Subtitle/Subtitle';
import Mandatory from '../../../../components/Mandatory';
import { Button } from '../../../../components/Button';
import { SelectUnControlled } from '../../../../components/Select';
import { translate } from '../../../../utils/translate';
import Row from '../Row';
import ErrorTooltip from '../ErrorTooltip';
import styles from './styles.module.scss';
import LanguageList from '../LanguageList';

const Languages = ({ languagesList }) => {
  const [selectedOptionId, setSelectedOptionId] = useState(null);

  const selectRef = useRef(null);

  const [field,, helpers] = useField({ name: 'languages' });

  const [valueDefaultLang, helpersDefaultLang] = useState();

  const { value: items } = field;

  useEffect(() => {
    const findDefault = items.find((item) => item[0] === '**');

    if (!valueDefaultLang || (items.length && !findDefault)) {
      if (findDefault) {
        helpersDefaultLang(findDefault[1]);
      } else {
        if (items.length) {
          const newItems = [...items];

          newItems[0][0] = '**';

          helpers.setValue(newItems);
        }

        helpersDefaultLang(items?.[0]?.[1]);
      }
    }
  }, [helpers, helpersDefaultLang, items, valueDefaultLang]);

  const trItems = items.map((item) => item[1]);

  const languageOptions = useMemo(() => languagesList
    .filter((language) => !language.disabled)
    .map(({ name, iso }) => ({
      label: `${name} (${iso.toUpperCase()})`,
      value: iso.toLowerCase(),
    })), [languagesList]);

  const options = languageOptions.filter(({ value }) => !trItems.includes(value));

  const currentValue = languageOptions.find((item) => item.value === selectedOptionId);

  const onRemove = (removeIso) => {
    const currentItems = items.filter((item) => removeIso !== item[1]);

    helpers.setValue(currentItems);
  };

  const handleChange = ({ value }) => {
    if (value) setSelectedOptionId(value);
  };

  const onAdd = () => {
    if (selectedOptionId) {
      helpers.setValue([...items, [selectedOptionId, selectedOptionId]]);

      setSelectedOptionId(null);
    }
  };

  const onChecked = (lang) => {
    const newDefaultLang = lang.iso.toLowerCase();
    helpersDefaultLang(newDefaultLang);
    helpers.setValue([...items.map((el) => (newDefaultLang === el[1] ? ['**', el[1]] : [el[1], el[1]]))]);
  };

  return (
    <section className={styles.container}>

      <Title className={styles.title}>{translate('Languages')}</Title>

      <Subtitle>{translate('Choose language to add')}</Subtitle>

      <Row
        first={(
          <div className={styles.wrap}>

            <SelectUnControlled
              key={`select_key__${currentValue}`}
              ref={selectRef}
              options={options}
              placeholder={translate('Languages')}
              value={currentValue}
              onChange={handleChange}
              dark
            />

            <Button type="button" onClick={onAdd} className={styles.button} disabled={!selectedOptionId}>{translate('Add')}</Button>

          </div>
        )}
        second={(
          <ErrorMessage name="languages" component={ErrorTooltip} />
        )}
      />

      <Subtitle>
        {translate('Added languages')}
        {' '}
        <Mandatory title={translate('Mandatory')} />
      </Subtitle>

      <LanguageList
        items={items}
        valueDefaultLang={valueDefaultLang}
        languagesList={languagesList}
        onChecked={onChecked}
        onRemove={onRemove}
      />

    </section>
  );
};

Languages.propTypes = {
  languagesList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      iso: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Languages;
