import React, { useCallback, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { joinLanguages, splitLanguages } from '../../../../utils/convLang';
import { useWebsite } from '../../../../hooks/useWebsite';
import ContentModal from '../ContentModal';

const FormModal = ({
  defaultTab, show, isEdit, onClose,
}) => {
  const [tabIndex, setTabIndex] = useState(defaultTab);

  const {
    currentWebsite,
    languages: languagesList,
    domain: domainList,
    onCreate,
    onEdit,
  } = useWebsite(show);

  const domainOptions = useMemo(() => domainList.map((name) => ({
    label: `.${name}`,
    value: name,
  })), [domainList]);

  const defaultMainDomain = domainOptions.length > 0 ? domainOptions[0].value : '';

  let initValue = {
    id: null,
    name: '',
    main_domain: defaultMainDomain,
    sub_domain: '',
    website_category: '',
    use_sitemap: false,
    icon: '',
    web_clip: '',
    languages: [],
    title: '',
    seo: '',
    seo_description: '',
  };

  if (isEdit && typeof currentWebsite?.id === 'number') {
    const {
      id,
      name,
      main_domain,
      sub_domain,
      website_category,
      use_sitemap,
      languages,
      seo_title = '',
      seo_keywords = '',
      seo_description = '',
      icon,
      web_clip,
      seo_meta,
    } = currentWebsite;

    initValue = {
      id,
      name,
      main_domain: main_domain || defaultMainDomain,
      sub_domain,
      use_sitemap,
      website_category: website_category || '',
      languages: splitLanguages(languages),
      title: seo_title || '',
      seo: seo_keywords || '',
      seo_description: seo_description || '',
      icon,
      web_clip,
      seo_meta: seo_meta || '',
    };
  }

  const b64EncodeUnicode = useCallback((str) => (
    window.btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) => String.fromCharCode(`0x${p1}`)))
  ), []);

  const onSubmit = (
    {
      id, name, main_domain, sub_domain, website_category, languages, icon,
      web_clip, seo, seo_description, title, seo_meta, use_sitemap,
    },
    { setFieldError },
  ) => {
    const data = new FormData();

    data.append('id', id);
    data.append('name', name);
    data.append('main_domain', main_domain);
    data.append('sub_domain', sub_domain.toLowerCase());
    data.append('website_category', website_category || '');
    data.append('use_sitemap', use_sitemap || false);
    data.append('languages', joinLanguages(languages));
    data.append('seo_title', title || '');
    data.append('seo_keywords', seo || '');
    data.append('seo_description', seo_description || '');
    data.append('seo_meta', b64EncodeUnicode(seo_meta || ''));

    if (typeof icon === 'object') data.append('icon', icon);
    if (typeof web_clip === 'object') data.append('web_clip', web_clip);

    const action = isEdit ? onEdit : onCreate;

    action(
      data,
      {
        setFieldError,
        onSuccess: onClose,
        onError: setTabIndex,
      },
    );
  };

  const schema = Yup.object().shape({
    id: Yup.number().nullable(),
    name: Yup.string()
      .min(2)
      .required(),
    main_domain: Yup.string()
      .required(),
    sub_domain: Yup.string()
      .strict()
      .lowercase()
      .min(2)
      .matches(/^[a-z0-9-\\.]+$/gi, { message: 'For Subdomain available characters a-z, numeric, "-" and "."' }),
    website_category: Yup.mixed(),
    use_sitemap: Yup.boolean(),
    icon: Yup.lazy((valueInput) => {
      switch (typeof valueInput) {
        case 'string':
          return Yup.string().required();

        case 'object':
          return Yup.mixed()
            .test('fileType', 'Unsupported File Format',
              (value) => value && /(image\/*)/.test(value.type))
            .test('fileSize', 'Max File size', (value) => value.size <= 1000000)
            .required();
        default:
          return Yup.mixed();
      }
    }),
    web_clip: Yup.lazy((valueInput) => {
      switch (typeof valueInput) {
        case 'string':
          return Yup.string().required();

        case 'object':
          return Yup.mixed()
            .test('fileType', 'Unsupported File Format',
              (value) => value && /(image\/*)/.test(value.type))
            .test('fileSize', 'Max File size', (value) => value.size <= 1000000)
            .required();
        default:
          return Yup.mixed();
      }
    }),
    languages: Yup.array()
      .required(),
    title: Yup.string(),
    seo: Yup.string(),
    seo_description: Yup.string(),
    seo_meta: Yup.string(),
  });

  const contentModal = useMemo(() => (
    <ContentModal
      isEdit={isEdit}
      webId={show}
      domainOptions={domainOptions}
      languagesList={languagesList}
      tabIndex={tabIndex}
      setTabIndex={setTabIndex}
      onClose={onClose}
    />
  ), [isEdit, show, domainOptions, languagesList, tabIndex, setTabIndex, onClose]);

  return (
    <Formik
      initialValues={initValue}
      validationSchema={schema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <Form noValidate>
        {contentModal}
      </Form>

    </Formik>
  );
};

export default FormModal;
