import { MEDIA } from '../../constants/type';
import { merge } from '../../utils/mergeLists';
import { sortItemsByName } from '../../utils/sortFunction';

export const defaultState = {
  list: [],
  categories: [],
};

export default (state = defaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    /* MEDIA */
    case MEDIA.GET_ALL.SUCCESS: {
      return {
        ...state,
        list: payload.data.files.sort(sortItemsByName),
        categories: payload.data.dirs.sort(sortItemsByName),
      };
    }
    case MEDIA.GET_ALL_BY_PATH.SUCCESS: {
      return {
        ...state,
        list: merge(payload.data.files.sort(sortItemsByName), state.list, 'relative_path'),
        categories: merge(payload.data.dirs.sort(sortItemsByName), state.categories, 'relative_path'),
      };
    }
    /* case MEDIA.REMOVE.SUCCESS: {
      return {
        ...state,
        list: state.list
          .filter((media) => !payload.contains(media.id)),
      };
    } */
    case MEDIA.EDIT.SUCCESS: {
      const { requestData, name, ...media } = payload;

      const list = state.list.map((item) => {
        if (item.relative_path !== requestData.relative_path) return item;

        return {
          ...requestData.oldMedia,
          ...media,
          absolute_path: requestData.oldMedia.absolute_path
            .replace(requestData.oldMedia.relative_path, media.relative_path),
        };
      });

      const categories = state.categories.map((item) => {
        if (requestData.old_path && (item.relative_path === requestData.old_path)) {
          const childCategories = state.categories
            .filter((item_2) => item_2.parent === requestData.old_path)
            .length > 0;

          const childMedia = list
            .filter((item_2) => item_2.parent === requestData.old_path)
            .length > 0;

          return {
            ...item,
            has_child: childCategories || childMedia,
          };
        }

        if (requestData.path === item.relative_path) {
          return {
            ...item,
            has_child: true,
          };
        }

        return item;
      });

      return {
        ...state,
        list,
        categories,
      };
    }
    case MEDIA.CREATE_CATEGORY.SUCCESS: {
      const { requestData, ...category } = payload;

      const categories = state.categories
        .map((item) => (
          item.relative_path === requestData.path
            ? {
              ...item,
              has_child: true,
            }
            : item
        ));

      return {
        ...state,
        categories: [
          ...categories,
          {
            ...category,
            has_child: false,
          },
        ],
      };
    }
    case MEDIA.EDIT_CATEGORY.SUCCESS: {
      const { requestData, ...category } = payload;

      const categories = state.categories.map((item) => {
        if (requestData.path !== requestData.old_path) {
          if (item.relative_path === requestData.path) {
            return {
              ...item,
              has_child: true,
            };
          }

          if (item.relative_path === requestData.old_path) {
            return {
              ...item,
              has_child: state.categories
                .filter((item_2) => item_2.parent === requestData.old_path)
                .length > 1
              || state.list.filter((item_2) => item_2.parent === requestData.old_path)
                .length > 0,
            };
          }
        }

        if (item.relative_path !== requestData.relative_path) return item;

        return category;
      });

      return {
        ...state,
        categories,
      };
    }
    /* case MEDIA.REMOVE_CATEGORY.SUCCESS: {
      return {
        ...state,
        categories: state.categories
          .filter((category) => category.id !== payload.path),
      };
    } */
    default:
      return state;
  }
};
