import React from 'react';
import CustomPrompt from '../components/CustomPrompt';

export const promptOptions = ({
  title,
  message,
  buttons,
}) => ({
  title,
  message,
  buttons,
  customUI: (props) => (
    <CustomPrompt
      {...props}
    />
  ),
  closeOnClickOutside: false,
});
