import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, useFormikContext } from 'formik';
import { AssetItem } from '../AssetItem';

export const AssetCheckbox = ({
  items, name, // editClick,
}) => {
  const { values } = useFormikContext();

  const value = values[name];

  return (
    <FieldArray
      name={name}
    >
      {(arrayHelpers) => (
        items.map((item) => {
          const { relative_path } = item;

          const onChange = () => {
            const index = value && value.indexOf(relative_path);

            if (index !== -1) {
              arrayHelpers.remove(index);
            } else {
              arrayHelpers.push(relative_path);
            }
          };

          const checked = value && value.includes(relative_path);

          return (
            <AssetItem
              key={relative_path}
              name={relative_path}
              label={`${name}-${relative_path}`}
              checked={checked}
              item={item}
              handleChange={onChange}
              // editClick={editClick}
            />
          );
        })
      )}
    </FieldArray>
  );
};

AssetCheckbox.propTypes = {
  // editClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    relative_path: PropTypes.string.isRequired,
    absolute_path: PropTypes.string,
    name: PropTypes.string.isRequired,
    extension: PropTypes.string.isRequired,
    /* ratio: PropTypes.string, */
    size: PropTypes.number,
  })).isRequired,
};
