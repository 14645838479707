import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../Modal/Modal';
import ModalHeader from '../../Modal/componets/ModalHeader';
import MediaModalContent from './components/MediaModalContent';
import { translate } from '../../../utils/translate';
import styles from '../styles.module.scss';
import ModalContent from '../../Modal/componets/ModalContent';

/* eslint-disable react/jsx-props-no-spreading */
const MediaModal = (props) => {
  const { show, onClose } = props;

  const isEdit = show && typeof show === 'string';

  return useMemo(
    () => (
      <Modal
        show={Boolean(show)}
        classNameContainer={styles.modal}
        onClose={onClose}
      >

        <ModalHeader
          className={styles.header}
          title={translate(isEdit ? 'Edit folder' : 'New folder')}
          onClose={onClose}
        />

        <ModalContent
          tag="div"
          className={styles.content}
        >

          <MediaModalContent
            {...props}
            isEdit={isEdit}
          />

        </ModalContent>

      </Modal>
    ),
    [show, isEdit, onClose, props],
  );
};

MediaModal.defaultProps = {
  show: false,
};

MediaModal.propTypes = {
  show: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  onClose: PropTypes.func.isRequired,
};

export default MediaModal;
