import React from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../Input';
import { SnippetWrap } from '../SnippetWrap';

/* eslint-disable react/jsx-props-no-spreading */
export const Text = (props) => (
  <SnippetWrap>
    <Input
      {...props}
    />
  </SnippetWrap>
);
/* eslint-disable react/jsx-props-no-spreading */

Text.defaultProps = {
  label: null,
  type: 'text',
  placeholder: '',
  disabled: false,
  tooltip: null,
  maxLength: null,
  className: null,
  classNameControl: null,
};

Text.propTypes = {
  classNameControl: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
  ]),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
  maxLength: PropTypes.number,
};
