import React, { useMemo, useCallback } from 'react';
import * as Yup from 'yup';
import { confirmAlert } from 'react-confirm-alert';
import { promptOptions } from '../../../../../utils/promptOptions';
import { useRedirect } from '../../../../../hooks/useRedirect';
import RedirectForm from '../RedirectForm';

const RedirectModalContent = ({
  show, isEdit, currentWebsite, onClose,
}) => {
  const {
    currentRedirect,
    onCreate,
    onEdit,
    onRemove,
  } = useRedirect(currentWebsite.id, show);

  const initValue = useMemo(() => {
    let value = {
      id: '',
      name: '',
      source: '',
      site: '',
      page: '',
      url: '',
      enable: false,
      permanent: false,
    };

    if (isEdit && currentRedirect && currentRedirect.id) {
      const {
        id, name, source, enable, permanent, target, preview,
      } = currentRedirect;

      value = {
        id,
        name,
        source,
        enable,
        permanent,
        url: isNaN(target) ? target : '',
        site: isNaN(target) ? '' : +preview.website_id,
        page: isNaN(target) ? '' : +target,
      };
    }

    return value;
  }, [isEdit, currentRedirect]);

  const schema = Yup.object().shape({
    name: Yup.string().min(2).required(),
    source: Yup.string().min(2).required(),
    site: Yup.number().nullable(),
    page: Yup.number().nullable(),
    url: Yup.string(),
    enable: Yup.bool(),
  });

  const handleSubmit = useCallback(({
    id, name, source, enable, page, url, permanent,
  }, { setFieldError }) => {
    const data = {
      id,
      name,
      source,
      website: currentWebsite.id,
      enable,
      permanent,
    };

    if (url) {
      data.target = url;
    } else {
      data.target = `${page}`;
    }

    const action = id ? onEdit : onCreate;
    action(data, { setFieldError, onSuccess: onClose });
  }, [currentWebsite, onClose, onCreate, onEdit]);

  const handleRemove = useCallback(() => {
    confirmAlert(promptOptions({
      buttons: [
        {
          label: 'No',
        },
        {
          label: 'Yes',
          color: 'danger',
          onClick: () => onRemove({ onSuccess: onClose }),
        },
      ],
    }));
  }, [onRemove, onClose]);

  return useMemo(() => (
    <RedirectForm
      isEdit={isEdit}
      initValue={initValue}
      schema={schema}
      currentWebsite={currentWebsite}
      currentRedirect={currentRedirect}
      onRemove={handleRemove}
      onSubmit={handleSubmit}
    />
  ), [
    initValue, schema, currentWebsite, currentRedirect,
    handleSubmit, handleRemove, isEdit,
  ]);
};

export default RedirectModalContent;
