import React, {
  useCallback, useMemo, useRef, useState,
} from 'react';
import { ReactComponent as EditIcon } from '../../../../../../assets/icons/edit.svg';
import { ReactComponent as UploadIcon } from '../../../../../../assets/icons/upload.svg';
import { ReactComponent as ChevronRight } from '../../../../../../assets/icons/chevron-right.svg';
import { ReactComponent as AddDirectory } from '../../../../../../assets/icons/add-directory.svg';
// import { ReactComponent as TrashIcon } from '../../../../../../assets/icons/trash.svg';
import ButtonSVG from '../../../../../../components/ButtonSVG';
import ButtonPanel from '../../../../../../components/Button/components/ButtonPanel';
import AsyncContent from '../../../../../../components/Accordion/components/AsyncContent';
import { Accordion } from '../../../../../../components/Accordion';
import { SidebarTab } from '../../../../../../components/SidebarTabs/SidebarTab';
import { InputSearch } from '../../../../../../components/InputSearch';
import { SidebarPanel } from '../../../../../../components/SidebarTabs/SidebarPanel';
import { AccordionHeader } from '../../../../../../components/Accordion/components/Header';
import { translate } from '../../../../../../utils/translate';
import MediaDropWrap from '../MediaDropWrap';
import { AssetCheckbox } from '../AssetCheckbox';
import styles from './styles.module.scss';

const MediaFormContent = ({
  list, createLoading, categories, onOpenAccordion, openModal, openLoaderMedia,
}) => {
  const fileRef = useRef(null);

  const [search, setSearch] = useState('');

  const onChangeFile = (e) => {
    const { files } = e.currentTarget;

    openLoaderMedia(Array.from(files));
  };

  const triggerFileInput = () => fileRef.current.click();

  const handleOpen = useCallback((path) => onOpenAccordion(path), [onOpenAccordion]);

  // const handleEdit = useCallback(() => console.log('EDIT MEDIA'), []);

  const getHeader = useCallback(({
    has_child, name, relative_path,
  }) => (
    <AccordionHeader
      hasChildren={has_child}
      title={(
        <span title={name}>
          {name}
        </span>
      )}
      leftIcons={(
        <ChevronRight
          width={24}
          height={24}
          style={{ fill: has_child ? undefined : '#5E5E5D' }}
        />
      )}
      rightIcons={(
        <ButtonSVG
          className={styles.button}
          title={translate('Edit Category')}
          id={relative_path}
          onClick={openModal}
        >
          <EditIcon width={16} height={16} />
        </ButtonSVG>
      )}
    />
  ), [openModal]);

  const getContent = useCallback((items) => (
    <AssetCheckbox
      items={items}
      name="media"
      /* editClick={handleEdit} */
    />
  ), [/* handleEdit */]);

  const mediaNoCategory = useMemo(
    () => list.filter((media) => !media.parent),
    [list],
  );

  const makeItems = useCallback((array, items, parent = null) => array
    .filter((category) => category.parent === parent)
    .map((category) => {
      let content;

      const childCategories = makeItems(array, items, category.relative_path);

      const currentMedia = items.filter((item) => item.parent === category.relative_path);

      if (currentMedia.length === 0 && childCategories.length === 0 && category.has_child) {
        content = <AsyncContent />;
      } else {
        const contentItems = currentMedia.length > 0
          ? getContent(currentMedia)
          : '';

        content = childCategories.length > 0
          ? [
            { content: contentItems },
            ...childCategories,
          ]
          : contentItems;
      }

      return {
        id: category.relative_path,
        category,
        header: getHeader(category),
        content,
      };
    }), [getContent, getHeader]);

  const categoriesList = makeItems(categories, list);

  const refScroll = useMemo(() => ({}), []);

  function containerRef(ref) {
    refScroll.ref = ref;
  }

  const onHoverHeader = useCallback((item/* , dragItem */) => {
    if (item.header) {
      refScroll.ref.scrollTop -= 5;
    }
    if (item.bottom) {
      refScroll.ref.scrollTop += 5;
    }
  }, [refScroll]);

  const MediaDropWrapWithHover = useCallback(({ item, children }) => (
    <MediaDropWrap item={item} onHoverHeader={onHoverHeader}>{children}</MediaDropWrap>
  ), [onHoverHeader]);

  const structure = useMemo(() => (
    categoriesList.map((item, index) => (
      <Accordion
        withBorder={false}
        item={item}
        key={index}
        wrapper={MediaDropWrapWithHover}
        onOpen={handleOpen}
      />
    ))
  ), [categoriesList, handleOpen, MediaDropWrapWithHover]);

  return (
    <SidebarTab
      title={translate('Global assets')}
      iconsContent={(
        <ButtonSVG
          onClick={openModal}
          title={translate('Create Folder')}
        >
          <AddDirectory width={20} height={20} />
        </ButtonSVG>
      )}
      wrapper={MediaDropWrapWithHover}
      headerContent={(
        <InputSearch
          className={styles.tab_search}
          value={search}
          onChange={setSearch}
          placeholder={translate('Search media')}
        />
      )}
    >
      <SidebarPanel containerRef={containerRef}>
        {structure}
        {mediaNoCategory.length > 0 && getContent(mediaNoCategory)}
      </SidebarPanel>

      <div className={styles.tab_btnHolder}>

        <input
          ref={fileRef}
          type="file"
          name="newFile"
          onChange={onChangeFile}
          multiple
          hidden
        />

        <MediaDropWrapWithHover item={{ bottom: true, place: 'bottom' }}>

          <ButtonPanel
            title={translate('Upload Image')}
            loading={createLoading}
            onClick={triggerFileInput}
          >
            <UploadIcon />
            {translate('Upload')}
          </ButtonPanel>

        </MediaDropWrapWithHover>
        {/* <Button
          type="submit"
        >
          <TrashIcon />
          {translate('Delete')}
        </Button> */}

      </div>

    </SidebarTab>
  );
};

export default MediaFormContent;
