import { createSelector } from 'reselect';

/* TEMPLATES */
export const templatesSelector = (state) => state.templates;

export const listSelector = createSelector(
  templatesSelector,
  (templates) => templates.list,
);

export const templateIdSelector = (store, templateId) => templateId;

export const templateSelector = createSelector(
  listSelector,
  templateIdSelector,
  (templates, templateId) => templates.find(({ id }) => id === templateId),
);
