import { createSelector } from 'reselect';

export const uiSelector = (state) => state.ui;

export const leftMenuSelector = createSelector(
  uiSelector,
  (ui) => ui.leftMenu,
);

export const selectedWebsiteIdSelector = createSelector(
  uiSelector,
  (ui) => ui.selectedWebsiteId,
);

export const selectedPageIdSelector = createSelector(
  uiSelector,
  (ui) => ui.selectedPageId,
);

export const selectedSnippetIdSelector = createSelector(
  uiSelector,
  (ui) => ui.selectedSnippetId,
);

export const selectedSnippetAtPageIdSelector = createSelector(
  uiSelector,
  (ui) => ui.selectedSnippetAtPageId,
);

export const selectedLanguageSelector = createSelector(
  uiSelector,
  (ui) => ui.selectedLanguage,
);
