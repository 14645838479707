import React from 'react';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';
import { ReactComponent as Info } from '../../../../assets/icons/info.svg';
import { ReactComponent as Brackets } from '../../../../assets/icons/brackets.svg';
import { ReactComponent as SnippetPreview } from '../../../../assets/icons/snippet-preview.svg';
import ButtonSVG from '../../../ButtonSVG';
import { translate } from '../../../../utils/translate';
import { dropTypes } from '../../../../utils/dropTypes';
import { PreviewTooltip } from '../Preview';
import styles from './styles.module.scss';

export const GalleryItem = ({
  snippet, handleAddSnippet, handleEditSnippet,
}) => {
  const {
    id, name, short_name, description, public_status, is_container, icon, preview, usage_cnt, include_cnt
  } = snippet;

  const { SNIPPET_CONTAINER, SNIPPET } = dropTypes;

  const type = is_container ? SNIPPET_CONTAINER : SNIPPET;

  const [{ isDragging }, drag] = useDrag({
    item: { type, snippet },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacityType = public_status ? 1 : 0.6;

  const opacity = isDragging ? 0.4 : opacityType;

  const iconImage = icon
    ? (<img src={icon} alt="snippet" />)
    : (<SnippetPreview />);

  return (
    <div className={styles.container}>

      <div
        ref={drag}
        className={styles.item}
        style={{ opacity }}
      >

        <div className={styles.icon}>
          {iconImage}
        </div>

        <p
          className={styles.text}
          title={short_name}
        >
          {short_name}
        </p>

      </div>

      <div className={styles.helper}>

        <ButtonSVG
          id={id}
          title={translate('Edit Snippet')}
          className={styles.helper_item}
          onClick={handleEditSnippet}
        >
          <Brackets width={12} height={12} />
        </ButtonSVG>
        <span className={usage_cnt || include_cnt ? styles.texton : styles.textoff}>{usage_cnt}/{include_cnt}</span>
        <ButtonSVG
          data-tip
          data-for={`tooltip-snippet-${id}`}
          className={styles.helper_item}
        >
          <Info width={12} height={12} />
        </ButtonSVG>

      </div>

      <PreviewTooltip
        id={id}
        idFor={`tooltip-snippet-${id}`}
        title={name}
        description={description}
        previewImg={preview}
        handleAddSnippet={handleAddSnippet}
      />

    </div>
  );
};

export const GalleryItemProps = {
  handleEditSnippet: PropTypes.func.isRequired,
  handleAddSnippet: PropTypes.func,
};

GalleryItem.defaultProps = {
  handleAddSnippet: undefined,
};

GalleryItem.propTypes = GalleryItemProps;
