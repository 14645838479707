import React from 'react';
import { useFormikContext } from 'formik';
import { ReactComponent as EditIcon } from '../../../../../assets/icons/edit.svg';
import { InputUnControlled } from '../../../../Input';
import ButtonSVG from '../../../../ButtonSVG';
import { useWebsite } from '../../../../../hooks/useWebsite';
import { useModal } from '../../../../../hooks/useModal';
import { usePage } from '../../../../../hooks/usePage';
import ModalLink from '../ModalLink';
import styles from './styles.module.scss';

const InnerLink = () => {
  const { values: { site, page }, setFieldValue } = useFormikContext();

  const [modal, openModal, closeModal] = useModal(false);

  const { currentPage } = usePage(page, site);

  const { currentWebsite } = useWebsite(site);

  const onChange = (value) => {
    setFieldValue('site', value.site);
    setFieldValue('page', value.page);
    setFieldValue('url', '');
  };

  const currentValue = {
    site,
    page,
  };

  const value = currentPage?.tree && currentWebsite?.domain
    ? `${currentWebsite.domain}/${currentPage.tree}/`
    : '';

  return (
    <div className={styles.container}>

      <InputUnControlled
        id="redirect-inner"
        value={value}
        styleType={2}
        disabled
      />

      <ButtonSVG
        className={styles.button}
        onClick={openModal}
      >
        <EditIcon
          width={16}
          height={16}
        />
      </ButtonSVG>

      <ModalLink
        show={modal}
        onClose={closeModal}
        onChange={onChange}
        value={currentValue}
      />

    </div>
  );
};

export default InnerLink;
