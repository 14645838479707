import { PAGES, SNIPPETS_AT_PAGE } from '../../constants/type';
import { merge } from '../../utils/mergeLists';

export const defaultState = {
  noParent: {},
  byParent: {},
  search: [],
};

const sortItems = (a, b) => {
  try {
    if (a.is_folder && b.is_folder) {
      return 0;
    }

    if (a.is_folder || b.is_folder) {
      return -1;
    }

    const charA = a.name[0].toLowerCase();
    const charB = b.name[0].toLowerCase();

    if (charA === charB) return ((a.name > b.name) ? 1 : -1);

    return ((charA > charB) ? 1 : -1);
  } catch (error) {
    console.warn('sortItems empty filed name', error, a, b);
    return 0;
  }
};

const removeSync = (state, { selectedWebsiteId, selectedPageId }) => {
  const { noParent, byParent } = state;
  const noParentWeb = noParent[selectedWebsiteId] || [];
  const byParentWeb = byParent[selectedWebsiteId] || [];

  const changePage = (page) => (page.id === +selectedPageId ? {
    ...page,
    was_sync: false,
  } : page);

  if (selectedWebsiteId && selectedPageId) {
    return {
      ...state,
      noParent: {
        ...noParent,
        [selectedWebsiteId]: noParentWeb
          .map(changePage),
      },
      byParent: {
        ...byParent,
        [selectedWebsiteId]: byParentWeb
          .map(changePage),
      },
    };
  }

  return state;
};

export default (state = defaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    /* PAGES */
    case PAGES.GET_LIST.SUCCESS: {
      return {
        ...state,
        noParent: {
          ...state.noParent,
          [payload.requestData.id]: payload.data.sort(sortItems),
        },
      };
    }
    case PAGES.GET_LIST_SEARCH.SUCCESS: {
      return {
        ...state,
        search: payload.data.sort(sortItems),
      };
    }
    case PAGES.CLEAR_LIST_SEARCH.SUCCESS: {
      return {
        ...state,
        search: [],
      };
    }
    case PAGES.GET_CURRENT_PAGE.SUCCESS: {
      const { requestData: { webId }, ...currentPage } = payload;

      const parameter = currentPage.parent ? 'byParent' : 'noParent';

      const pagesParameter = state[parameter][webId] || [];

      const index = pagesParameter.findIndex((page) => page.id === currentPage.id);

      if (index === -1) {
        return {
          ...state,
          [parameter]: {
            ...state[parameter],
            [webId]: [
              ...pagesParameter,
              currentPage,
            ],
          },
        };
      }

      return {
        ...state,
        [parameter]: {
          ...state[parameter],
          [webId]: pagesParameter
            .map((page) => (page.id === currentPage.id ? currentPage : page)),
        },
      };
    }
    case PAGES.GET_CHILDREN.SUCCESS: {
      const oldPagesByParent = state.byParent[payload.requestData.id] || [];

      return {
        ...state,
        byParent: {
          [payload.requestData.id]: merge(payload.data, oldPagesByParent, 'id', payload.requestData.parentId),
        },
      };
    }

    case SNIPPETS_AT_PAGE.SET_SNIPPET.SUCCESS:
    case SNIPPETS_AT_PAGE.CHANGE_ORDER.SUCCESS:
    case SNIPPETS_AT_PAGE.REMOVE_SNIPPET.SUCCESS:
    case SNIPPETS_AT_PAGE.SET_PROPS.SUCCESS:
      return removeSync(state, payload.requestData.ui);

    default:
      return state;
  }
};
