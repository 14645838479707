import React, { useState } from 'react';
import { SidebarPanel } from '../../../../components/SidebarTabs/SidebarPanel';
import { InputSearch } from '../../../../components/InputSearch';
import { SidebarTab } from '../../../../components/SidebarTabs/SidebarTab';
import { translate } from '../../../../utils/translate';
import { useModal } from '../../../../hooks/useModal';
import ContentTemplates from './components/ContentTemplates';
import styles from './styles.module.scss';
import ModalTemplate from '../../../ModalTemplate';

const TemplatesTab = ({ hide }) => {
  const [search, setSearch] = useState('');
  const [modal, openModal, closeModal] = useModal(false);

  return (
    <SidebarTab
      title={translate('Templates')}
      hide={hide}
      headerContent={(
        <InputSearch
          className={styles.search}
          value={search}
          onChange={setSearch}
          placeholder={translate('Search page')}
        />
      )}
    >

      <SidebarPanel>

        <ContentTemplates
          openModal={openModal}
          search={search}
        />

      </SidebarPanel>

      <ModalTemplate
        show={modal}
        onClose={closeModal}
      />

    </SidebarTab>
  );
};

export default TemplatesTab;
