import React, { useState, useMemo } from 'react';
import Collapsible from 'react-collapsible';
import PropTypes from 'prop-types';
import BlockPropsTitle from './components/BlockPropsTitle';
import styles from './styles.module.scss';

const BlockProps = ({ title, children }) => {
  const [isOpen, setOpen] = useState(true);

  const setOpening = () => setOpen(true);

  const setClosing = () => setOpen(false);

  return useMemo(() => (
    <Collapsible
      open={isOpen}
      triggerTagName="div"
      onOpening={setOpening}
      onClosing={setClosing}
      trigger={(
        <BlockPropsTitle
          isOpen={isOpen}
          title={title}
        />
      )}
    >
      <div className={styles.container}>
        {children}
      </div>
    </Collapsible>
  ), [isOpen, title, children]);
};

BlockProps.propTypes = {
  title: PropTypes.string.isRequired,
};

export default BlockProps;
