import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import useAction from '../utils/useAction';
import {
  editorHelperValuesSelector, editorHelperActionsSelector, editorHelperDisableSelector, editorHelperTxtDisableSelector,
} from '../services/selectors/snippets';
import {
  setHelperValues, setHelperActions, setHelperDisable,
} from '../services/actions/snippets';

const selector = createSelector(
  editorHelperValuesSelector,
  editorHelperActionsSelector,
  editorHelperDisableSelector,
  editorHelperTxtDisableSelector,
  (helperValues, helperActions, helperDisable, helperDisableTxt) => ({
    helperValues,
    helperActions,
    helperDisable,
    helperDisableTxt,
  }),
);

const useSnippetHelper = () => {
  const data = useSelector(selector);

  const onSetHelperValues = useAction(setHelperValues);
  const onSetHelperActions = useAction(setHelperActions);
  const onSetHelperDisable = useAction(setHelperDisable);

  return {
    ...data,
    onSetHelperValues,
    onSetHelperActions,
    onSetHelperDisable,
  };
};

export default useSnippetHelper;
