import React from 'react';
import AsyncContent from '../../../../components/Accordion/components/AsyncContent';

const getStructurePages = ({
  pages,
  header,
  content,
}) => {
  const getPages = (pageList, parent = null) => pageList
    .filter((page) => page.parent === parent)
    .map((page) => {
      if (page.has_child || page.is_folder) {
        if (page.has_child) {
          const pageContent = getPages(pageList, page.id);

          return {
            id: page.id,
            page,
            header: header(page),
            content: pageContent.length > 0 ? pageContent : (<AsyncContent />),
          };
        }

        return {
          id: page.id,
          page,
          header: header(page),
        };
      }

      return {
        id: page.id,
        page,
        content: content(page),
      };
    });

  return getPages(pages);
};

export default getStructurePages;
