import React from 'react';
import Modal from '../../../../../../../../components/Modal/Modal';
import ModalContent from '../../../../../../../../components/Modal/componets/ModalContent';
import ModalHeader from '../../../../../../../../components/Modal/componets/ModalHeader';
import { translate } from '../../../../../../../../utils/translate';
import ModalForm from './components/ModalForm';
import styles from './styles.module.scss';

const ModalLink = ({
  show, info, onClose, onChange,
}) => {
  const isEdit = info?.mode === 'link';
  const title = `${isEdit ? 'Edit' : 'Create'} Link`;

  return (
    <Modal
      show={show}
      classNameContainer={styles.modal}
      onClose={onClose}
    >

      <ModalHeader
        className={styles.header}
        title={translate(title)}
        onClose={onClose}
      />

      <ModalContent
        className={styles.content}
        tag="div"
      >

        <ModalForm
          info={info}
          onClose={onClose}
          onChange={onChange}
        />

      </ModalContent>

    </Modal>
  );
};

export default ModalLink;
