import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { splitLanguages } from '../utils/convLang';
import useAction from '../utils/useAction';
import { changeLanguage } from '../services/actions/ui';
import { listSelector } from '../services/selectors/websites';
import {
  selectedLanguageSelector, selectedWebsiteIdSelector,
} from '../services/selectors/ui';

const selector = createSelector(
  listSelector,
  selectedWebsiteIdSelector,
  selectedLanguageSelector,
  (list, webId, language) => {
    const currentWebsite = (list || []).find(({ id }) => id === +webId);

    const languagesArray = currentWebsite?.languages
      ? splitLanguages(currentWebsite.languages)
      : [];

    const languages = languagesArray
      .map((langArr) => langArr[1]);

    const defaultLanguageArray = languagesArray
      .find((langArr) => langArr[0] === '**');

    const defaultLanguage = defaultLanguageArray ? defaultLanguageArray[1] : '';

    return {
      language,
      languages,
      defaultLanguage,
    };
  },
);

const usePageLanguage = () => {
  const data = useSelector(selector);

  const onChangeLanguage = useAction(changeLanguage);

  return {
    ...data,
    onChangeLanguage,
  };
};

export default usePageLanguage;
