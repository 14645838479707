import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { AuthLayout } from '../../modules/layouts';
import { SIGN_IN_ROUTE } from '../../constants/routes';
import SignIn from '../../modules/auth/components/SignIn';

export const AuthRoutes = () => (
  <AuthLayout>
    <Switch>
      <Route exact path={SIGN_IN_ROUTE} component={SignIn} />
      <Route path="*">
        <Redirect to={SIGN_IN_ROUTE} />
      </Route>
    </Switch>
  </AuthLayout>
);
