import React, { useState, useEffect } from 'react';
import { ReactComponent as EditIcon } from '../../../../../../../../assets/icons/edit.svg';
import { InputUnControlled } from '../../../../../../../../components/Input';
import AssetsModal from '../../../../../../../../components/CustomModals/AssetsModal';
import ButtonSVG from '../../../../../../../../components/ButtonSVG';
import { useModal } from '../../../../../../../../hooks/useModal';
import styles from './styles.module.scss';

const InnerSource = ({
  id, type, error, value, info, onChange,
}) => {
  const [innerValue, setInnerValue] = useState('');

  const [modal, openModal, closeModal] = useModal(false);

  useEffect(() => {
    const currentValue = info?.mode === 'inner' ? value : '';

    setInnerValue(currentValue);
  }, [value, info]);

  const handleChoose = (media) => {
    onChange({
      value: media.absolute_path,
      props: {
        mode: 'inner',
      },
    });
  };

  return (
    <div className={styles.container}>

      <InputUnControlled
        id={`${id}-inner`}
        value={innerValue}
        error={error}
        disabled
      />

      <ButtonSVG
        className={styles.button}
        onClick={openModal}
      >
        <EditIcon
          width={16}
          height={16}
        />
      </ButtonSVG>

      <AssetsModal
        show={modal}
        onClose={closeModal}
        onChose={handleChoose}
        filter={type}
      />

    </div>
  );
};

export default InnerSource;
