import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Loader } from '../Loader';
import styles from './styles.module.scss';

/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
export const Button = ({
  className, disabled, loading, type, style, color, children, onClick, ...props
}) => (
  <button
    type={type}
    className={cn(styles.btn, className, { [styles[`btn_${color}`]]: color }, { [styles.btn_disabled]: disabled })}
    onClick={onClick}
    disabled={disabled || loading}
    style={style}
    {...props}
  >
    {loading ? <Loader className="btn-loader" /> : children}
  </button>
);

Button.defaultProps = {
  className: null,
  type: 'button',
  disabled: false,
  loading: false,
  color: '',
  onClick: () => {},
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(['', 'danger']),
  className: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};
