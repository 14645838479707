import {
  createMedia, createBigMedia, getAll, cancelBigMedia, getAllByPath,
} from '../services/actions/media';

const useBigMedia = () => {
  const onCreate = createMedia;
  const onCreateBig = createBigMedia;
  const onCancelBig = cancelBigMedia;

  return {
    onCreate,
    onCreateBig,
    onCancelBig,
    getAll,
    getAllByPath,
  };
};

export default useBigMedia;
