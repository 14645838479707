import React from 'react';
import Modal from '../../../../Modal/Modal';
import ModalHeader from '../../../../Modal/componets/ModalHeader';
import ModalContent from '../../../../Modal/componets/ModalContent';
import { translate } from '../../../../../utils/translate';
import ModalForm from './components/ModalForm';
import styles from './styles.module.scss';

const ModalLink = ({
  show, value, onClose, onChange,
}) => {
  const isEdit = value.site && value.page;

  const title = `${isEdit ? 'Edit' : 'Create'} Link`;

  return (
    <Modal
      show={show}
      classNameContainer={styles.modal}
      onClose={onClose}
    >

      <ModalHeader
        className={styles.header}
        title={translate(title)}
        onClose={onClose}
      />

      <ModalContent
        className={styles.content}
        tag="div"
      >

        <ModalForm
          onClose={onClose}
          onChange={onChange}
          value={value}
        />

      </ModalContent>

    </Modal>
  );
};

export default ModalLink;
