import React from 'react';
import { Button } from '../Button';
import styles from './styles.module.scss';
import { translate } from '../../utils/translate';

const CustomPrompt = ({
  title, message, buttons, onClose,
}) => {
  const handleClick = (onClick) => () => {
    if (onClick) onClick();
    onClose();
  };

  return (
    <div className={styles.container}>

      <header className={styles.header}>
        <h2 className={styles.title}>{title}</h2>
      </header>

      <div className={styles.content}>
        {message}
      </div>

      <div className={styles.wrapButtons}>
        {
          buttons.length > 0 && buttons.map((button, index) => (
            <Button
              key={index}
              color={button.color}
              onClick={handleClick(button.onClick)}
            >
              {button.label}
            </Button>
          ))
        }
      </div>
    </div>
  );
};

CustomPrompt.defaultProps = {
  title: translate('Confirm to submit'),
  message: translate('Are you sure to do this?'),
  buttons: [],
};

export default CustomPrompt;
