import React from 'react';
import cn from 'classnames';
import { TabList } from 'react-tabs';
import styles from './styles.module.scss';

const ModalTabList = ({ className, children }) => (
  <TabList className={cn(styles.list, className)}>
    {children}
  </TabList>
);

export default ModalTabList;
