import { useEffect, useCallback } from 'react';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { WEBSITES } from '../constants/type';
import {
  getLanguages, getDomain, geList, createWebsite, editWebsite,
} from '../services/actions/websites';
import {
  currentWebsiteSelector, languagesSelector, domainSelector,
} from '../services/selectors/websites';
import createLoadingSelector from '../services/selectors/loading';

const selector = createSelector(
  currentWebsiteSelector,
  domainSelector,
  createLoadingSelector(WEBSITES.GET_DOMAIN.base),
  languagesSelector,
  createLoadingSelector(WEBSITES.GET_LANGUAGES.base),
  (currentWebsite, domain, domainLoader, languages, languagesLoader) => ({
    currentWebsite,
    domain,
    domainLoader,
    languages,
    languagesLoader,
  }),
);

export const useWebsite = (id) => {
  const currentSelector = useCallback((store) => selector(store, id), [id]);

  const data = useSelector(currentSelector);

  const onCreate = createWebsite;
  const onEdit = editWebsite;

  useEffect(() => {
    if (typeof id === 'number' && !data.currentWebsite) {
      geList();
    }
  }, [data.currentWebsite, id]);

  useEffect(() => {
    const { loading, loaded, error } = data.languagesLoader;

    if (!loading && !loaded && !error) {
      getLanguages();
    }
  }, [data.languages, data.languagesLoader]);

  useEffect(() => {
    const { loading, loaded, error } = data.domainLoader;

    if (!loading && !loaded && !error) {
      getDomain();
    }
  }, [data.domain, data.domainLoader]);

  return {
    ...data,
    onCreate,
    onEdit,
  };
};
