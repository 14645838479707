import React, { useRef } from 'react';
import { useFormikContext, ErrorMessage } from 'formik';
import { ReactComponent as ImageIcon } from '../../../assets/icons/image.svg';
import { ReactComponent as Upload } from '../../../assets/icons/upload.svg';
import { ReactComponent as Edit } from '../../../assets/icons/edit.svg';
import { ReactComponent as Trash } from '../../../assets/icons/trash.svg';
import Icon from '../../../modules/ModalWebsite/components/Icon';
import { SnippetWrap } from '../SnippetWrap';
import ButtonSVG from '../../ButtonSVG';
import Subtitle from '../../Subtitle/Subtitle';
import InputUpload from '../../inputUpload/inputUpload';
import AssetsModal from '../../CustomModals/AssetsModal';
import { translate } from '../../../utils/translate';
import { useModal } from '../../../hooks/useModal';
import styles from './styles.module.scss';

const Image = ({
  id, title, name, styleType, onEdit,
}) => {
  const [modalAssets, openModalAssets, closeModalAssets] = useModal(false);

  const {
    values, errors, touched, setFieldValue, setFieldTouched,
  } = useFormikContext();

  const uploadInput = useRef(null);

  const triggerUploadInput = () => {
    uploadInput.current.click();
  };

  const file = values[name];

  const error = errors[name] && touched[name];

  const handleDelete = () => setFieldValue(name, '');

  const handleChose = ({ absolute_path }) => {
    setFieldTouched(name, true);

    setFieldValue(name, absolute_path);
  };

  return (
    <SnippetWrap>

      <Subtitle>{title}</Subtitle>

      <div className={styles.imageWrap}>

        <Icon file={file} error={error} className={styles.image} />

        {!styleType && (
          <div className={styles.imageLabel}>

            <p>{translate('Upload a 64 x 64 pixel, PNG, GIF, or JPG to display in Snippet collection.')}</p>

            <span className={styles.error}><ErrorMessage name={name} /></span>

          </div>
        )}

        <InputUpload ref={uploadInput} name={name} id={id} />

        <AssetsModal
          show={modalAssets}
          onClose={closeModalAssets}
          onChose={handleChose}
          filter="image"
        />

      </div>

      <div className={styles.containerIcons}>

        <div className={styles.wrapIcons}>

          <ButtonSVG
            className={styles.buttonIcon}
            title={translate('Chose image from gallery')}
            onClick={openModalAssets}
          >
            <ImageIcon width={16} height={16} />
          </ButtonSVG>

          <ButtonSVG
            className={styles.buttonIcon}
            title={translate('Chose image from PC')}
            onClick={triggerUploadInput}
          >
            <Upload width={16} height={16} />
          </ButtonSVG>

        </div>

        <div className={styles.wrapIcons}>

          <ButtonSVG
            className={styles.buttonIcon}
            title={translate('Crop Image')}
            onClick={onEdit}
            disabled
          >
            <Edit width={16} height={16} />
          </ButtonSVG>

          <ButtonSVG
            className={styles.buttonIcon}
            title={translate('Delete Image')}
            onClick={handleDelete}
          >
            <Trash width={16} height={16} />
          </ButtonSVG>

        </div>

      </div>

    </SnippetWrap>
  );
};

export default Image;
