import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as File } from '../../../../../../assets/icons/file.svg';
import { ReactComponent as Settings } from '../../../../../../assets/icons/settings.svg';
import ButtonSVG from '../../../../../../components/ButtonSVG';
import { translate } from '../../../../../../utils/translate';
import styles from './styles.module.scss';

const TemplateItem = ({ item, onEdit }) => {
  const { id, name } = item;

  return (
    <div className={styles.wrap}>

      <div className={styles.link}>

        <ButtonSVG className={styles.button}>
          <File width={16} height={16} fill="" />
        </ButtonSVG>

        <span className={styles.text}>{name}</span>

      </div>

      <ButtonSVG
        id={id}
        className={styles.button}
        title={translate('Edit Template')}
        onClick={onEdit}
      >
        <Settings width={16} height={16} />
      </ButtonSVG>

    </div>
  );
};

TemplateItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default TemplateItem;
