import React, { useState, useEffect } from 'react';
import { InputUnControlled } from '../../../../../../../../components/Input';

const GeneralLink = ({
  id, value, info, error, onChange,
}) => {
  const [generalValue, setGeneralValue] = useState('');

  useEffect(() => {
    const currentValue = info?.mode === 'url' ? value : '';

    setGeneralValue(currentValue);
  }, [value, info]);

  const handleBlur = () => onChange({
    value: generalValue,
    props: {
      mode: 'url',
    },
  });

  const handleChange = (e) => setGeneralValue(e.target.value);

  return (
    <InputUnControlled
      id={`${id}-general`}
      value={generalValue}
      error={error}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export default GeneralLink;
