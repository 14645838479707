import { createSelector } from 'reselect';

/* REDIRECTS */
export const redirectsSelector = (state) => state.redirects;

const listSelector = createSelector(
  redirectsSelector,
  (redirects) => redirects?.redirects || [],
);

export const webIdSelector = (state, webId) => webId;

export const websiteRedirectsSelector = createSelector(
  listSelector,
  webIdSelector,
  (redirects, webId) => redirects[webId],
);

export const redirectIdSelector = (state, webId, redirectId) => redirectId;

export const redirectSelector = createSelector(
  listSelector,
  webIdSelector,
  redirectIdSelector,
  (redirects, webId, redirectId) => (redirects[webId] || [])
    .find((redirect) => redirect.id === redirectId),
);
