import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { propsSelector } from '../services/selectors/snippet-at-page';
import { setSnippetProp, getSnippetProps } from '../services/actions/snippet-at-page';

const selector = createSelector(
  propsSelector,
  (snippetProps) => ({
    snippetProps,
  }),
);

const useSnippetAtPageProps = (id) => {
  const data = useSelector(selector);

  const onSetSnippetProp = setSnippetProp;

  useEffect(() => {
    if (id || id === 0) {
      getSnippetProps(id);
    }
  }, [id]);

  return {
    ...data,
    onSetSnippetProp,
  };
};

export default useSnippetAtPageProps;
