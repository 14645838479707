import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { isFetchingSelector } from '../services/selectors/auth';
import { signIn, signOut } from '../services/actions/auth';

const selector = createSelector(
  isFetchingSelector,
  (loading) => ({
    loading,
  }),
);

export const useAuth = () => {
  const data = useSelector(selector);

  const onSignIn = signIn;
  const onSignOut = signOut;

  return {
    ...data,
    onSignIn,
    onSignOut,
  };
};
