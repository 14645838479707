import React from 'react';
import styles from './styles.module.scss';
import ButtonSVG from '../../../../../../../../components/ButtonSVG';

const Radio = ({
  name, label, value, checked, onChange,
}) => (
  <ButtonSVG className={styles.choice}>
    <input
      className={styles.input}
      id={name}
      type="radio"
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
    />
    <label htmlFor={name}>
      {label}
    </label>
  </ButtonSVG>
);

export default Radio;
