import { WEBSITES } from '../../constants/type';

export const defaultState = {
  list: [],
  files: {},
  categories: [],
  languages: [],
  currentWebsite: undefined,
  domain: [],
};

const sortItems = (a, b) => {
  try {
    const charA = a.name[0].toLowerCase();
    const charB = b.name[0].toLowerCase();

    if (charA === charB) return ((a.name > b.name) ? 1 : -1);

    return (charA > charB) ? 1 : -1;
  } catch (error) {
    console.warn('sortItems empty filed name', error, a, b);
    return 0;
  }
};

const sortItemsCategories = (a, b) => {
  try {
    const charA = a.name[0].toLowerCase();
    const charB = b.name[0].toLowerCase();

    if (charA === charB) return ((a.name > b.name) ? 1 : -1);

    return ((charA > charB) ? 1 : -1);
  } catch (error) {
    console.warn('sortItems empty filed name', error, a, b);
    return 0;
  }
};

export default (state = defaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    /* WEBSITES */
    case WEBSITES.GET_CURRENT_WEBSITE.SUCCESS: {
      return {
        ...state,
        currentWebsite: payload,
      };
    }
    case WEBSITES.CLEAR_CURRENT_WEBSITE.base: {
      return {
        ...state,
        currentWebsite: undefined,
      };
    }
    case WEBSITES.GET_FILES.SUCCESS: {
      return {
        ...state,
        files: {
          ...state.files,
          [payload.requestData.webId]: payload.data,
        },
      };
    }
    case WEBSITES.CREATE_FILE.SUCCESS: {
      const { website } = payload;
      var file = state.files[website].filter((file) => file.id === payload.id);

      return file && file.length > 0 ? {
        ...state,
        files: {
          ...state.files,
          [website]: [
            ...state.files[website].map((ws) => (ws.id === payload.id ? payload : ws)),
          ],
        },
      } :  {
        ...state,
        files: {
          ...state.files,
          [website]: [
            ...state.files[website],
            payload,
          ],
        },
      };
    }
    case WEBSITES.REMOVE_FILE.SUCCESS: {
      const { id, webId } = payload.requestData;

      return {
        ...state,
        files: {
          ...state.files,
          [webId]: state.files[webId].filter((file) => file.id !== id),
        },
      };
    }
    case WEBSITES.GET_LIST.SUCCESS: {
      return {
        ...state, list: payload.data.sort(sortItems),
      };
    }
    case WEBSITES.CREATE.SUCCESS: {
      return {
        ...state,
        list: [
          ...state.list,
          payload,
        ].sort(sortItems),
        files: [],
      };
    }
    case WEBSITES.EDIT.SUCCESS: {
      return {
        ...state,
        list: [
          ...state.list
            .map((website) => (website.id === payload.id ? payload : website)),
        ].sort(sortItems),
        currentWebsite: undefined,
        files: [],
      };
    }
    case WEBSITES.GET_CATEGORIES.SUCCESS: {
      return {
        ...state,
        categories: [
          ...payload.data.sort(sortItemsCategories),
        ],
      };
    }
    case WEBSITES.CREATE_CATEGORY.SUCCESS: {
      return {
        ...state,
        categories: [
          ...state.categories,
          payload,
        ].sort(sortItemsCategories),
      };
    }
    case WEBSITES.EDIT_CATEGORY.SUCCESS: {
      return {
        ...state,
        categories: [
          ...state.categories
            .map((category) => (category.id === payload.id
              ? payload : category
            )),
        ].sort(sortItemsCategories),
      };
    }
    case WEBSITES.REMOVE_CATEGORY.SUCCESS: {
      return {
        ...state,
        categories: [
          ...state.categories
            .filter((category) => category.id !== payload.id),
        ].sort(sortItemsCategories),
      };
    }
    case WEBSITES.GET_LANGUAGES.SUCCESS: {
      return {
        ...state,
        languages: payload,
      };
    }
    case WEBSITES.GET_DOMAIN.SUCCESS: {
      return {
        ...state,
        domain: payload,
      };
    }
    default:
      return state;
  }
};
