import React, { useMemo } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import Title from '../../../../components/Title/Title';
import Subtitle from '../../../../components/Subtitle/Subtitle';
import Select from '../../../../components/Select';
import { translate } from '../../../../utils/translate';
import { promptOptions } from '../../../../utils/promptOptions';
import useTemplates from '../../../../hooks/useTemplates';
import Row from '../../../ModalWebsite/components/Row';
import styles from './styles.module.scss';

const Templates = () => {
  const { templates } = useTemplates();

  const templateOptions = useMemo(() => templates.map(({ id, name }) => ({
    label: name,
    value: id,
  })), [templates]);

  const onChangeTemplate = (changeValue) => {
    confirmAlert(promptOptions({
      buttons: [
        {
          label: 'No',
        },
        {
          label: 'Yes',
          color: 'danger',
          onClick: () => changeValue(),
        },
      ],
    }));
  };

  return (
    <section>

      <Title className={styles.title}>{translate('Templates')}</Title>

      <Subtitle>
        {translate('Choose Template')}
      </Subtitle>

      <Row
        first={(
          <div className={styles.wrap}>

            <Select
              id="template"
              name="template"
              className={styles.select}
              placeholder={translate('Template')}
              options={templateOptions}
              onChange={onChangeTemplate}
              isClearable
              dark
            />

          </div>
        )}

      />

    </section>
  );
};

export default Templates;
