import {
  createSnippet, editSnippet, removeSnippet,
} from '../services/actions/snippet-at-page';

const useSnippetAtPageDND = () => {
  const onCreate = createSnippet;
  const onEdit = editSnippet;
  const onRemove = removeSnippet;

  return {
    onCreate,
    onEdit,
    onRemove,
  };
};

export default useSnippetAtPageDND;
