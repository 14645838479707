import React, { useState, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router';
import { createAction } from 'redux-actions';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { ReactComponent as Edit } from '../../../../assets/icons/edit.svg';
import { ReactComponent as Brackets } from '../../../../assets/icons/brackets.svg';
import { ReactComponent as ChevronRight } from '../../../../assets/icons/chevron-right.svg';
import { ReactComponent as AddDirectory } from '../../../../assets/icons/add-directory.svg';
import ButtonSVG from '../../../../components/ButtonSVG';
import ButtonPanel from '../../../../components/Button/components/ButtonPanel';
import FolderModal from '../../../../components/CustomModals/FolderModal';
import { Accordion } from '../../../../components/Accordion';
import { SidebarTab } from '../../../../components/SidebarTabs/SidebarTab';
import { InputSearch } from '../../../../components/InputSearch';
import { SidebarPanel } from '../../../../components/SidebarTabs/SidebarPanel';
import { SnippetGallery } from '../../../../components/SnippetGallery';
import { AccordionHeader } from '../../../../components/Accordion/components/Header';
import { InputUnControlled } from '../../../../components/Input';
import { filterTab } from '../../../../utils/filterCategories';
import { translate } from '../../../../utils/translate';
import { encodeURIRedirect } from '../../../../utils/encodeURIRedirect';
import { useModal } from '../../../../hooks/useModal';
import { useSnippets } from '../../../../hooks/useSnippets';
import useSnippetAtPageDND from '../../../../hooks/useSnippetAtPageDND';
import { PAGE_EDITOR_ROUTE } from '../../../../constants/routes';
import { UI_ACTIONS, LEFT_MENU } from '../../../../constants/ui';
import SnippetDropWrap from './components/SnippetDropWrap';
import styles from './styles.module.scss';

const SnippetsTab = ({ hide }) => {
  const history = useHistory();

  let pageId;

  const match = useRouteMatch(PAGE_EDITOR_ROUTE);

  if (match) {
    pageId = match.params.pageId;
  }

  const [modalFolder, openModalFolder, closeModalFolder] = useModal(false);

  const [activeTab/* , setTab */] = useState(0);

  const [search, setSearch] = useState('');

  const [unpublished, setUnpublished] = useState(true);

  const {
    snippets: snippetList,
    categories: snippetCategories,
    onCreateCategory,
    onEditCategory,
    onRemoveCategory,
  } = useSnippets();

  const {
    onCreate,
  } = useSnippetAtPageDND();

  const dispatch = useDispatch();

  // const reUrl = /\/(website|page)\/(\d+)/g;

  const linkToSniperEditor = useCallback((id) => {
    const actionSelectLeftMenu = createAction(UI_ACTIONS.SELECT_LEFT_MENU);

    dispatch(actionSelectLeftMenu(LEFT_MENU.SNIPPET_EDITOR));

    const currentId = typeof id === 'number' ? id : '';
    const { pathname } = history.location;
    let url = encodeURIRedirect(`${pathname}${history.location.search}`);
    if (url && url.indexOf('/snippet/') !== -1) {
      url = null;
    }
    history.push(`/snippet/${currentId}?${url ? 'redirect=' : ''}${url ? url : ''}`);
  }, [history, dispatch]);

  const onAdd = useCallback((id) => {
    const data = {
      parent: '',
      page: +pageId,
      snippet: id,
    };

    onCreate(data);
  }, [pageId, onCreate]);

  const currentSnippetList = unpublished
    ? snippetList
    : snippetList.filter((snippet) => snippet.public_status);

  const {
    items: filteredSnippets, categories: filteredCategories,
  } = filterTab(search, currentSnippetList, snippetCategories, 'snippet_category');

  const makeSnippet = useCallback((snippet) => ({
    pageId: +pageId,
    snippet,
    handleEditSnippet: linkToSniperEditor,
    handleAddSnippet: onAdd,
  }), [onAdd, pageId, linkToSniperEditor]);

  const nested = (categories, snippets, parent = null) => {
    const currentCategories = categories.filter((category) => category.parent === parent);

    return currentCategories.map((category) => {
      const currentSnippets = snippets
        .filter((snippet) => snippet.snippet_category === category.id)
        .map(makeSnippet);

      let content = [];

      if (currentSnippets.length > 0) {
        content = [
          {
            content: <SnippetGallery options={currentSnippets} />,
          },
        ];
      }

      content = [
        ...content,
        ...nested(categories, snippets, category.id),
      ];

      const has_child = content.length > 0;

      return {
        category,
        header: <AccordionHeader
          hasChildren={has_child}
          title={(
            <span title={category.name}>
              {category.name}
            </span>
          )}
          leftIcons={(
            <ChevronRight width={24} height={24} style={{ fill: has_child ? undefined : '#5E5E5D' }} />
          )}
          rightIcons={(
            <ButtonSVG
              id={category.id}
              title={translate('Edit Category')}
              onClick={openModalFolder}
              className={styles.buttonSVG}
            >
              <Edit width={16} height={16} />
            </ButtonSVG>
          )}
        />,
        content: has_child ? content : null,
      };
    });
  };

  const sideBarOptions = nested(filteredCategories, filteredSnippets);

  const getContent = useMemo(() => {
    if (activeTab === 0) {
      const snippetsNoParent = filteredSnippets
        .filter((snippet) => !snippet.snippet_category)
        .map(makeSnippet);

      return (
        <SidebarPanel>
          {sideBarOptions.map((item, index) => (
            <Accordion
              // noGap
              withBorder={false}
              item={item}
              key={index}
              wrapper={SnippetDropWrap}
            />
          ))}
          {snippetsNoParent.length > 0 && <SnippetGallery options={snippetsNoParent} />}
        </SidebarPanel>
      );
    }
    return (<div className={styles.tab_panel}>PANEL 2</div>);
  }, [sideBarOptions, activeTab, filteredSnippets, makeSnippet]);

  const handleChangeUnpublished = () => setUnpublished((valueUnpublished) => !valueUnpublished);

  return (
    <SidebarTab
      title={translate('Snippet collection')}
      hide={hide}
      iconsContent={(
        <ButtonSVG
          onClick={openModalFolder}
          title={translate('Create Folder')}
        >
          <AddDirectory width={20} height={20} />
        </ButtonSVG>
      )}
      headerContent={(
        <>
          {/* <div className={styles.switcher}>
            <span
              onClick={() => setTab(0)}
              className={cn(styles.switcher_item, { [styles.active]: activeTab === 0 })}
            >
              {translate('Snippets')}
            </span>
            <span
              onClick={() => setTab(1)}
              className={cn(styles.switcher_item, { [styles.active]: activeTab === 1 })}
            >
              {translate('Primitives')}
            </span>
          </div> */}
          <InputSearch
            className={styles.tab_search}
            value={search}
            onChange={setSearch}
            placeholder={translate('Search snippet')}
          />
        </>
      )}
    >

      <FolderModal
        show={modalFolder}
        directories={snippetCategories}
        onClose={closeModalFolder}
        onEdit={onEditCategory}
        onCreate={onCreateCategory}
        onRemove={onRemoveCategory}
      />

      <InputUnControlled
        className={styles.unpublished}
        type="checkbox"
        label={translate('Show unpublished')}
        onChange={handleChangeUnpublished}
        checked={unpublished}
        styleType={3}
        invert
      />

      {getContent}

      <ButtonPanel
        onClick={linkToSniperEditor}
        title={translate('Create Snippet')}
      >

        <Brackets width={24} height={24} />

        {translate('Create a new Snippet')}

      </ButtonPanel>

    </SidebarTab>
  );
};

export default SnippetsTab;
