import { TEMPLATES } from '../../constants/type';

export const defaultState = {
  list: [
    // {
    //   id: 1,
    //   name: 'Test Template',
    //   description: 'Description Test Template',
    //   seo_title: 'seo_title',
    //   seo_keywords: 'seo_keywords',
    //   seo_description: 'seo_description',
    //   website: 'website',
    //   languages: '**=en,no',
    // },
  ],
};

export default (state = defaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    /* TEMPLATES */
    case TEMPLATES.GET_LIST.SUCCESS: {
      return {
        ...state,
        list: payload.data,
      };
    }
    case TEMPLATES.CREATE.SUCCESS: {
      return {
        ...state,
        list: [
          ...state.list,
          payload,
        ],
      };
    }
    case TEMPLATES.EDIT.SUCCESS: {
      return {
        ...state,
        list: state.list
          .map((item) => (item.id === payload.id ? payload : item)),
      };
    }
    case TEMPLATES.REMOVE.SUCCESS: {
      return {
        ...state,
        list: state.list.filter(({ id }) => id !== payload.id),
      };
    }

    default:
      return state;
  }
};
