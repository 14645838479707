export const splitLanguages = (languagesStr) => languagesStr.split(',')
  .map((lngRec) => {
    const rec = lngRec.split('=');

    return [rec[0], rec.length === 2 ? rec[1] : rec[0]];
  });

export const splitLangs = (languagesStr) => languagesStr.split(',')
  .map((lngRec) => {
    const langISO = lngRec.replace('**=', '');
    return [langISO, langISO];
  });

export const joinLanguages = (langsArr) => {
  const langs = langsArr.map((item) => (item[0] === '**' ? item.join('=') : item[0])).join(',');

  if (langs.length === 0) {
    return '**';
  }

  return langs.indexOf('**') !== -1 ? langs : `**=${langs}`;
};
