import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import styles from './styles.module.scss';

export const InputSearch = (props) => {
  const {
    value,
    className,
    placeholder,
    onKeyDown,
    onChange,
  } = props;

  const handleChange = (e) => {
    onChange(e.currentTarget.value);
  };

  return (
    <div className={cn(styles.searchInput_wrap, className)}>

      <span className={styles.searchInput_searchBtn}>
        <SearchIcon width={18} height={18} />
      </span>

      <input
        className={styles.searchInput_input}
        type="text"
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        onChange={handleChange}
        value={value}
      />

    </div>
  );
};

InputSearch.defaultProps = {
  value: '',
  className: '',
  placeholder: '',
  onKeyDown: () => {},
};

InputSearch.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onKeyDown: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};
