import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import createLoadingSelector from '../services/selectors/loading';
import {
  listSelector, categoriesSelector,
} from '../services/selectors/media';
import {
  editMedia, removeMedia, createCategory, editCategory, removeCategory,
  getAllByPath, getAll,
} from '../services/actions/media';
import { MEDIA } from '../constants/type';

const selector = createSelector(
  listSelector,
  categoriesSelector,
  createLoadingSelector(MEDIA.GET_ALL.base),
  createLoadingSelector(MEDIA.CREATE.base),
  (media, categories, loader, createMediaLoader) => ({
    media,
    categories,
    loader,
    createMediaLoader,
  }),
);

export const useMediaTab = () => {
  const data = useSelector(selector);

  const onEdit = editMedia;
  const onRemove = removeMedia;
  const onCreateCategory = createCategory;
  const onEditCategory = editCategory;
  const onRemoveCategory = removeCategory;
  const onOpenAccordion = getAllByPath;

  useEffect(() => {
    const { loading, loaded, error } = data.loader;

    if (!loading && !loaded && !error) getAll();
  }, [data.loader]);

  return {
    ...data,
    onEdit,
    onRemove,
    onCreateCategory,
    onEditCategory,
    onRemoveCategory,
    onOpenAccordion,
  };
};
