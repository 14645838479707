import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { ReactComponent as RadioChecked } from '../../../../assets/icons/radio-checked.svg';
import { ReactComponent as RadioUnchecked } from '../../../../assets/icons/radio-unchecked.svg';
import { translate } from '../../../../utils/translate';
import LangItem from '../LangItem';
import styles from './styles.module.scss';

const LanguageList = ({
  valueDefaultLang, items, languagesList, onRemove, onChecked,
}) => (
  <div className={styles.container}>

    <div className={cn(styles.header)}>

      <div className={cn(styles.checkbox)}>
        {translate('Default')}
      </div>

      <div className={styles.language}>
        {translate('Language name')}
      </div>

    </div>

    { items.length > 0 && items.map((value, idx) => {
      const language = languagesList.find((item) => item.iso.toLowerCase() === value[1]);

      const handleChecked = () => {
        if (onChecked) onChecked(language);
      };

      return language
        ? (
          <div
            key={language.id}
            className={styles.row}
          >

            <div
              className={styles.checkbox}
              onClick={handleChecked}
            >
              {
                value[1] === valueDefaultLang
                  ? <RadioChecked with={16} height={16} />
                  : <RadioUnchecked with={16} height={16} />
              }
            </div>

            <div className={styles.language}>

              <LangItem
                key={value[0]}
                language={language}
                onRemove={onRemove}
                checked={!idx}
              />

            </div>

          </div>
        )
        : null;
    })}

  </div>
);

LanguageList.defaultProps = {
  valueDefaultLang: '',
  onRemove: undefined,
  onChecked: undefined,
};

LanguageList.propTypes = {
  valueDefaultLang: PropTypes.string,
  onRemove: PropTypes.func,
  onChecked: PropTypes.func,
};

export default LanguageList;
