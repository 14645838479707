import React from 'react';
import { ErrorMessage } from 'formik';
import Title from '../../../../components/Title/Title';
import Subtitle from '../../../../components/Subtitle/Subtitle';
import Mandatory from '../../../../components/Mandatory';
import { Input } from '../../../../components/Input';
import Row from '../../../ModalWebsite/components/Row';
import ErrorTooltip from '../../../ModalWebsite/components/ErrorTooltip';
import { translate } from '../../../../utils/translate';
import styles from './styles.module.scss';

const General = () => (
  <>

    <Title className={styles.title}>{translate('General')}</Title>

    <Subtitle>
      { translate('Name') }
      { ' ' }
      <Mandatory title={translate('Mandatory')} />
    </Subtitle>

    <Row
      first={(
        <Input
          type="text"
          name="name"
          id="name"
          placeholder={translate('Name')}
          styleType="2"
        />
      )}
      second={<ErrorMessage name="name" component={ErrorTooltip} />}
    />

    <Subtitle>
      {translate('Description')}
    </Subtitle>

    <Row
      first={(
        <Input
          type="textarea"
          name="description"
          id="description"
          placeholder={translate('Description')}
          styleType="2"
        />
      )}
      second={<ErrorMessage name="seo" component={ErrorTooltip} />}
    />

  </>
);

export default General;
