import React, {
  useRef, useMemo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { useModal } from '../../../../../../hooks/useModal';
import PageSelectModal from './components/PageSelectModal';
import PageSelectView from './components/PageSelectView';
import styles from './styles.module.scss';

const PageSelect = ({
  name, placeholder, webId, pageId, filterChild,
}) => {
  const ref = useRef();

  const [field, , helpers] = useField(name);

  const [open, onOpen, onClose] = useModal(false);

  const onClear = useCallback(() => helpers.setValue(''), [helpers]);

  const modal = useMemo(() => {
    const onSelect = ({ id }) => {
      if (pageId && pageId === id) return;

      helpers.setValue(id);

      onClose();
    };

    return (
      open && (
        <PageSelectModal
          divRef={ref}
          value={field.value}
          pageId={pageId}
          webId={webId}
          filterChild={filterChild}
          onSelect={onSelect}
          onClose={onClose}
        />
      )
    );
  },
  [open, webId, helpers, field.value, pageId, filterChild, onClose]);

  const view = useMemo(() => (
    <PageSelectView
      placeholder={placeholder}
      webId={webId}
      field={field}
      onOpen={onOpen}
      onClear={onClear}
    />
  ), [placeholder, webId, field, onClear, onOpen]);

  return (
    <div ref={ref} className={styles.container}>

      {view}

      {modal}

    </div>
  );
};

PageSelect.defaultProps = {
  placeholder: '',
  pageId: undefined,
  filterChild: false,
};

PageSelect.propTypes = {
  placeholder: PropTypes.string,
  filterChild: PropTypes.bool,
  name: PropTypes.string.isRequired,
  webId: PropTypes.number.isRequired,
  pageId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

export default PageSelect;
