import React, { useMemo } from 'react';
import { Link, /* NavLink, */ useHistory } from 'react-router-dom';
// import { Switch, Route, useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';
import { createAction } from 'redux-actions';
import { LEFT_MENU, UI_ACTIONS, URL_QUERY_PARAMS } from '../../constants/ui';
import {
  HOME_ROUTE, /* PAGES_ROUTE, PAGE_EDITOR_ROUTE, SNIPPET_CREATE_ROUTE,
  MEDIA_ROUTE, SNIPPET_EDITOR_ROUTE, SNIPPETS_ROUTE, PAGES_NO_WEBSITE_ROUTE, */
} from '../../constants/routes';
import { ReactComponent as File } from '../../assets/icons/file.svg';
import { ReactComponent as PageIcon } from '../../assets/icons/page-2.svg';
import { ReactComponent as LogoIcon } from '../../assets/icons/logo.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import { ReactComponent as WorldIcon } from '../../assets/icons/world.svg';
import { ReactComponent as ImagesIcon } from '../../assets/icons/images.svg';
import { ReactComponent as SnippetEditorIcon } from '../../assets/icons/snippet-editor.svg';
import { ReactComponent as SnippetCollectionIcon } from '../../assets/icons/snippet-collection.svg';
import NavLinks from '../../components/SidebarTabs/NavTabs/NavLinks';
import ButtonSVG from '../../components/ButtonSVG';
import PagesTab from './components/PagesTab';
import { MediaTab } from './components/MediaTab';
import { SiteSelect } from './components/SiteSelect';
import { WebsitesTab } from './components/WebsitesTab';
import SnippetsTab from './components/SnippetsTab';
import TemplatesTab from './components/TemplatesTab';
// import PagesNoWebsiteTab from './components/PagesNoWebsiteTab';
import SnippetEditorTab from './components/SnippetEditorTab';
import { translate } from '../../utils/translate';
import { useAuth } from '../../hooks/useAuth';
import {
  leftMenuSelector, selectedWebsiteIdSelector, selectedPageIdSelector,
  selectedSnippetIdSelector,
} from '../../services/selectors/ui';
import styles from './styles.module.scss';

/* eslint-disable react/jsx-props-no-spreading */

const selector = createSelector(
  leftMenuSelector,
  selectedWebsiteIdSelector,
  selectedPageIdSelector,
  selectedSnippetIdSelector,
  (leftMenu, selectedWebsiteId, selectedPageId, selectedSnippetId) => ({
    leftMenu,
    selectedWebsiteId,
    selectedPageId,
    selectedSnippetId,
  }),
);

const Button = ({
  id, children, className, activeClassName, title,
}) => {
  const history = useHistory();

  const {
    leftMenu,
    selectedWebsiteId,
    selectedPageId,
    selectedSnippetId,
  } = useSelector(selector);

  let url = '';
  if (selectedWebsiteId) {
    url += `/website/${selectedWebsiteId}`;
    if (selectedPageId) {
      url += `/page/${selectedPageId}`;
    }
  }
  const actionSelectLeftMenu = createAction(UI_ACTIONS.SELECT_LEFT_MENU);
  const dispatch = useDispatch();
  return (
    <div
      className={cn(className, id === leftMenu && activeClassName)}
      title={title}
      onClick={() => {
        if (id === LEFT_MENU.SNIPPET_EDITOR) {
          if (selectedSnippetId) {
            history.push(`/snippet/${selectedSnippetId}?redirect=${url ? `${url}` : '/'}`);
          }
          return;
        }
        const uiParam = `?${URL_QUERY_PARAMS.LEFT_BAR}=${id.toLowerCase()}`;
        history.push(`${url}${uiParam}`);
        dispatch(actionSelectLeftMenu(id));
      }}
    >
      {children}
    </div>
  );
};

const navigationLinks = [
  {
    id: LEFT_MENU.SITES,
    title: translate('Websites'),
    icon: (props) => <Button {...props}><WorldIcon /></Button>,
    content: WebsitesTab,
  },
  {
    id: LEFT_MENU.PAGES,
    title: translate('Pages'),
    icon: (props) => <Button {...props}><PageIcon /></Button>,
    content: PagesTab,
  },
  {
    id: LEFT_MENU.TEMPLATES,
    title: translate('Templates'),
    icon: (props) => <Button {...props}><File width={26} height={26} /></Button>,
    content: TemplatesTab,
  },
  {
    id: LEFT_MENU.SNIPPETS,
    title: translate('Snippet Collection'),
    icon: (props) => <Button {...props}><SnippetCollectionIcon /></Button>,
    content: SnippetsTab,
  },
  {
    id: LEFT_MENU.SNIPPET_EDITOR,
    title: translate('Snippet Editor'),
    icon: (props) => <Button {...props}><SnippetEditorIcon /></Button>,
    content: SnippetEditorTab,
  },
  {
    id: LEFT_MENU.ASSETS,
    title: translate('Assets'),
    icon: (props) => <Button {...props}><ImagesIcon /></Button>,
    content: MediaTab,
  },
];

export const LeftSidebar = () => {
  const { onSignOut } = useAuth();

  const items = useMemo(() => [
    navigationLinks,
    [
      {
        id: 'account',
        title: translate('Sign Out'),
        icon: ({ activeClassName, ...props }) => (
          <ButtonSVG {...props} onClick={onSignOut}><UserIcon /></ButtonSVG>
        ),
      },
    ],
  ], [onSignOut]);

  const leftMenu = useSelector(leftMenuSelector);
  return (
    <div className={styles.sidebar_wrap}>

      <header className={styles.sidebar_header}>

        <Link to={HOME_ROUTE} className={styles.sidebar_logo} title={translate('Home')}>
          <LogoIcon />
        </Link>

        <SiteSelect />

      </header>

      <NavLinks links={items}>
        {navigationLinks.map((item) => {
          const Content = item.content;
          return leftMenu === item.id ? <Content key={item.id} /> : null;
        })}
      </NavLinks>
    </div>
  );
};
