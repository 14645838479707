import { toast } from 'react-toastify';
// eslint-disable no-debugger
export const wrapSimple = async (request) => {
  try {
    return await request();
  } catch (e) {
    if (e.non_field_errors) {
      e.non_field_errors.map((message) => toast.error(message));
    }
    debugger;
    console.log('e', e);
  }
};

export const wrapGetPage = async (request, actions) => {
  try {
    return await request();
  } catch (e) {
    if (e.non_field_errors) {
      e.non_field_errors.map((message) => toast.error(message));
    } else if (e.detail && actions?.onError) {
      actions.onError(e.detail);
    }
    debugger;
    console.log('e', e);
  }
};

export const wrapWebsite = async (request, actions) => {
  try {
    await request();
    if (actions?.onSuccess) actions.onSuccess();
  } catch (e) {
    if (e.non_field_errors) {
      e.non_field_errors.map((message) => toast.error(message));
    } else if (actions?.setFieldError) {
      Object.keys(e).map((key) => actions.setFieldError(key, e[key]));
    } else {
      debugger;
      console.log('e', e);
    }
  }
};

export const wrapCategory = async (request, actions) => {
  try {
    await request();

    if (actions?.onSuccess) actions.onSuccess();
  } catch (e) {
    if (e.non_field_errors) {
      e.non_field_errors.map((message) => toast.error(message));
    } else if (actions?.setFieldError) {
      Object.keys(e).map((key) => actions.setFieldError(key, e[key]));
    } else {
      debugger;
      console.log('e', e);
    }
  }
};

export const wrapPage = async (request, actions) => {
  try {
    await request();

    if (actions?.onSuccess) actions.onSuccess();
  } catch (e) {
    if (e.non_field_errors) {
      e.non_field_errors.map((message) => toast.error(message));
    } else if (actions?.setFieldError) {
      Object.keys(e).map((key) => actions.setFieldError(key, e[key]));
    } else {
      debugger;
      console.log('e', e);
    }
  }
};

export const wrapSnippet = async (request, actions) => {
  try {
    await request();

    if (actions?.onSuccess) actions.onSuccess();
    if (actions?.handleReset) actions.handleReset();
  } catch (e) {
    if (e.non_field_errors) {
      e.non_field_errors.map((message) => toast.error(message));
    } else if (actions?.setFieldError) {
      Object.keys(e).map((key) => actions.setFieldError(key, e[key][0]));
    } else {
      debugger;
      console.log('e', e);
    }
  }
};

export const wrapSnippetPage = async (request, actions) => {
  try {
    await request();

    if (actions?.onSuccess) actions.onSuccess();
  } catch (e) {
    if (e.non_field_errors) {
      e.non_field_errors.map((message) => toast.error(message));
    }
    debugger;
    console.log('e', e);
  }
};

export const wrapTemplate = async (request, actions) => {
  try {
    await request();

    if (actions?.onSuccess) actions.onSuccess();
  } catch (e) {
    if (e.non_field_errors) {
      e.non_field_errors.map((message) => toast.error(message));
    }
    debugger;
    console.log('e', e);
  }
};

export const wrapRedirect = async (request, actions) => {
  try {
    await request();

    if (actions?.onSuccess) actions.onSuccess();
  } catch (e) {
    if (e.non_field_errors) {
      e.non_field_errors.map((message) => toast.error(message));
    } else if (actions?.setFieldError) {
      Object.keys(e).map((key) => actions.setFieldError(key, e[key]));
    } else {
      debugger;
      console.log('e', e);
    }
  }
};
