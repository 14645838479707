import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { clearListSearch, getListSearch } from '../../../../../../services/actions/pages';
import { Accordion } from '../../../../../../components/Accordion';
import { usePagesTab } from '../../../../../../hooks/usePagesTab';
import PageItemCategory from '../PageItemCategory';
import PageItem from '../../../../../ModalPage/components/PageItem';
import getStructurePages from '../../getStructurePages';
import PageDropWrap from '../PageDropWrap';
import styles from './styles.module.scss';

const PageList = ({
  websiteId, openModal, openModalFolder, openModalRedirect,
  search, isByContent,
}) => {
  const {
    pages,
    searchList,
    onEdit,
    onOpenAccordion,
  } = usePagesTab();

  const timerRef = useRef(null);

  const [searchLoader, setSearchLoader] = useState(false);
  const onSuccessSearch = useCallback(() => { setSearchLoader(false); }, []);
  const onFailureSearch = useCallback(() => { setSearchLoader(false); }, []);

  useEffect(() => {
    clearTimeout(timerRef.current);

    if (search) {
      setSearchLoader(true);
      timerRef.current = setTimeout(() => {
        getListSearch(websiteId, search, isByContent, onSuccessSearch, onFailureSearch);
      }, 300);
    } else {
      clearListSearch();
    }
  }, [isByContent, search, websiteId, onSuccessSearch, onFailureSearch]);

  const handleOpen = useCallback((pageId) => onOpenAccordion(websiteId, pageId),
    [websiteId, onOpenAccordion]);

  const header = useCallback((page) => (
    <PageItemCategory
      page={page}
      openModalPage={openModal}
      openModalFolder={openModalFolder}
      openModalRedirectEdit={openModalRedirect}
    />
  ), [openModal, openModalFolder, openModalRedirect]);

  const content = useCallback((page) => (
    <PageItem
      page={page}
      onEdit={onEdit}
      onOpenModalEdit={openModal}
      openModalRedirectEdit={openModalRedirect}
    />
  ), [onEdit, openModal, openModalRedirect]);

  const searchListContent = useMemo(() => {
    if (!search) { return null; }

    if (searchLoader) {
      return (
        <div className={styles.loading}>
          <span data-text="L">L</span>
          <span data-text="O">O</span>
          <span data-text="A">A</span>
          <span data-text="D">D</span>
          <span data-text="I">I</span>
          <span data-text="N">N</span>
          <span data-text="G">G</span>
        </div>
      );
    }

    return searchList.map((page) => (!page?.is_folder ? (
      <PageItem
        key={page.id}
        page={page}
        onEdit={onEdit}
        onOpenModalEdit={openModal}
        openModalRedirectEdit={openModalRedirect}
      />
    ) : null));
  }, [onEdit, openModal, openModalRedirect, searchList, searchLoader, search]);

  return useMemo(() => {
    const structurePages = getStructurePages({
      pages,
      header,
      content,
    });

    return (
      <>
        <div style={{ display: searchListContent ? 'none' : 'block' }}>
          {structurePages.length > 0 && structurePages.map((item) => (
            <Accordion
              key={item.id}
              item={item}
              onOpen={handleOpen}
              wrapper={PageDropWrap}
            />
          ))}
        </div>

        {searchListContent}
      </>
    );
  }, [header, content, pages, handleOpen, searchListContent]);
};

export default PageList;
