import React from 'react';
import { useHistory } from 'react-router';
import { encodeURIRedirect } from 'utils/encodeURIRedirect';
import { ReactComponent as Brackets } from '../../../../../../assets/icons/brackets.svg';
import { ReactComponent as Info } from '../../../../../../assets/icons/info.svg';
import { ReactComponent as SnippetIcon } from '../../../../../../assets/icons/snippet-icon.svg';
import { PreviewTooltip } from '../../../../../../components/SnippetGallery/components/Preview';
import ButtonSVG from '../../../../../../components/ButtonSVG';
import { translate } from '../../../../../../utils/translate';
import useSnippet from '../../../../../../hooks/useSnippet';
import styles from './styles.module.scss';

const Preview = ({ snippetAtPage }) => {
  console.log(snippetAtPage);
  const { snippet } = snippetAtPage || {};
  const history = useHistory();
  const {
    snippet: {
      id, name, description, icon, preview,
    },
  } = useSnippet(snippet);

  const { pathname, search } = history.location;

  const handleEdit = () => {
    let url = encodeURIRedirect(`${pathname}${search}`);
    if (url && url.indexOf('/snippet/') !== -1) {
      url = null;
    }
    history.push(`/snippet/${id}?${url ? 'redirect=' : ''}${url ? url : ''}`);
  }

  const iconImage = icon
    ? <img src={icon} alt="logo snippet" />
    : <SnippetIcon width={64} height={64} />;

  return (
    <section className={styles.preview}>

      <figure className={styles.previewIcon}>{iconImage}</figure>

      <div className={styles.previewWrap}>

        <h2 className={styles.previewName}>{name}</h2>

        <div className={styles.previewButtons}>

          <ButtonSVG
            className={styles.previewButton}
            title={translate('Edit Snippet')}
            onClick={handleEdit}
          >
            <Brackets />
          </ButtonSVG>

          <ButtonSVG
            className={styles.previewButton}
            data-tip
            data-for={`tooltip-page-snippet-${id}`}
          >
            <Info />
          </ButtonSVG>

        </div>

        {
          id && (
            <PreviewTooltip
              id={id}
              idFor={`tooltip-page-snippet-${id}`}
              place="bottom"
              title={name}
              description={description}
              previewImg={preview}
            />
          )
        }

      </div>

    </section>
  );
};

export default Preview;
