import React from 'react';
import cn from 'classnames';
import { ReactComponent as Settings } from '../../../../../../assets/icons/settings.svg';
import ModalWebsite from '../../../../../ModalWebsite';
import { SnippetWrap } from '../../../../../../components/SnippetProperties/SnippetWrap';
import ButtonSVG from '../../../../../../components/ButtonSVG';
import { translate } from '../../../../../../utils/translate';
import { useModal } from '../../../../../../hooks/useModal';
import styles from './styles.module.scss';

const LanguageList = ({
  websiteId, languages, language, onChangeLanguage,
}) => {
  const [modalWebsite, openModalWebsite, closeModalWebsite] = useModal(false);

  return (
    <SnippetWrap className={styles.container}>

      <ModalWebsite
        show={modalWebsite}
        onClose={closeModalWebsite}
        defaultTab={1}
      />

      <div className={styles.languages}>
        {translate('View language')}

        <ButtonSVG
          id={websiteId}
          title={translate('Edit Language List')}
          className={styles.languagesConfigure}
          onClick={openModalWebsite}
        >
          <Settings />
        </ButtonSVG>

      </div>

      <div className={styles.languagesIcons}>

        { languages.length > 0 && language && languages.map((lang) => (
          <ButtonSVG
            key={lang}
            id={lang}
            onClick={onChangeLanguage}
            className={cn(
              styles.languagesIcon,
              { [styles.active]: lang === language },
            )}
          >
            {lang}
          </ButtonSVG>
        ))}

      </div>

    </SnippetWrap>
  );
};

export default LanguageList;
