import { usePage } from '../../../../../../hooks/usePage';
import { translate } from '../../../../../../utils/translate';

const SiteTitle = ({ websites, websiteId, pageId }) => {
  const wId = +websiteId || null;
  const pId = +pageId || null;

  const { currentPage } = usePage(pId, wId);

  if (!wId) {
    return translate('Choose website');
  }

  const webName = websites.find(({ id }) => id === wId)?.name || '';

  const pageName = currentPage?.name ? `/${currentPage?.name}` : '';

  return `${webName}${pageName}`;
};

export default SiteTitle;
