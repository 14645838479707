import React from 'react';
import cn from 'classnames';
import ButtonSVG from '../../../../../../../../components/ButtonSVG';
import { ReactComponent as Page } from '../../../../../../../../assets/icons/page.svg';
import { AccordionHeader } from '../../../../../../../../components/Accordion/components/Header';
import { ReactComponent as ChevronRight } from '../../../../../../../../assets/icons/chevron-right.svg';
import styles from './styles.module.scss';

const PageSelectItem = ({
  isContent, value, page, onSelect,
}) => {
  const {
    id, name, is_folder, has_child,
  } = page;

  const title = is_folder ? `<${name}>` : name;

  const handleSelect = () => {
    onSelect(page);
  };

  return isContent
    ? (
      <div
        className={cn(styles.item, styles.container, { [styles.active]: id === value })}
        onClick={handleSelect}
      >

        <ButtonSVG>
          <Page width={24} heigth={24} />
        </ButtonSVG>

        {title}

      </div>
    )
    : (
      <AccordionHeader
        className={cn(styles.container, { [styles.active]: id === value })}
        hasChildren={has_child}
        title={(
          <div onClick={handleSelect}>
            {title}
          </div>
        )}
        leftIcons={(
          <ChevronRight
            width={24}
            height={24}
            style={{ fill: has_child ? undefined : '#5E5E5D' }}
          />
        )}
      />
    );
};

export default PageSelectItem;
