import { createAction } from 'redux-actions';
import appStore from '../../utils/store';
import { requestWrapper } from '../../utils/requestWrapper';
import { AUTH } from '../../constants/type';
import { API_SIGN_IN_URL } from '../../constants/api';
import { setAuthHeader, removeAuthHeader } from '../../utils/apiService';

export const signIn = async ({ remember, ...requestData }, actions) => {
  try {
    const { key, additional } = await requestWrapper(
      AUTH.SIGN_IN,
      API_SIGN_IN_URL,
      'post',
      false,
      requestData,
    );
    const callBack = () => {
      appStore.dispatch(createAction(AUTH.SIGN_IN.base)());
    };

    setAuthHeader(key, additional, remember, callBack);
  } catch (e) {
    if (e.non_field_errors) {
      if (actions?.setFieldError) {
        actions.setFieldError('username', e.non_field_errors);

        actions.setFieldError('password', e.non_field_errors);
      }
    } else {
      // eslint-disable-next-line no-debugger
      debugger;
      console.log('e', e);
    }
  }
};

export const signOut = () => {
  const callBack = () => {
    appStore.dispatch(createAction(AUTH.SIGN_OUT.base)());
  };

  removeAuthHeader(callBack);
};
