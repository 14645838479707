const filterParent = (categories, categoryId) => {
  const category = categories.find(({ id }) => id === categoryId);

  if (category?.parent) {
    return [
      categoryId,
      filterParent(categories, category.parent),
    ].flat();
  }

  return categoryId;
};

const filterCategories = (items, categories, itemsParent) => {
  const categoryIds = Array
    .from(new Set(items.map((item) => item[itemsParent])));
  return Array
    .from(new Set(categoryIds.map((categoryId) => filterParent(categories, categoryId)).flat()));
};

export const filterTab = (search, items, categories, itemsParent = 'parent') => {
  const searchValue = search.trim().toLowerCase();

  if (searchValue.length > 2) {
    const filteredItems = items
      .filter((item) => item.name.toLowerCase().indexOf(searchValue) !== -1);

    const filteredCategoryIds = filterCategories(filteredItems, categories, itemsParent);

    const filteredCategories = categories
      .filter((category) => filteredCategoryIds.includes(category.id));

    return {
      items: filteredItems,
      categories: filteredCategories,
    };
  }

  return {
    items,
    categories,
  };
};
