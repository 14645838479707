import { createAction } from 'redux-actions';
import { requestWrapper, dataWrapper } from '../../utils/requestWrapper';
import { SNIPPETS } from '../../constants/type';
import { API_SNIPPETS, API_SNIPPET_PREVIEW } from '../../constants/api';
import { wrapSimple, wrapSnippet, wrapCategory } from '../../utils/actionWraps';

export const getList = async () => {
  const request = () => requestWrapper(
    SNIPPETS.GET_LIST,
    API_SNIPPETS.LIST_URL,
    'get',
    false,
  );

  wrapSimple(request);
};

export const getSnippet = async (id) => {
  if (!id) {
    const request = () => dataWrapper(
        SNIPPETS.GET_FULL,
        {
          css_code: "",
          css_code_type: "css",
          css_path: undefined,
          description: "new description",
          fields: {},
          icon: undefined,
          id: id,
          is_container: false,
          js_code: "",
          js_path: undefined,
          name: "new name",
          preview: undefined,
          public_status: false,
          short_name: "new short_name",
          slug: undefined,
          snippet_category: null,
          template_code: "",
          template_path: ""
        },
    );

    wrapSimple(request);
    return
  }
  const request = () => requestWrapper(
    SNIPPETS.GET_FULL,
    `${API_SNIPPETS.LIST_URL}${id}/`,
    'get',
    false,
  );

  wrapSimple(request);
};

export const createSnippet = async (requestData, actions) => {
  const request = () => requestWrapper(
    SNIPPETS.CREATE,
    API_SNIPPETS.LIST_URL,
    'post',
    null,
    requestData,
  );

  wrapSnippet(request, actions);
};

export const editSnippet = async (requestData, actions) => {
  const id = requestData.get ? requestData.get('id') : requestData.id;

  const request = () => requestWrapper(
    SNIPPETS.EDIT,
    `${API_SNIPPETS.LIST_URL}${id}/`,
    'put',
    null,
    requestData,
  );

  wrapSnippet(request, actions);
};

export const removeSnippet = async (id, params) => {

  const request = () => requestWrapper(
    SNIPPETS.REMOVE,
    `${API_SNIPPETS.LIST_URL}${id}/`,
    'delete',
    null,
    null,
    null,
    params.onSuccess,
    params.onFailure,
  );

  wrapSnippet(request);
};

export const getCategories = async () => {
  const request = () => requestWrapper(
    SNIPPETS.GET_CATEGORIES,
    API_SNIPPETS.CATEGORIES_URL,
    'get',
  );

  wrapSimple(request);
};

export const createCategory = async (requestData, actions) => {
  const request = () => requestWrapper(
    SNIPPETS.CREATE_CATEGORY,
    API_SNIPPETS.CATEGORIES_URL,
    'post',
    null,
    requestData,
  );

  wrapCategory(request, actions);
};

export const editCategory = async (requestData, actions) => {
  const id = requestData.get ? requestData.get('id') : requestData.id;

  const request = () => requestWrapper(
    SNIPPETS.EDIT_CATEGORY,
    `${API_SNIPPETS.CATEGORIES_URL}${id}/`,
    'put',
    null,
    requestData,
  );

  wrapCategory(request, actions);
};

export const removeCategory = async (id, actions) => {
  const request = () => requestWrapper(
    SNIPPETS.REMOVE_CATEGORY,
    `${API_SNIPPETS.CATEGORIES_URL}${id}/`,
    'delete',
  );

  wrapCategory(request, actions);
};

export const setHelperValues = createAction(SNIPPETS.SET_HELPER_VALUES.base);
export const setHelperActions = createAction(SNIPPETS.SET_HELPER_ACTIONS.base);
export const setHelperDisable = createAction(SNIPPETS.SET_HELPER_DISABLE.base);

export const getContent = async (requestData) => {
  const request = () => requestWrapper(
    SNIPPETS.GET_KEY,
    API_SNIPPET_PREVIEW,
    'post',
    null,
    requestData,
  );

  wrapSimple(request);
};
