import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { keySelector } from '../services/selectors/snippets';
import { getContent } from '../services/actions/snippets';

const useRenderSnippet = (code, c_js, c_css, c_css_type) => {
  const key = useSelector(keySelector);

  useEffect(() => {
    const requestData = {
      template_code: code,
      js_code: c_js,
      css_code: c_css,
      css_code_type: c_css_type,
    };

    getContent(requestData);
  }, [code, c_js, c_css, c_css_type]);

  return key;
};

export default useRenderSnippet;
