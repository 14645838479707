import React, {
  useMemo, useCallback, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { InputUnControlled } from '../../../../../../components/Input';
import { SnippetWrap } from '../../../../../../components/SnippetProperties/SnippetWrap';
import SnippetRow from '../../../../../../components/SnippetProperties/SnippetWrap/components/SnippetRow';
import BlockPropsLanguage from '../BlockProps/components/BlockPropsLanguage';

const BlockText = ({
  id, item: {
    name, values, group, errors,
  }, language, onChange,
}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    const currentValue = values[language] || '';

    setValue(currentValue);
  }, [values, language]);

  const handleBlur = useCallback((e) => {
    const data = {
      id,
      group,
      language,
      value: e.target.value,
    };

    onChange(data);
  }, [id, group, language, onChange]);

  const handleChange = (e) => setValue(e.target.value);

  const error = (errors && errors[language]) || false;

  const input = useMemo(() => (
    <InputUnControlled
      id={id}
      name={id}
      onChange={handleChange}
      onBlur={handleBlur}
      error={error}
      value={value}
    />
  ), [id, error, value, handleBlur]);

  return (
    <SnippetWrap>

      <SnippetRow
        first={name}
        second={input}
        third={(
          <BlockPropsLanguage language={language} />
        )}
      />

    </SnippetWrap>
  );
};

BlockText.propTypes = {
  id: PropTypes.string.isRequired,
  item: PropTypes.shape({
    name: PropTypes.string,
    values: PropTypes.object,
  }).isRequired,
  language: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default BlockText;
