import React, { useRef } from 'react';
import { useFormikContext } from 'formik';
import { ReactComponent as Upload } from '../../../../assets/icons/upload.svg';
import Subtitle from '../../../../components/Subtitle/Subtitle';
import { Button } from '../../../../components/Button';
import InputUpload from '../../../../components/inputUpload/inputUpload';
import { translate } from '../../../../utils/translate';
import Icon from '../Icon';
import styles from './styles.module.scss';

const UploadIcon = ({
  label, description, name, id,
}) => {
  const { values } = useFormikContext();

  const uploadInput = useRef(null);

  const triggerUploadInput = () => {
    uploadInput.current.click();
  };

  const file = values[name];

  return (
    <div className={styles.container}>

      <Subtitle>{label}</Subtitle>

      <div className={styles.wrap}>

        <div className={styles.first}>

          <Icon file={file} />

        </div>

        <div className={styles.second}>

          <InputUpload ref={uploadInput} name={name} id={id} />

          <Button type="button" className={styles.button} onClick={triggerUploadInput}>
            <Upload />
            {translate('Upload')}
          </Button>

          { description && <p className={styles.description}>{translate(description)}</p>}

        </div>

      </div>

    </div>
  );
};

export default UploadIcon;
