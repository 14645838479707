import { useEffect, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PAGES_ROUTE } from 'constants/routes';
import {
  getCurrentPage, createPage, editPage, removePage,
} from '../services/actions/pages';
import { pageWebsiteSelector } from '../services/selectors/pages';

export const usePage = (pageId, wId, actions) => {
  let webId;
  const match = useRouteMatch(PAGES_ROUTE);
  if (match) {
    webId = match.params.webId;
  }
  const id = wId || +webId;

  const selector = useCallback((state) => pageWebsiteSelector(state, id, pageId), [pageId, id]);

  const currentPage = useSelector(selector);

  const onCreate = createPage;
  const onEdit = editPage;
  const onRemove = (...args) => removePage(...args, id);

  useEffect(() => {
    if (!currentPage && typeof pageId === 'number' && !isNaN(pageId)) getCurrentPage(pageId, id, actions);
  }, [currentPage, pageId, id, actions]);

  return {
    currentPage,
    onCreate,
    onEdit,
    onRemove,
  };
};
