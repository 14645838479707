import React from 'react';
import PropTypes from 'prop-types';
import AssetsModalContent from './components/AssetsModalContent';
import Modal from '../../Modal/Modal';
import ModalHeader from '../../Modal/componets/ModalHeader';
import ModalContent from '../../Modal/componets/ModalContent';
import { translate } from '../../../utils/translate';
import styles from './styles.module.scss';

const AssetsModal = ({
  show, filter, onClose, onChose,
}) => {
  const handleChose = (props) => {
    onChose(props);

    onClose();
  };

  return (
    <Modal
      show={Boolean(show)}
      onClose={onClose}
      classNameContainer={styles.modal}
    >

      <ModalHeader
        className={styles.header}
        title={translate('Global Assets')}
        onClose={onClose}
      />

      <ModalContent
        tag="div"
        className={styles.content}
      >

        <AssetsModalContent
          onChose={handleChose}
          filter={filter}
        />

      </ModalContent>

    </Modal>
  );
};

AssetsModal.defaultProps = {
  show: false,
};

AssetsModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onChose: PropTypes.func.isRequired,
};

export default AssetsModal;
