import React, { useRef } from 'react';
import { useDrop, useDrag } from 'react-dnd';
import { dropTypes } from '../../../../../../utils/dropTypes';
import { useWebsitesTab } from '../../../../../../hooks/useWebsitesTab';

const WebsiteDropWrap = ({
  item, children,
}) => {
  const ref = useRef(null);

  const {
    onEdit,
    onEditCategory,
  } = useWebsitesTab();

  const [{ isOverCurrent }, drop] = useDrop({
    accept: [dropTypes.WEBSITE_DIRECTORY, dropTypes.WEBSITE],
    drop(dragItem, monitor) {
      const didDrop = monitor.didDrop();
      if (didDrop) return;

      if (
        dragItem.type === dropTypes.WEBSITE_DIRECTORY
        && dragItem.category.id !== item.category.id
      ) {
        onEditCategory({
          ...dragItem.category,
          parent: item.category.id,
        });
      }

      if (dragItem.type === dropTypes.WEBSITE) {
        const { icon, web_clip, ...website } = dragItem.website;

        onEdit({
          ...website,
          website_category: item.category.id,
        });
      }
    },
    collect: (monitor) => ({
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
  });

  const [{ isDragging }, drag] = useDrag({
    item: {
      category: item.category,
      type: dropTypes.WEBSITE_DIRECTORY,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;

  const backgroundColor = isOverCurrent ? 'rgba(0,0,0,.2)' : 'transparent';

  drag(drop(ref));

  return (
    <div
      ref={ref}
      style={{
        opacity,
        backgroundColor,
      }}
    >
      {children}
    </div>
  );
};

export default WebsiteDropWrap;
