import { toast } from 'react-toastify';
import { requestWrapper } from '../../utils/requestWrapper';
import { REDIRECTS } from '../../constants/type';
import { API_REDIRECT } from '../../constants/api';
import { wrapRedirect, wrapSimple } from '../../utils/actionWraps';
import { baseApi } from '../../utils/apiService';
import { translate } from '../../utils/translate';

export const getList = (webId) => {
  const request = () => requestWrapper(
    REDIRECTS.GET_LIST,
    `${API_REDIRECT}?website=${webId}`,
    'get',
    false,
    { webId },
    true,
  );

  wrapSimple(request);
};

export const createRedirect = async (data, actions) => {
  let requestData = data;

  if (data.get) {
    requestData.append('key', baseApi.AddToken);
  } else {
    requestData = {
      ...requestData,
      key: baseApi.AddToken,
    };
  }

  const onSuccess = () => {
    if (actions?.onSuccess) actions.onSuccess();

    const webId = data.get ? data.get('website') : data.website;
    getList(webId);
  };

  const extendActions = {
    ...actions,
    onSuccess,
  };

  const request = () => requestWrapper(
    REDIRECTS.CREATE,
    API_REDIRECT,
    'post',
    false,
    requestData,
    true,
  );

  wrapRedirect(request, extendActions);
};

export const editRedirect = async (data, actions) => {
  let requestData = data;

  if (data.get) {
    requestData.append('key', baseApi.AddToken);
  } else {
    requestData = {
      ...requestData,
      key: baseApi.AddToken,
    };
  }

  const id = data.get ? data.get('id') : data.id;

  const onSuccess = () => {
    if (actions?.onSuccess) actions.onSuccess();

    const webId = data.get ? data.get('website') : data.website;
    getList(webId);
  };

  const extendActions = {
    ...actions,
    onSuccess,
  };

  const request = () => requestWrapper(
    REDIRECTS.EDIT,
    `${API_REDIRECT}${id}/`,
    'put',
    false,
    requestData,
  );

  wrapRedirect(request, extendActions);
};

export const removeRedirect = async ({ id, webId }, actions) => {
  const onSuccess = () => {
    if (actions?.onSuccess) actions.onSuccess();

    getList(webId);

    toast.success(translate('Redirect removed'));
  };

  const extendActions = {
    ...actions,
    onSuccess,
  };

  const request = () => requestWrapper(
    REDIRECTS.REMOVE,
    `${API_REDIRECT}${id}/`,
    'delete',
    false,
  );

  wrapRedirect(request, extendActions);
};
