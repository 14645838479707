/* eslint-disable no-restricted-globals */
import { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { PAGES_ROUTE } from 'constants/routes';
import {
  createPage, editPage, removePage, getPageChildren, getList,
} from '../services/actions/pages';
import { pagesWebsiteSelector } from '../services/selectors/pages';

export const usePagesTab = (wId) => {
  let webId;
  const match = useRouteMatch(PAGES_ROUTE);
  if (match) {
    webId = match.params.webId;
  }
  const id = wId || +webId;

  const selector = useCallback((state) => pagesWebsiteSelector(state, id), [id]);

  const { pages = [], loaded } = useSelector(selector);
  const searchList = useSelector((state) => state.pages.search);

  const onCreate = createPage;
  const onEdit = editPage;
  const onRemove = (...args) => removePage(...args, id);

  const onOpenAccordion = (websiteId, pageId) => {
    getPageChildren(websiteId, pageId);
  };

  useEffect(() => {
    if (typeof id === 'number' && !isNaN(id) && !loaded) {
      getList(id);
    }
  }, [id, loaded]);

  return {
    pages,
    searchList,
    onCreate,
    onEdit,
    onRemove,
    onOpenAccordion,
  };
};
