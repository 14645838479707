export const LEFT_MENU = {
  SITES: 'SITES',
  PAGES: 'PAGES',
  SNIPPETS: 'SNIPPETS',
  TEMPLATES: 'TEMPLATES',
  SNIPPET_EDITOR: 'SNIPPET_EDITOR',
  ASSETS: 'ASSETS',
};

export const URL_QUERY_PARAMS = {
  LEFT_BAR: 'ui_left_bar',
  SNIPPET_AT_PAGE: 'ui_snippet_at_page',
};

export const UI_ACTIONS = {
  SELECT_LEFT_MENU: 'SELECT_LEFT_MENU',
  SELECT_RIGHT_MENU: 'SELECT_RIGHT_MENU',
  SELECTED_WEBSITE: 'SELECTED_WEBSITE',
  SELECTED_PAGE: 'SELECTED_PAGE',
  SELECTED_SNIPPET: 'SELECTED_SNIPPET',
  SELECTED_SNIPPET_AT_PAGE: 'SELECTED_SNIPPET_AT_PAGE',
  PARSE_PATH: 'PARSE_PATH',
};
