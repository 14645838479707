import React, { useMemo, useState, useCallback } from 'react';
import MediaModal from '../../../../components/CustomModals/MediaModal';
import BigMediaModal from '../../../../components/CustomModals/BigMediaModal';
import { useModal } from '../../../../hooks/useModal';
import { useMediaTab } from '../../../../hooks/useMediaTab';
import MediaFormContent from './components/MediaFormContent';
import MediaForm from './components/MediaForm';
import DirectoryModal from './components/DirectoryModal';

const MediaContent = ({ openModal, openLoaderMedia, hide }) => {
  const {
    media,
    categories,
    createMediaLoader,
    onRemove,
    onOpenAccordion,
  } = useMediaTab();

  const content = useMemo(() => (
    <MediaFormContent
      list={media}
      categories={categories}
      createLoading={createMediaLoader.loading}
      onOpenAccordion={onOpenAccordion}
      openModal={openModal}
      openLoaderMedia={openLoaderMedia}
    />
  ), [
    media, categories, createMediaLoader, onOpenAccordion, openModal, openLoaderMedia,
  ]);

  return (
    <MediaForm onRemove={onRemove} hide={hide}>
      {content}
    </MediaForm>
  );
};

export const MediaTab = ({ hide }) => {
  const [modal, openModal, closeModal] = useModal(false);

  const [loaderMedia, setLoaderMedia] = useState({});

  const [directory, setDirectory] = useState(false);

  const handleClose = useCallback(() => {
    setLoaderMedia({});

    setDirectory(false);
  }, []);

  const onUpload = useCallback((files) => {
    setDirectory(files);
  }, []);

  const onSelectDirectory = useCallback((selectedDirectory) => {
    setLoaderMedia({
      files: directory,
      directory: selectedDirectory,
    });

    setDirectory(false);
  }, [directory]);

  const loaderModal = useMemo(() => (
    <BigMediaModal
      files={loaderMedia?.files}
      directory={loaderMedia?.directory}
      onClose={handleClose}
    />
  ), [loaderMedia, handleClose]);

  const directoryModal = useMemo(() => (
    <DirectoryModal
      show={directory}
      onClose={handleClose}
      onSelect={onSelectDirectory}
    />
  ), [directory, handleClose, onSelectDirectory]);

  return (
    <>

      <MediaModal
        show={modal}
        onClose={closeModal}
      />

      {loaderModal}

      {directoryModal}

      <MediaContent
        openModal={openModal}
        openLoaderMedia={onUpload}
        hide={hide}
      />

    </>
  );
};
