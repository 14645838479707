import React, { useMemo, useState } from 'react';
import { Tabs, TabPanel } from 'react-tabs';
import ModalTab from '../../../../components/Modal/componets/ModalTab';
import ModalHeader from '../../../../components/Modal/componets/ModalHeader';
import ModalContent from '../../../../components/Modal/componets/ModalContent';
import ModalTabList from '../../../../components/Modal/componets/ModalTabList';
import { translate } from '../../../../utils/translate';
import HeaderModal from '../HeaderModal';
import Templates from '../Templates';
import General from '../General';
import Seo from '../Seo';
import styles from './styles.module.scss';

const ContentModal = ({
  isEdit, contentTypes, currentWebsite, currentPage, onRemove, onClose,
}) => {
  const [editingSlug, setEditingSlug] = useState(!isEdit);

  const header = useMemo(() => (
    <HeaderModal
      isEdit={isEdit}
      page={currentPage}
      onRemove={onRemove}
      onClose={onClose}
    />
  ), [isEdit, currentPage, onRemove, onClose]);

  const general = useMemo(() => (
    <General
      editingSlug={editingSlug}
      currentWebsite={currentWebsite}
      currentPage={currentPage}
      contentTypes={contentTypes}
      setEditingSlug={setEditingSlug}
    />
  ), [editingSlug, currentWebsite, currentPage, contentTypes, setEditingSlug]);

  const seo = useMemo(() => (<Seo />), []);

  const templates = useMemo(() => (
    <Templates
      page={currentPage}
    />
  ), [currentPage]);

  const tabs = [
    {
      title: translate('General'),
      content: general,
    },
    {
      title: translate('Seo'),
      content: seo,
    },
    {
      title: translate('Templates'),
      content: templates,
    },
  ];

  return (
    <Tabs
      className={styles.tabs}
    >

      <ModalHeader title={translate(isEdit ? 'Edit Page' : 'Creating a new Page')} onClose={onClose}>

        {header}

        {ModalTabList({
          className: styles.tabList,
          children: tabs.map(({ title }) => ModalTab({
            key: title,
            children: title,
          })),
        })}

      </ModalHeader>

      <ModalContent>

        { tabs.map(({ title, content }) => (
          <TabPanel key={title}>
            {content}
          </TabPanel>
        )) }

      </ModalContent>

    </Tabs>
  );
};

export default ContentModal;
