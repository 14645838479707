import React from 'react';
import { ErrorMessage } from 'formik';
import Title from '../../../../components/Title/Title';
import Subtitle from '../../../../components/Subtitle/Subtitle';
import { Input } from '../../../../components/Input';
import Row from '../Row';
import ErrorTooltip from '../ErrorTooltip';
import { translate } from '../../../../utils/translate';
import styles from './styles.module.scss';

const SeoMeta = () => (
  <section className={styles.container}>
    <Title className={styles.title}>{translate('Seo Meta')}</Title>
    <Subtitle>{translate('Html')}</Subtitle>

    <Row
      first={(
        <Input
          type="textarea"
          name="seo_meta"
          id="seo_meta"
          placeholder={translate('Enter meta tags (as HTML)')}
          styleType="2"
          rows={10}
        />
      )}
      second={<ErrorMessage name="seo_meta" component={ErrorTooltip} />}
    />
  </section>
);

export default SeoMeta;
