import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  createRedirect, editRedirect, removeRedirect,
} from '../services/actions/redirects';
import { redirectSelector } from '../services/selectors/redirects';

export const useRedirect = (webId, id) => {
  const selector = useCallback((state) => redirectSelector(state, webId, id), [webId, id]);

  const currentRedirect = useSelector(selector);

  const onCreate = createRedirect;
  const onEdit = editRedirect;
  const onRemove = (...args) => removeRedirect({ webId, id }, ...args);

  return {
    currentRedirect,
    onCreate,
    onEdit,
    onRemove,
  };
};
