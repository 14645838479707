import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { ReactComponent as Close } from '../../../../assets/icons/close.svg';
import ButtonSVG from '../../../ButtonSVG';
import { translate } from '../../../../utils/translate';
import styles from './styles.module.scss';

const ModalHeader = ({
  title, className, onClose, children,
}) => (
  <header className={cn(styles.header, className)}>

    <div className={styles.wrap}>

      <h2
        className={styles.title}
      >
        {title}
      </h2>

      {onClose && (
        <ButtonSVG
          onClick={onClose}
          title={translate('Close')}
        >
          <Close />
        </ButtonSVG>
      )}

    </div>

    <div
      className={styles.content}
    >
      {children}
    </div>

  </header>
);

ModalHeader.defaultProps = {
  title: '',
  className: '',
  onClose: undefined,
  children: undefined,
};

ModalHeader.propType = {
  title: PropTypes.string,
  className: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.node,
};

export default ModalHeader;
