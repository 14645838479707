import React, { forwardRef } from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.module.scss';

/* eslint-disable react/jsx-props-no-spreading */
const InputContainer = ({
  label, className, styleType, invert, disabled, children,
}) => (
  <div className={cn(
    styles.container,
    className,
    { [styles[`type-${styleType}`]]: styleType },
    { [styles.invert]: invert },
  )}
  >
    {label && (
      <label
        htmlFor={label}
        className={cn(styles.label, { [styles[`type-${styleType}`]]: styleType }, { [styles.disabled]: disabled })}
      >
        {label}
      </label>
    )}
    {children}
  </div>
);

InputContainer.defaultProps = {
  label: undefined,
  className: null,
  styleType: null,
  invert: false,
  disabled: false,
};

InputContainer.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
  ]),
  className: PropTypes.string,
  styleType: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  invert: PropTypes.bool,
  disabled: PropTypes.bool,
};

const InputWrap = ({ children }) => (
  <div className={styles.wrap}>{children}</div>
);

const Textarea = forwardRef(({
  id,
  label,
  field,
  placeholder,
  disabled,
  isError,
  styleType,
  classNameControl,
  ...otherProps
}, ref) => {
  const idFor = id || label;

  return (
    <InputWrap>
      <textarea
        id={idFor}
        rows={3}
        {...field}
        {...otherProps}
        ref={ref}
        placeholder={placeholder}
        className={cn(
          styles.input,
          styles.textarea,
          { [styles.error]: isError },
          { [styles.disabled]: disabled },
          { [styles[`type-${styleType}`]]: styleType },
          classNameControl,
        )}
        disabled={disabled}
      />
    </InputWrap>
  );
});

Textarea.defaultProps = {
  id: null,
  label: null,
  field: {},
  placeholder: null,
  disabled: false,
  isError: false,
  styleType: null,
  classNameControl: '',
};

Textarea.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
  ]),
  field: PropTypes.object,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isError: PropTypes.bool,
  styleType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  classNameControl: PropTypes.string,
};

const Checkbox = forwardRef(({
  id,
  label,
  field,
  placeholder,
  disabled,
  isError,
  styleType,
  classNameControl,
  ...otherProps
}, ref) => {
  const idFor = id || label;

  return (
    <InputWrap ref={ref}>
      <input
        id={idFor}
        type="checkbox"
        {...field}
        {...otherProps}
        ref={ref}
        placeholder={placeholder}
        className={cn(
          styles.checkbox,
          { [styles.error]: isError },
          { [styles.disabled]: disabled },
          classNameControl,
        )}
        disabled={disabled}
      />
      <label
        htmlFor={idFor}
        className={cn(styles.checkbox_wrap, { [styles[`type-${styleType}`]]: styleType }, { [styles.error]: isError })}
      />
    </InputWrap>
  );
});

Checkbox.defaultProps = {
  id: null,
  label: '',
  field: {},
  placeholder: '',
  disabled: false,
  isError: false,
  styleType: null,
  classNameControl: '',
};

Checkbox.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
  ]),
  field: PropTypes.object,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isError: PropTypes.bool,
  styleType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  classNameControl: PropTypes.string,
};

const Text = forwardRef(({
  id,
  label,
  field,
  onChange,
  placeholder,
  disabled,
  isError,
  styleType,
  classNameControl,
  dark,
  ...otherProps
}, ref) => {
  const idFor = id || label;

  const handleChange = (e) => {
    const setValue = () => (field.onChange(e));

    if (onChange) {
      onChange(e, setValue);
    } else {
      setValue();
    }
  };

  return (
    <input
      id={idFor}
      type="text"
      {...field}
      onChange={handleChange}
      {...otherProps}
      ref={ref}
      placeholder={placeholder}
      className={cn(
        styles.input,
        { [styles.error]: isError },
        { [styles.disabled]: disabled },
        { [styles.dark]: dark },
        { [styles[`type-${styleType}`]]: styleType },
        classNameControl,
      )}
      disabled={disabled}
    />
  );
});

const InputContent = forwardRef(({ type, ...props }, ref) => {
  switch (type) {
    case 'textarea': {
      return (<Textarea {...props} ref={ref} />);
    }
    case 'checkbox': {
      return (<Checkbox {...props} ref={ref} />);
    }
    default:
      return (<Text {...props} type={type} ref={ref} />);
  }
});

Text.defaultProps = {
  id: null,
  label: '',
  field: {},
  placeholder: '',
  disabled: false,
  isError: false,
  styleType: null,
  classNameControl: '',
  dark: false,
};

Text.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
  ]),
  field: PropTypes.object,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isError: PropTypes.bool,
  styleType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  classNameControl: PropTypes.string,
  dark: PropTypes.bool,
};

export const Input = forwardRef((props, ref) => {
  const [field = {}, meta = {}] = useField(props);

  const { error, touched } = meta;

  const {
    label,
    disabled,
    className,
    styleType,
    invert,
    ...otherProps
  } = props;

  return (
    <InputContainer
      label={label}
      className={className}
      styleType={styleType}
      invert={invert}
      disabled={disabled}
    >
      <InputContent
        ref={ref}
        label={label}
        disabled={disabled}
        styleType={styleType}
        isError={error && touched}
        field={field}
        {...otherProps}
      />
    </InputContainer>
  );
});

Input.defaultProps = {
  label: undefined,
  className: null,
  styleType: null,
  invert: false,
  disabled: false,
  type: 'text',
  placeholder: '',
  classNameControl: null,
  tooltip: null,
};

Input.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.number,
    PropTypes.string,
  ]),
  className: PropTypes.string,
  styleType: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  invert: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  classNameControl: PropTypes.string,
  tooltip: PropTypes.string,
};

export const InputUnControlled = forwardRef((props, ref) => {
  const {
    label,
    disabled,
    className,
    styleType,
    invert,
    error,
    ...otherProps
  } = props;

  return (
    <InputContainer
      label={label}
      className={className}
      styleType={styleType}
      invert={invert}
      disabled={disabled}
    >
      <InputContent
        ref={ref}
        label={label}
        disabled={disabled}
        styleType={styleType}
        isError={error}
        {...otherProps}
      />
    </InputContainer>
  );
});

InputUnControlled.defaultProps = {
  label: undefined,
  className: null,
  styleType: null,
  invert: false,
  disabled: false,
  type: 'text',
  placeholder: '',
  classNameControl: null,
  tooltip: null,
  error: false,
};

InputUnControlled.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
  ]),
  className: PropTypes.string,
  styleType: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  invert: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  classNameControl: PropTypes.string,
  tooltip: PropTypes.string,
  error: PropTypes.bool,
};
