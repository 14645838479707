import React, { useEffect, useState } from 'react';
import { ReactComponent as EditIcon } from '../../../../../../../../assets/icons/edit.svg';
import { InputUnControlled } from '../../../../../../../../components/Input';
import ButtonSVG from '../../../../../../../../components/ButtonSVG';
import { useModal } from '../../../../../../../../hooks/useModal';
import { usePage } from '../../../../../../../../hooks/usePage';
import ModalLink from '../ModalLink';
import styles from './styles.module.scss';

const InnerLink = ({
  id, value, info, onChange,
}) => {
  const onError = () => {
    onChange({
      value: '',
      props: {},
    });
  };

  const { currentPage } = usePage(info?.page, info?.site, { onError });

  const [innerValue, setInnerValue] = useState('');

  const [modal, openModal, closeModal] = useModal(false);

  useEffect(() => {
    const currentValue = info?.mode === 'link' ? (currentPage?.tree || '') : '';

    setInnerValue(currentValue);
  }, [value, currentPage, info]);

  return (
    <div className={styles.container}>

      <InputUnControlled
        id={`${id}-inner`}
        value={innerValue}
        disabled
      />

      <ButtonSVG
        className={styles.button}
        onClick={openModal}
      >
        <EditIcon
          width={16}
          height={16}
        />
      </ButtonSVG>

      <ModalLink
        show={modal}
        onClose={closeModal}
        onChange={onChange}
        info={info}
      />

    </div>
  );
};

export default InnerLink;
