import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import styles from './styles.module.scss';
import ButtonSVG from '../../../../components/ButtonSVG';
import { ReactComponent as Delete } from '../../../../assets/icons/trash.svg';
import { promptOptions } from '../../../../utils/promptOptions';
import { useRedirect } from '../../../../hooks/useRedirect';
import { translate } from '../../../../utils/translate';
import { Button } from '../../../../components/Button';

const RedirectItem = ({ item, webId, openModalRedirectEdit }) => {
  const {
    id, name, source, preview, permanent, enable,
  } = item;

  const {
    onRemove,
  } = useRedirect(webId, id);

  const onRemoveClicked = () => {
    confirmAlert(promptOptions({
      buttons: [
        {
          label: 'No',
        },
        {
          label: 'Yes',
          color: 'danger',
          onClick: onRemove,
        },
      ],
    }));
  };

  return (
    <div className={styles.container}>

      <div className={styles.wrap}>

        <h3 className={enable ? styles.title : styles.title_off}>

          <span>{name}</span>
          <span>
            {` (${permanent ? '301' : '302'})`}
          </span>
        </h3>

        <p className={styles.description}>
          {`${source} -> ${preview.preview}`}
        </p>

      </div>

      <div className={styles.buttons}>
        <Button
          type="button"
          className={styles.button}
          onClick={openModalRedirectEdit}
        >
          {translate('Edit')}
        </Button>

        <ButtonSVG
          id={id}
          className={styles.button}
          onClick={onRemoveClicked}
        >
          <Delete />
        </ButtonSVG>
      </div>

    </div>
  );
};

export default RedirectItem;
