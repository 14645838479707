import React from 'react';
import PropTypes from 'prop-types';
import { GalleryItem, GalleryItemProps } from './components/GalleryItem';
import style from './styles.module.scss';

/* eslint-disable react/jsx-props-no-spreading */
export const SnippetGallery = ({ options }) => (
  <div className={style.container}>
    { options.map((option) => {
      const { id } = option.snippet;

      return (
        <GalleryItem
          key={id}
          {...option}
        />
      );
    }) }
  </div>
);

SnippetGallery.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape(GalleryItemProps)).isRequired,
};
