import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { InputUnControlled } from '../../../../components/Input';
import getWebsiteLink from '../../getWebsiteLink';
import { usePage } from '../../../../hooks/usePage';

const PageInputLink = ({ currentWebsite, placeholder }) => {
  const { values: { parent, slug } } = useFormikContext();

  const { currentPage: parentPage } = usePage(parent, currentWebsite.id);

  const websiteURL = getWebsiteLink(currentWebsite);

  const [value, setValue] = useState('');

  useEffect(() => {
    const tree = parentPage?.tree ? `${parentPage.tree}/` : '';

    const slugName = slug ? `${slug}/` : slug;

    // ! TODO need fix on backend side. DRYFTSHOP-617
    const path = (() => {
      let data = '';

      `${websiteURL}${tree}${slugName}`.split('/').forEach((item) => {
        if (item && item.indexOf('__folder__') === -1) {
          data = `${data}${item}/`;
        }
      });

      return data;
    })();

    // setValue(`${websiteURL}${tree}${slugName}`);
    setValue(path);
  }, [slug, websiteURL, parentPage]);

  return (
    <InputUnControlled
      type="text"
      name="link"
      id="link"
      placeholder={placeholder}
      styleType="2"
      disabled
      value={value}
    />
  );
};

export default PageInputLink;
