import React, { useEffect, useMemo } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import OutsideClickHandler from 'react-outside-click-handler';
import getStructurePages from '../../../../getStructurePages';
import { Accordion } from '../../../../../../../../components/Accordion';
import { usePagesTab } from '../../../../../../../../hooks/usePagesTab';
import PageSelectItem from '../PageSelectItem';
import styles from '../../styles.module.scss';

const PageSelectModal = ({
  divRef, value, webId, pageId, filterChild, onSelect, onClose,
}) => {
  const element = document.createElement('div');

  const { current } = divRef;

  const {
    pages,
    onOpenAccordion,
  } = usePagesTab(webId);

  useEffect(() => {
    current.appendChild(element);

    return () => {
      current.removeChild(element);
    };
  });

  const items = useMemo(() => {
    const header = (page) => (
      <PageSelectItem value={value} page={page} onSelect={onSelect} />
    );

    const content = (page) => (
      <PageSelectItem isContent value={value} page={page} onSelect={onSelect} />
    );

    const filterPage = () => (
      pageId && filterChild
        ? pages.filter((page) => page.id !== pageId)
        : pages
    );

    return getStructurePages({
      pages: filterPage(),
      search: '',
      header,
      content,
    });
  }, [pages, pageId, value, filterChild, onSelect]);

  const handleClose = (e) => {
    e.stopPropagation();

    onClose();
  };

  return (
    <div className={styles.modal}>

      <OutsideClickHandler
        onOutsideClick={handleClose}
      >
        <PerfectScrollbar className={styles.scrollBar}>

          { items.length > 0 && items.map((item) => (
            <Accordion
              key={item.id}
              item={item}
              withBorder={false}
              onOpen={(pageIdItem) => onOpenAccordion(webId, pageIdItem)}
            />
          )) }

        </PerfectScrollbar>

      </OutsideClickHandler>

    </div>
  );
};

export default PageSelectModal;
