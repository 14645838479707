import React from 'react';
import PropTypes from 'prop-types';

import styles from '../styles.module.scss';

export const AuthLayout = ({ children }) => (
  <div className={styles.mainWrapper}>
    <main className={styles.mainContent}>
      {children}
    </main>
  </div>
);

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
