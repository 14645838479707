import { toast } from 'react-toastify';
import { requestWrapper } from '../../utils/requestWrapper';
import { wrapTemplate } from '../../utils/actionWraps';
import { translate } from '../../utils/translate';
import { API_PAGE_URL } from '../../constants/api';
import { TEMPLATES } from '../../constants/type';

export const getTemplates = async () => {
  const request = () => requestWrapper(
    TEMPLATES.GET_LIST,
    `${API_PAGE_URL}template/`,
    'get',
  );

  wrapTemplate(request);
};

export const createTemplate = async (requestData, actions) => {
  const { pageId } = requestData;

  const request = () => requestWrapper(
    TEMPLATES.CREATE,
    `${API_PAGE_URL}${pageId}/template/`,
    'post',
    false,
    requestData,
  );

  wrapTemplate(request, actions);
};

export const editTemplate = async (requestData, actions) => {
  const { id } = requestData;

  const request = () => requestWrapper(
    TEMPLATES.EDIT,
    `${API_PAGE_URL}template/${id}/`,
    'put',
    false,
    requestData,
  );

  wrapTemplate(request, actions);
};

export const removeTemplate = async (templateId, actions) => {
  const extendSuccess = () => {
    if (actions?.onSuccess) actions.onSuccess();

    toast.success(translate('Template removed'));
  };

  const request = () => requestWrapper(
    TEMPLATES.REMOVE,
    `${API_PAGE_URL}template/${templateId}/`,
    'delete',
    false,
  );

  wrapTemplate(request, { ...actions, onSuccess: extendSuccess });
};
