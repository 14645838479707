import React, { forwardRef } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
const ButtonSVG = forwardRef(({
  id, className, loading, type, disabled, children, onClick, ...props
}, ref) => {
  const handleClick = (e) => {
    e.stopPropagation();
    onClick(id || e);
  };

  return (
    <button
      ref={ref}
      type={type}
      className={cn(
        styles.button,
        className,
        { [styles.disabled]: disabled },
      )}
      disabled={disabled || loading}
      onClick={handleClick}
      {...props}
    >
      {children}
    </button>
  );
});

ButtonSVG.defaultProps = {
  id: undefined,
  type: 'button',
  disabled: false,
  loading: false,
  className: '',
  onClick: () => {},
};

ButtonSVG.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  disabled: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
  className: PropTypes.string,
  loading: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func,
};

export default ButtonSVG;
