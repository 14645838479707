import React, { useState, useCallback, useEffect } from 'react';
import cn from 'classnames';
import { ReactComponent as Empty } from '../../../../assets/icons/empty-icon-square.svg';
import styles from './styles.module.scss';

const Icon = ({ file, error, className }) => {
  const [source, setSource] = useState('');

  const checkFile = useCallback(() => {
    if (typeof file === 'string') {
      setSource(file);
    } else if (file) {
      if (/(image\/*)/.test(file.type)) {
        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onloadend = () => {
          if (!error) {
            setSource(reader.result);
          } else {
            setSource('');
          }
        };
      } else {
        setSource('');
      }
    }
  }, [file, error]);

  useEffect(() => {
    checkFile();
  });

  var rnd = source && source.substr(0, 5) !== 'data:' ? `?r=${Math.random()}` : '';

  return (
    <div
      className={cn(
        styles.icon,
        className,
        { [styles.error]: error },
      )}
    >
      { source ? <img src={`${source}${rnd}`} alt="field-img" /> : <Empty /> }
    </div>
  );
};

export default Icon;
