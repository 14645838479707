import React from 'react';
import cn from 'classnames';
import Modal from '../../Modal/Modal';
import ModalHeader from '../../Modal/componets/ModalHeader';
import ModalContent from '../../Modal/componets/ModalContent';
import { translate } from '../../../utils/translate';
import styles from '../styles.module.scss';
import RedirectContent from './components/RedirectContent';

/* eslint-disable react/jsx-props-no-spreading */
const RedirectModal = (props) => {
  const { show, onClose } = props;

  const isEdit = show && typeof show === 'number';

  const title = `${isEdit ? 'Edit' : 'New'} redirect`;

  return (
    <Modal
      show={Boolean(show)}
      classNameContainer={cn(styles.modal, styles.redirectModal)}
      onClose={onClose}
    >

      <ModalHeader
        className={styles.header}
        title={translate(title)}
        onClose={onClose}
      />

      <ModalContent
        className={styles.content}
        tag="div"
      >

        <RedirectContent {...props} isEdit={isEdit} show={show} />

      </ModalContent>

    </Modal>
  );
};

export default RedirectModal;
