export const merge = (target, source, prop, parentId) => {
  let array = source;

  if (parentId) {
    array = [
      ...array.filter((item) => item.parent !== +parentId),
      ...target,
    ];
  } else {
    target.map((itemTarget) => {
      const currentIndex = array.findIndex((itemSource) => itemSource[prop] === itemTarget[prop]);

      if (currentIndex === -1) {
        array = [...array, itemTarget];
      } else {
        array[currentIndex] = itemTarget;
      }

      return itemTarget;
    });
  }

  return array;
};
