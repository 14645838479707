import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styles from './styles.module.scss';

const ModalContent = ({ tag: Tag, className, children }) => (
  <Tag
    className={cn(styles.content, className)}
  >
    {children}
  </Tag>
);

ModalContent.defaultProps = {
  className: '',
  tag: PerfectScrollbar,
};

ModalContent.propTypes = {
  className: PropTypes.string,
  tag: PropTypes.any,
};

export default ModalContent;
