import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.module.scss';

export const SnippetWrap = ({ className, children }) => (
  <div className={cn(styles.container, className)}>
    {children}
  </div>
);

SnippetWrap.defaultProps = {
  children: '',
};

SnippetWrap.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.node,
  ]),
};
