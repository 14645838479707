import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
// import { confirmAlert } from 'react-confirm-alert';
import Subtitle from '../../../../Subtitle/Subtitle';
import { Input } from '../../../../Input';
import { Button } from '../../../../Button';
// import { promptOptions } from '../../../../../utils/promptOptions';
import { translate } from '../../../../../utils/translate';
import { useMediaCategory } from '../../../../../hooks/useMediaCategory';
import MediaSelect from '../MediaSelect';
import styles from './styles.module.scss';

const MediaModalContent = ({
  show, isEdit, onClose,
}) => {
  const {
    currentCategory,
    onCreate,
    onEdit,
    // onRemove,
  } = useMediaCategory(show);

  const schema = Yup.object().shape({
    name: Yup.string().required(),
    parent: Yup.mixed().nullable(),
  });

  const initValue = useMemo(() => {
    if (isEdit && currentCategory) {
      return currentCategory;
    }

    return {
      name: '',
      parent: '',
    };
  }, [isEdit, currentCategory]);

  const handleSubmit = (category, { setFieldError }) => {
    const action = isEdit ? onEdit : onCreate;

    const data = isEdit
      ? {
        name: category.name,
        path: category.parent || '/',
        old_name: currentCategory.name,
        old_path: currentCategory.parent || '/',
        relative_path: category.relative_path,
      }
      : {
        name: category.name,
        path: category.parent || '/',
      };

    action(data, { setFieldError, onSuccess: onClose });
  };

  /* const handleRemove = async () => confirmAlert(promptOptions({
    buttons: [
      {
        label: 'No',
      },
      {
        label: 'Yes',
        color: 'danger',
        onClick: () => onRemove(currentCategory.relative_path, { onSuccess: onClose }),
      },
    ],
  })); */

  const mediaSelect = useMemo(() => (
    <div className={styles.row}>

      <Subtitle className={styles.subtitle}>{translate('Location')}</Subtitle>

      <MediaSelect
        name="parent"
        id="parent"
        placeholder={translate('Location')}
        currentCategory={currentCategory}
      />

    </div>
  ), [currentCategory]);

  return (
    <Formik
      initialValues={initValue}
      validationSchema={schema}
      onSubmit={handleSubmit}
      enableReinitialize
    >

      <Form>

        <div className={styles.row}>

          <Subtitle className={styles.subtitle}>{translate('Name')}</Subtitle>

          <Input
            type="text"
            name="name"
            id="name"
            placeholder={translate('Folder name')}
            styleType={2}
          />

        </div>

        {mediaSelect}

        <div className={styles.wrapButtons}>

          {/* {(onRemove && isEdit) && <Button onClick={handleRemove} color="danger">{translate('Remove')}</Button>} */}

          <Button type="submit">{translate('Submit')}</Button>

        </div>

      </Form>

    </Formik>
  );
};

export default MediaModalContent;
