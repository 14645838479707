import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Trash } from '../../../../assets/icons/trash.svg';
import ButtonSVG from '../../../../components/ButtonSVG';
import styles from './styles.module.scss';

const LangItem = ({ language: { iso, icon, name }, onRemove }) => (
  <div className={styles.container}>

    <div className={styles.icon}><img src={icon} alt={name} /></div>

    <div className={styles.title}>{`${name} (${iso.toUpperCase()})`}</div>

    { onRemove && (
      <ButtonSVG
        id={iso.toLowerCase()}
        className={styles.button}
        onClick={onRemove}
      >
        <Trash with={16} height={16} />
      </ButtonSVG>
    )}

  </div>
);

LangItem.defaultProps = {
  onRemove: undefined,
};

LangItem.propTypes = {
  language: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    iso: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }).isRequired,
  onRemove: PropTypes.func,
};

export default LangItem;
