import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import useSnippetHelper from '../../../../../../hooks/useSnippetHelper';

const SetHelpers = () => {
  const {
    values, isValid, dirty, submitForm, setFieldError, setFieldTouched,
    setFieldValue, handleReset, errors
  } = useFormikContext();

  const {
    onSetHelperValues,
    onSetHelperActions,
    onSetHelperDisable,
  } = useSnippetHelper();

  useEffect(() => {
    onSetHelperValues(values);

    return () => onSetHelperValues({});
  }, [values, onSetHelperValues]);

  useEffect(() => {
    onSetHelperActions({
      submitForm, setFieldError, setFieldValue, setFieldTouched, handleReset,
    });

    return () => onSetHelperActions({});
  },
  [
    submitForm, setFieldError, setFieldTouched, setFieldValue, handleReset, onSetHelperActions,
  ]);

  useEffect(() => {
    const isDisable = !(isValid && dirty);
    const errorsTxt = isDisable ? `${Object.values(errors)}` : null;
    onSetHelperDisable({errorsTxt, isDisable});

    return () => onSetHelperDisable({isDisable: true, errorsTxt: null});
  }, [isValid, dirty, errors, onSetHelperDisable]);

  return '';
};

export default SetHelpers;
