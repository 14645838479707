import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { isAuthorizedSelector } from '../services/selectors/auth';

const selector = createSelector(
  isAuthorizedSelector,
  (isAuthorized) => ({
    isAuthorized,
  }),
);

export const useRoutes = () => {
  const data = useSelector(selector);

  return {
    ...data,
  };
};
