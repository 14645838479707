export const headingConfig = {
  options: [
    { model: 'paragraph', title: 'Paragraph' },
    {
      model: 'paragraphGradText',
      view: {
        name: 'p',
        classes: 'grad_text',
        styles: { color: 'orange' },
      },
      title: 'Paragraph 2 (grad_text)',
      class: 'ck-paragraph_grad_text',
      converterPriority: 'high',
    },
    { model: 'heading1', view: { name: 'h1', styles: { 'font-size': '36px' } }, title: 'Heading 1' },
    {
      model: 'heading2', view: { name: 'h2', styles: { 'font-size': '24px' } }, title: 'Heading 2', class: 'ck-heading_heading2',
    },
    { model: 'heading3', view: { name: 'h3', styles: { 'font-size': '18px' } }, title: 'Heading 3' },
    { model: 'heading4', view: { name: 'h4', styles: { 'font-size': '12px' } }, title: 'Heading 4' },
  ],
};

export const fontSizeConfig = {
  options: [
    'default',
    { title: '8', model: '8px' },
    { title: '9', model: '9px' },
    { title: '10', model: '10px' },
    { title: '11', model: '11px' },
    { title: '12', model: '12px' },
    { title: '14', model: '14px' },
    { title: '18', model: '18px' },
    { title: '24', model: '24px' },
    { title: '30', model: '30px' },
    { title: '36', model: '36px' },
  ],
};

export const toolbarConfig = [
  'heading',
  '|',
  'fontsize',
  'fontColor',
  'style',
  'fontBackgroundColor',
  '|',
  'bold',
  'italic',
  'underline',
  'strikethrough',
  '|',
  'alignment',
  '|',
  'numberedList',
  'bulletedList',
  '|',
  'outdent',
  'indent',
  '|',
  'link',
  'blockquote',
  'insertTable',
  '|',
  'undo',
  'redo',
];

export const stylesConfig = [
  {
    name: 'Word with gradient',
    element: 'span',
    classes: ['grad_text'],
  },
];
