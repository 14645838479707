import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Subtitle from 'components/Subtitle/Subtitle';
import styles from './styles.module.scss';

const SnippetRow = ({
  className, first, second, third,
}) => {
  const secondEl = <div className={styles.second}>{second}</div>;
  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.first}>
        <Subtitle className={styles.title} title={first}>{first}</Subtitle>
        {second ? null : <div>{third}</div>}
      </div>
      <div className={cn(styles.container2)}>
        {second && secondEl}
        {second && third && <div>{third}</div> }
      </div>
    </div>
  );
};

SnippetRow.defaultProps = {
  className: '',
  first: '',
  second: '',
  third: '',
};

const asChildren = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.element,
  PropTypes.arrayOf(PropTypes.element),
]);

SnippetRow.propTypes = {
  className: PropTypes.string,
  first: asChildren,
  second: asChildren,
  third: asChildren,
};

export default SnippetRow;
