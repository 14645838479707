import { createSelector } from 'reselect';

export const websitesSelector = (state) => state.websites;

export const webIdSelector = (store, webId) => webId;

export const listSelector = createSelector(
  websitesSelector,
  (websites) => websites?.list || [],
);

export const filesSelector = createSelector(
  websitesSelector,
  (websites) => websites?.files || [],
);

export const websiteFilesSelector = createSelector(
  filesSelector,
  webIdSelector,
  (files, webId) => files[webId],
);

export const categoriesSelector = createSelector(
  websitesSelector,
  (websites) => websites?.categories || [],
);

export const currentWebsiteSelector = createSelector(
  listSelector,
  webIdSelector,
  (list, webId) => (list || []).find(({ id }) => id === webId),
);

export const languagesSelector = createSelector(
  websitesSelector,
  (websites) => websites?.languages || [],
);

export const domainSelector = createSelector(
  websitesSelector,
  (websites) => websites?.domain || [],
);
