import { SNIPPETS } from '../../constants/type';
import { sortSnippets, sortItemsCategories } from '../../utils/sortFunction';

export const defaultState = {
  list: [],
  snippet: undefined,
  categories: [],
  editorHelper: {
    values: undefined,
    actions: undefined,
    isDisable: false,
    errorsTxt: '',
  },
  key: '',
};

export default (state = defaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    /* SNIPPETS */
    case SNIPPETS.GET_LIST.SUCCESS: {
      return {
        ...state,
        list: payload.data.sort(sortSnippets),
      };
    }
    case SNIPPETS.GET_FULL.SUCCESS: {
      return {
        ...state,
        snippet: payload,
      };
    }
    case SNIPPETS.CREATE.SUCCESS: {
      return {
        ...state,
        snippet: payload,
        list: [
          ...state.list,
          payload,
        ].sort(sortSnippets),
      };
    }
    case SNIPPETS.EDIT.SUCCESS: {
      return {
        ...state,
        snippet: payload,
        list: state.list
          .map((snippet) => (snippet.id === payload.id ? payload : snippet)).sort(sortSnippets),
      };
    }
    case SNIPPETS.REMOVE.SUCCESS: {
      return {
        ...state,
        list: state.list.filter((snippet) => snippet.id !== payload.id),
      };
    }
    case SNIPPETS.GET_CATEGORIES.SUCCESS: {
      return {
        ...state,
        categories: payload.data.sort(sortItemsCategories),
      };
    }
    case SNIPPETS.CREATE_CATEGORY.SUCCESS: {
      return {
        ...state,
        categories: [
          ...state.categories,
          payload,
        ].sort(sortItemsCategories),
      };
    }
    case SNIPPETS.EDIT_CATEGORY.SUCCESS: {
      return {
        ...state,
        categories: state.categories
          .map((category) => (category.id === payload.id ? payload : category)).sort(sortItemsCategories),
      };
    }
    case SNIPPETS.REMOVE_CATEGORY.SUCCESS: {
      return {
        ...state,
        categories: state.categories
          .filter((category) => category.id !== payload.id),
      };
    }
    case SNIPPETS.SET_HELPER_VALUES.base: {
      return {
        ...state,
        editorHelper: {
          ...state.editorHelper,
          values: payload,
        },
      };
    }
    case SNIPPETS.SET_HELPER_ACTIONS.base: {
      return {
        ...state,
        editorHelper: {
          ...state.editorHelper,
          actions: payload,
        },
      };
    }
    case SNIPPETS.SET_HELPER_DISABLE.base: {
      return {
        ...state,
        editorHelper: {
          ...state.editorHelper,
          isDisable: payload.isDisable,
          errorsTxt: payload.errorsTxt,
        },
      };
    }
    case SNIPPETS.GET_KEY.SUCCESS: {
      return {
        ...state,
        key: payload.key,
      };
    }
    default:
      return state;
  }
};
