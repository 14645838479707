import React from 'react';
import DatePicker from 'react-datepicker';
import { SnippetWrap } from '../../../../../../components/SnippetProperties/SnippetWrap';
import Subtitle from '../../../../../../components/Subtitle/Subtitle';
import styles from './styles.module.scss';

const BlockDate = ({
  id, item: {
    name, values, props,
  }, language, onChange,
}) => {
  const handleChange = (date) => {
    const data = {
      id,
      language,
      value: (new Date(date).getTime() / 1000).toFixed(0),
      props: {
        offset: new Date().getTimezoneOffset(),
      },
    };

    onChange(data);
  };

  const value = values[language] ? new Date(values[language] * 1000) : new Date();

  return (
    <SnippetWrap>

      <Subtitle
        className={styles.title}
        title={name}
      >
        {name}
      </Subtitle>

      <div className={styles.wrap}>

        <DatePicker
          inline
          showPopperArrow={false}
          selected={value}
          onChange={handleChange}
          showTimeSelect={props?.no_date || (!props?.no_time && !props?.no_date)}
          showTimeSelectOnly={props?.no_date}
        />

      </div>

    </SnippetWrap>
  );
};

export default BlockDate;
