import React, { useMemo, useCallback } from 'react';
import SnippetRow from '../../../../../../components/SnippetProperties/SnippetWrap/components/SnippetRow';
import { SnippetWrap } from '../../../../../../components/SnippetProperties/SnippetWrap';
import Radio from './components/Radio';
import styles from './styles.module.scss';

const BlockRadio = ({
  id, item: {
    name, values, props, group,
  }, language, onChange,
}) => {
  const handleChange = useCallback((e) => {
    const { value } = e.target;

    const data = {
      id,
      group,
      language,
      value,
    };
    onChange(data);
  }, [id, group, language, onChange]);

  const choices = useMemo(() => Object.keys(props)
    .map((key) => (
      <Radio
        key={key}
        name={id}
        label={props[key]}
        onChange={handleChange}
        value={key}
        checked={values[language] === key}
      />
    )), [id, language, props, values, handleChange]);

  return (
    <SnippetWrap>

      <SnippetRow
        first={name}
        second={(
          <div className={styles.choices}>
            {choices.length > 0 && choices}
          </div>
        )}
      />

    </SnippetWrap>
  );
};

export default BlockRadio;
