import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Button } from '../../../../../../../../../../components/Button';
import Subtitle from '../../../../../../../../../../components/Subtitle/Subtitle';
import Select from '../../../../../../../../../../components/Select';
import { translate } from '../../../../../../../../../../utils/translate';
import { useWebsitesTab } from '../../../../../../../../../../hooks/useWebsitesTab';
import Pages from '../Pages';
import styles from './styles.module.scss';

const ModalForm = ({ info, onClose, onChange }) => {
  const { websites } = useWebsitesTab();

  const webOptions = useMemo(() => websites.map((website) => ({
    label: website.name,
    value: website.id,
  })), [websites]);

  let initVal;

  if (info) {
    const { site, page } = info;

    initVal = {
      site,
      page,
    };
  } else {
    initVal = {
      site: '',
      page: '',
    };
  }

  const schema = Yup.object().shape({
    site: Yup.number().required(),
    page: Yup.number().required(),
  });

  const handleSubmit = ({ page, site }) => {
    onChange({
      value: page,
      props: {
        mode: 'link',
        page,
        site,
      },
    });

    onClose();
  };

  return (
    <Formik
      initialValues={initVal}
      validationSchema={schema}
      onSubmit={handleSubmit}
      enableReinitialize
    >

      {({ values, setFieldValue }) => (
        <Form>

          <div className={styles.row}>

            <Subtitle className={styles.subtitle}>{translate('Website')}</Subtitle>

            <Select
              name="site"
              id="site"
              options={webOptions}
              onChange={(changeSelect) => {
                setFieldValue('page', '');

                changeSelect();
              }}
              placeholder={translate('Website')}
              dark
            />

          </div>

          {values.site && <Pages />}

          <Button type="submit">{translate('Submit')}</Button>

        </Form>
      )}

    </Formik>
  );
};

export default ModalForm;
