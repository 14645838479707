import { createSelector } from 'reselect';
import _get from 'lodash/get';

export default function createLoadingSelector(action) {
  return createSelector(
    (state) => state.loading,
    (loading) => _get(loading, action, {
      loading: false,
      loaded: false,
      error: false,
    }),
  );
}
