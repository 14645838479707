import React, { useMemo, forwardRef } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';
// import { ReactComponent as EditIcon } from '../../../../../../assets/icons/edit.svg';
import IMAGE from '../../../../../../assets/images/asset_img.jpeg';
// import ButtonSVG from '../../../../../../components/ButtonSVG';
import { dropTypes } from '../../../../../../utils/dropTypes';
import styles from './styles.module.scss';
import formatBytes from '../../../../../../utils/formatBytes';

/* eslint-disable react/jsx-props-no-spreading */
export const AssetItem = forwardRef(({
  label,
  placeholder,
  disabled,
  // editClick,
  handleChange,
  item,
  checked,
  style,
  ...otherProps
}, ref) => {
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: dropTypes.MEDIA,
      media: item,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;

  // eslint-disable-next-line no-unused-vars
  const getCheckbox = useMemo(() => (
    <div className={styles.radio_wrap}>
      <input
        id={label}
        type="checkbox"
        {...otherProps}
        ref={ref}
        placeholder={placeholder}
        className={cn(styles.radio_input)}
        disabled={disabled}
        checked={checked}
        onChange={handleChange}
      />
      <label htmlFor={label} className={styles.radio_label} />
    </div>
  ),
  [checked, label, ref, placeholder, disabled, otherProps, handleChange]);

  // const handleClick = () => editClick(item.relative_path);

  return (
    <div
      className={styles.assetItem_wrap}
      ref={drag}
      style={{
        opacity,
      }}
    >

      <div className={styles.assetItem_preview}>

        {/* <div className={styles.preview_checkbox}>
          {getCheckbox}
        </div> */}

        <div className={styles.preview_img}>
          <img src={item.preview || item.absolute_path || IMAGE} alt="asset" />
        </div>

        <div className={styles.preview_info}>

          <p className={styles.preview_name} title={`${item.name}.${item.extension}`}>

            {item.name}

            <span className={styles.preview_extension}>{`.${item.extension}`}</span>

          </p>

          <ul className={styles.preview_desc}>

            <li className={styles.preview_ratio}>{item.ratio}</li>

            <li className={styles.preview_size}>{formatBytes(item.size)}</li>

          </ul>

        </div>

      </div>

      {/* <ButtonSVG
        onClick={handleClick}
        className={styles.assetItem_edit}
      >
        <EditIcon width={16} height={16} />
      </ButtonSVG> */}

    </div>
  );
});

AssetItem.propTypes = {
  item: PropTypes.shape({
    relative_path: PropTypes.string,
    absolute_path: PropTypes.string,
    name: PropTypes.string.isRequired,
    extension: PropTypes.string.isRequired,
    // ratio: PropTypes.string,
    size: PropTypes.number,
  }).isRequired,
  // editClick: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
};
