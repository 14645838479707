import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import {
  HOME_ROUTE, PAGES_ROUTE, PAGE_EDITOR_ROUTE, SNIPPET_CREATE_ROUTE,
  SNIPPET_EDITOR_ROUTE, SNIPPETS_ROUTE, MEDIA_ROUTE, PAGES_NO_WEBSITE_ROUTE,
} from '../../constants/routes';
import { AppLayout } from '../../modules/layouts';
import EmptyPage from '../../modules/EmptyPage';
import PageEditor from '../../modules/PageEditor';
import SnippetEditor from '../../modules/SnippetEditor';

export const AppRoutes = () => (
  <AppLayout>
    <Switch>

      <Route
        exact
        path={HOME_ROUTE}
      >
        <EmptyPage title="Choose website or create new" />
      </Route>

      <Route
        exact
        path={PAGES_NO_WEBSITE_ROUTE}
      >
        <EmptyPage title="Choose website or create new" />
      </Route>

      <Route
        exact
        path={PAGES_ROUTE}
      >
        <EmptyPage title="Choose page or create new" />
      </Route>

      <Route
        exact
        path={PAGE_EDITOR_ROUTE}
        component={PageEditor}
      />

      <Route
        exact
        path={SNIPPETS_ROUTE}
      >
        <EmptyPage title="Choose snippet or create new" />
      </Route>

      <Route
        exact
        path={SNIPPET_CREATE_ROUTE}
        component={SnippetEditor}
      />

      <Route
        exact
        path={SNIPPET_EDITOR_ROUTE}
        component={SnippetEditor}
      />

      <Route
        exact
        path={MEDIA_ROUTE}
      >
        <EmptyPage title="Choose media or upload new" />
      </Route>

      <Route path="*">
        <Redirect to="/" />
      </Route>

    </Switch>
  </AppLayout>
);
