import { toast } from 'react-toastify';
import { createAction } from 'redux-actions';
import { PAGES } from '../../constants/type';
import { API_PAGE_URL } from '../../constants/api';
import history from '../../utils/history';
import appStore from '../../utils/store';
import { baseApi } from '../../utils/apiService';
import { translate } from '../../utils/translate';
import { requestWrapper } from '../../utils/requestWrapper';
import { wrapSimple, wrapPage, wrapGetPage } from '../../utils/actionWraps';
import { getList as getSnippetsAtPage, getSnippetProps } from './snippet-at-page';
import { URL_QUERY_PARAMS } from '../../constants/ui';

const getCurrentUi = () => appStore.getState().ui;

export const getList = async (id) => {
  const request = () => requestWrapper(
    PAGES.GET_LIST,
    `${API_PAGE_URL}?website=${id}`,
    'get',
    false,
    { id },
    true,
  );

  wrapSimple(request);
};

export const getListSearch = async (id, search, with_content, onSuccess, onFailure) => {
  const request = () => requestWrapper(
    PAGES.GET_LIST_SEARCH,
    `${API_PAGE_URL}?website=${id}&search=${search || ''}&with_content=${with_content ? 1 : 0}`,
    'get',
    false,
    { id },
    false,
    onSuccess,
    onFailure,
  );

  wrapSimple(request);
};

export const clearListSearch = () => {
  appStore.dispatch(createAction(PAGES.CLEAR_LIST_SEARCH.SUCCESS)());
};

export const getPageChildren = async (websiteId, parentId) => {
  const request = () => requestWrapper(
    PAGES.GET_CHILDREN,
    `${API_PAGE_URL}?website=${websiteId}&parent=${parentId}`,
    'get',
    false,
    {
      id: websiteId,
      parentId,
    },
    true,
  );

  wrapSimple(request);
};

export const getCurrentPage = (pageId, webId, actions) => {
  const request = () => requestWrapper(
    PAGES.GET_CURRENT_PAGE,
    `${API_PAGE_URL}${pageId}/`,
    'get',
    false,
    {
      pageId,
      webId,
    },
    true,
  );

  const { noParent } = appStore.getState().pages;

  if (!noParent[webId]) getList(webId);

  wrapGetPage(request, actions);
};

export const createPage = async (data, actions) => {
  let requestData = data;

  if (data.get) {
    requestData.append('key', baseApi.AddToken);
  } else {
    requestData = {
      ...requestData,
      key: baseApi.AddToken,
    };
  }

  const onSuccess = () => {
    if (actions?.onSuccess) actions.onSuccess();

    const parent = data.get ? data.get('parent') : data.parent;
    const webId = data.get ? data.get('website') : data.website;

    if (!parent) {
      getList(webId);
    } else {
      getPageChildren(webId, parent);
    }

    getSnippetsAtPage();
  };

  const extendActions = {
    ...actions,
    onSuccess,
  };

  const request = () => requestWrapper(
    PAGES.CREATE,
    API_PAGE_URL,
    'post',
    false,
    requestData,
    true,
  );

  wrapPage(request, extendActions);
};

export const editPage = async (data, actions) => {
  let requestData = data;

  if (data.get) {
    requestData.append('key', baseApi.AddToken);
  } else {
    requestData = {
      ...requestData,
      key: baseApi.AddToken,
    };
  }

  const id = data.get ? data.get('id') : data.id;

  const onSuccess = () => {
    const { search, pathname } = history.location;

    const params = new URLSearchParams(search);

    params.delete(URL_QUERY_PARAMS.SNIPPET_AT_PAGE);

    history.push(`${pathname}?${params.toString()}`);

    if (actions?.onSuccess) actions.onSuccess();

    const parent = data.get ? data.get('parent') : data.parent;
    const webId = data.get ? data.get('website') : data.website;

    const { noParent, byParent } = appStore.getState().pages;

    const pagesNoParent = noParent[webId] || [];
    const pagesByParent = byParent[webId] || [];
    const pages = [...pagesNoParent, ...pagesByParent];

    const currentPage = pages
      .find((page) => +page.id === +id);

    if (currentPage.parent === parent) {
      if (!parent) {
        getList(webId);
      } else {
        getPageChildren(webId, parent);
      }
    } else {
      if (!currentPage.parent) {
        getList(webId);
      } else {
        const currentParent = pages
          .find((page) => page.id === currentPage.parent);

        if (!currentParent.parent) {
          getList(webId);
        } else {
          getPageChildren(webId, currentParent.parent);
        }
      }

      if (!parent) {
        getList(webId);
      } else {
        getPageChildren(webId, parent);

        const currentParent = pages
          .find((page) => page.id === parent);

        if (!currentParent.parent) {
          getList(webId);
        } else {
          getPageChildren(webId, currentParent.parent);
        }
      }
    }

    getSnippetsAtPage();
  };

  const extendActions = {
    ...actions,
    onSuccess,
  };

  const request = () => requestWrapper(
    PAGES.EDIT,
    `${API_PAGE_URL}${id}/`,
    'put',
    false,
    requestData,
  );

  wrapPage(request, extendActions);
};

export const removePage = async (id, actions, webId) => {
  const onSuccess = () => {
    if (actions?.onSuccess) actions.onSuccess();

    const { noParent, byParent } = appStore.getState().pages;

    const pagesNoParent = noParent[webId] || [];
    const pagesByParent = byParent[webId] || [];
    const pages = [...pagesNoParent, ...pagesByParent];

    const currentPage = pages
      .find((page) => page.id === id);

    history.push(`/website/${currentPage.website}`);

    if (!currentPage.parent) {
      getList(webId);
    } else {
      getPageChildren(webId, currentPage.parent);

      const currentParent = pages
        .find((page) => page.id === currentPage.parent);

      if (!currentParent.parent) {
        getList(webId);
      } else {
        getPageChildren(webId, currentParent.parent);
      }
    }

    toast.success(translate('Page removed'));
  };

  const extendActions = {
    ...actions,
    onSuccess,
  };

  const request = () => requestWrapper(
    PAGES.REMOVE,
    `${API_PAGE_URL}${id}/`,
    'delete',
    false,
  );

  wrapPage(request, extendActions);
};

export const actionPage = async (requestData, type) => {
  const { id, webId, parent } = requestData;

  const onSuccess = () => {
    const ui = getCurrentUi();

    if (parent) {
      getPageChildren(webId, parent);
    } else {
      getList(webId);
    }

    getSnippetsAtPage();

    if (type === 'reset') {
      if (ui.selectedSnippetAtPageId) getSnippetProps(ui.selectedSnippetAtPageId);
    }
  };

  const request = () => requestWrapper(
    PAGES.ACTION,
    `${API_PAGE_URL}${id}/${type}/?key=${baseApi.AddToken}`,
    'post',
    false,
  );

  wrapPage(request, { onSuccess });
};
