import { applyMiddleware, compose } from 'redux';
// import logger from 'redux-logger';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import history from '../utils/history';
import { LEFT_MENU, UI_ACTIONS, URL_QUERY_PARAMS } from '../constants/ui';

const RE_SITE = /\/website\/(\d+)/g;
const RE_PAGE = /\/page\/(\d+)/g;
const RE_SNIPPET = /\/snippet\/(\d+)/g;

function getId(pathname, re) {
  let strId = pathname.match(re);
  if (strId?.[0]) {
    strId = strId[0].replace(/[^0-9]/g, '');
    return strId;
  }
  return false;
}

function parseLocation() {
  return (next) => (action) => {
    const returnValue = next(action);
    if (action.type === '@@router/LOCATION_CHANGE') {
      const { pathname, search } = action.payload.location;
      const url = pathname + search;
      const siteId = getId(url, RE_SITE);
      const pageId = getId(url, RE_PAGE);
      const snippetId = getId(url, RE_SNIPPET);
      const newAction = {
        type: UI_ACTIONS.PARSE_PATH,
        payload: { siteId, pageId, snippetId },
      };
      const query = new URLSearchParams(search);
      const snippetAtPage = query.get(URL_QUERY_PARAMS.SNIPPET_AT_PAGE);

      const actionSnippetAtPage = {
        type: UI_ACTIONS.SELECTED_SNIPPET_AT_PAGE,
        payload: snippetAtPage || undefined,
      };
      next(actionSnippetAtPage);

      const leftBar = query.get(URL_QUERY_PARAMS.LEFT_BAR);
      if (leftBar !== null) {
        const newAction2 = {
          type: UI_ACTIONS.SELECT_LEFT_MENU,
          payload: leftBar.toUpperCase(),
        };
        next(newAction2);
      } else if (RE_SNIPPET.test(pathname)) {
        const newAction3 = {
          type: UI_ACTIONS.SELECT_LEFT_MENU,
          payload: LEFT_MENU.SNIPPET_EDITOR,
        };
        next(newAction3);
      }
      return next(newAction);
    }
    return returnValue;
  };
}

const middleware = compose(
  applyMiddleware(
    // logger,
    thunk,
    routerMiddleware(history),
    parseLocation,
  ),
  /* eslint-disable */
  window && window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (devTools) => devTools,
);

export default middleware;
