import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import Modal from '../../components/Modal/Modal';
import { splitLanguages } from '../../utils/convLang';
import { promptOptions } from '../../utils/promptOptions';
import { translate } from '../../utils/translate';
import useTemplate from '../../hooks/useTemplate';
import ContentModal from './components/ContentModal';

const ModalTemplate = ({
  pageId, show, onClose, onCloseModal,
}) => {
  const isEdit = typeof show === 'number';

  const {
    template,
    languages,
    onCreate,
    onEdit,
    onRemove,
  } = useTemplate(show);

  let initValue = {
    id: null,
    name: '',
    description: '',
  };

  const schema = Yup.object().shape({
    id: Yup.number().nullable(),
    name: Yup.string()
      .min(2)
      .required(),
    description: Yup.string(),
    seo_title: Yup.string(),
    seo_keywords: Yup.string(),
    seo_description: Yup.string(),
    website: Yup.string(),
    languages: Yup.string(),
  });

  if (isEdit && template) {
    const {
      description, seo_title, seo_keywords, seo_description,
    } = template;

    initValue = {
      ...template,
      description: description || '',
      seo_title: seo_title || '',
      seo_keywords: seo_keywords || '',
      seo_description: seo_description || '',
      languages: splitLanguages(template.languages),
    };
  }

  const onSubmit = (values) => {
    const data = {
      pageId,
      ...values,
    };

    const action = isEdit ? onEdit : onCreate;

    const onSuccess = () => {
      onClose();

      if (onCloseModal) onCloseModal();

      if (isEdit) {
        toast.success(translate('Template is edited'));
      } else {
        toast.success(translate('Template is created'));
      }
    };

    action(data, { onSuccess });
  };

  const handleRemove = useCallback(
    () => confirmAlert(promptOptions({
      buttons: [
        {
          label: 'No',
        },
        {
          label: 'Yes',
          color: 'danger',
          onClick: () => onRemove(show, { onSuccess: onClose }),
        },
      ],
    })),
    [show, onRemove, onClose],
  );

  const content = useMemo(() => (
    <ContentModal
      isEdit={isEdit}
      languages={languages}
      onClose={onClose}
      onRemove={handleRemove}
    />
  ), [languages, isEdit, onClose, handleRemove]);

  return (
    <Modal show={Boolean(show)} onClose={onClose}>

      <Formik
        initialValues={initValue}
        validationSchema={schema}
        onSubmit={onSubmit}
        enableReinitialize
      >

        <Form noValidate>

          {content}

        </Form>

      </Formik>

    </Modal>
  );
};

ModalTemplate.defaultProps = {
  show: false,
};

ModalTemplate.propTypes = {
  show: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  onClose: PropTypes.func.isRequired,
};

export default ModalTemplate;
