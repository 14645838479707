import React, { useEffect, useMemo } from 'react';
import cn from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import { ReactComponent as ChevronRight } from '../../../../../../../assets/icons/chevron-right.svg';
import { Accordion } from '../../../../../../Accordion';
import { AccordionHeader } from '../../../../../../Accordion/components/Header';
import { useMediaTab } from '../../../../../../../hooks/useMediaTab';
import styles from '../../styles.module.scss';
import AsyncContent from '../../../../../../Accordion/components/AsyncContent';

const MediaSelectModal = ({
  divRef, currentCategory, value, onSelect, onClose,
}) => {
  const element = document.createElement('div');

  const { current } = divRef;

  const {
    categories,
    onOpenAccordion,
  } = useMediaTab();

  useEffect(() => {
    current.appendChild(element);

    return () => {
      current.removeChild(element);
    };
  });

  const items = useMemo(() => {
    const getStructure = (array, parent = null) => array
      .filter((category) => category.parent === parent)
      .map((category) => {
        const childCategories = getStructure(array, category.relative_path);

        const handleSelect = () => onSelect(category.relative_path);

        return {
          id: category.relative_path,
          category,
          header: (
            <AccordionHeader
              className={cn(
                styles.container,
                { [styles.active]: category.relative_path === value },
              )}
              hasChildren={category.has_child}
              title={(
                <div onClick={handleSelect}>
                  {category.name}
                </div>
              )}
              leftIcons={<ChevronRight width={24} height={24} style={{ fill: category.has_child ? undefined : '#5E5E5D' }} />}
            />
          ),
          content: childCategories.length > 0 || !category.has_child ? childCategories : <AsyncContent />,
        };
      });

    const filterCategories = currentCategory && currentCategory.relative_path
      ? categories.filter((category) => category.relative_path !== currentCategory.relative_path)
      : categories;

    return getStructure(filterCategories);
  }, [categories, currentCategory, value, onSelect]);

  const handleClose = (e) => {
    e.stopPropagation();

    onClose();
  };

  return (
    <div className={styles.modal}>

      <OutsideClickHandler
        onOutsideClick={handleClose}
      >

        { items.length > 0 && items.map((item) => (
          <Accordion
            key={item.id}
            item={item}
            withBorder={false}
            onOpen={(path) => onOpenAccordion(path)}
          />
        )) }

      </OutsideClickHandler>

    </div>
  );
};

export default MediaSelectModal;
