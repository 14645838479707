import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { getTemplates } from '../services/actions/templates';
import { listSelector } from '../services/selectors/templates';
import createLoadingSelector from '../services/selectors/loading';
import { TEMPLATES } from '../constants/type';

const selector = createSelector(
  listSelector,
  createLoadingSelector(TEMPLATES.GET_LIST.base),
  (templates, templatesLoader) => ({
    templates,
    templatesLoader,
  }),
);

const useTemplates = () => {
  const data = useSelector(selector);

  useEffect(() => {
    const { loading, loaded, error } = data.templatesLoader;

    if (!loading && !loaded && !error) {
      getTemplates();
    }
  }, [data.templates, data.templatesLoader]);

  return {
    ...data,
  };
};

export default useTemplates;
