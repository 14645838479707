import { createSelector } from 'reselect';

export const mediaSelector = (state) => state.media;

export const listSelector = createSelector(
  mediaSelector,
  (media) => media.list,
);

export const categoriesSelector = createSelector(
  mediaSelector,
  (media) => media.categories,
);

export const categoryIdSelector = (store, categoryId) => categoryId;

export const currentCategorySelector = createSelector(
  categoriesSelector,
  categoryIdSelector,
  (categories, categoryId) => categories.find(({ relative_path }) => relative_path === categoryId),
);
