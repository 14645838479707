import React from 'react';
import PropTypes from 'prop-types';
import AssetFile from '../AssetFile';

const AssetForm = ({ items, onDelete }) => (
  <div>
    { items.map((item) => (
      <AssetFile key={item.id} item={item} onDelete={onDelete} />
    )) }
  </div>
);

AssetForm.propTypes = {
  onDelete: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    extension: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
  })).isRequired,
};

export default AssetForm;
