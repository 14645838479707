import React from 'react';
import slugify from 'slugify';
import { useField } from 'formik';
import { Input, InputUnControlled } from '../../../../components/Input';
import { translate } from '../../../../utils/translate';
import styles from './styles.module.scss';

/* eslint-disable react/jsx-props-no-spreading */
const PageInputSlug = ({ editingSlug, setEditingSlug }) => {
  const [field,, helpers] = useField('slug');

  const handleChange = (e) => {
    const currentValue = slugify(e.target.value, {
      replacement: '-',
      remove: /[*+~.()'"!:@]/g,
      lower: true,
      strict: true,
    });

    helpers.setValue(currentValue);
  };

  const onCheck = () => setEditingSlug((value) => !value);

  return (
    <div className={styles.container}>

      <Input
        type="text"
        id="slug"
        {...field}
        onChange={handleChange}
        placeholder={translate('Slug')}
        disabled={editingSlug}
        styleType="2"
      />

      <div className={styles.wrap}>

        <InputUnControlled
          id="editing-slug"
          type="checkbox"
          checked={editingSlug}
          onChange={onCheck}
          className={styles.checkbox}
        />

      </div>

    </div>
  );
};

export default PageInputSlug;
