import React from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { LEFT_MENU } from 'constants/ui';
import { dropTypes } from '../../../utils/dropTypes';
import useLeftSidebar from '../../../hooks/useLeftSidebar';
import { selectedSnippetIdSelector } from '../../../services/selectors/ui';
import styles from './styles.module.scss';

const NavLinks = ({ links, children }) => {
  const onDrop = useLeftSidebar();

  const selectedSnippetId = useSelector(selectedSnippetIdSelector);

  const {
    WEBSITE, WEBSITE_DIRECTORY, PAGE, SNIPPET, SNIPPET_CONTAINER,
    SNIPPET_DIRECTORY, MEDIA, MEDIA_DIRECTORY,
  } = dropTypes;

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [
      WEBSITE, WEBSITE_DIRECTORY, PAGE, SNIPPET, SNIPPET_CONTAINER,
      SNIPPET_DIRECTORY, MEDIA, MEDIA_DIRECTORY,
    ],
    drop(dragItem, monitor) {
      if (!monitor.didDrop()) onDrop(dragItem);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const backgroundColor = (canDrop && isOver && 'rgba(0,0,0,.1)')
    || (canDrop && 'rgba(0,0,0,.2)')
    || 'transparent';

  return (
    <div
      ref={drop}
      className={styles.navbar_wrap}
      style={{ backgroundColor }}
    >

      <div className={styles.navbar_list}>

        { links.length > 0 && links.map((wrap, index) => (
          <div
            key={index}
            className={cn(styles.navbar_list_wrap)}
          >
            { wrap.map(({ id, icon: Icon, title }) => (
              <Icon
                key={id}
                id={id}
                className={cn(
                  styles.navbar_item,
                  id === LEFT_MENU.SNIPPET_EDITOR
                  && !selectedSnippetId
                  && styles.navbar_item__disable,
                )}
                activeClassName={styles.navbar_item__active}
                title={title}
              />
            )) }
          </div>
        )) }

      </div>

      <div className={styles.navbar_content}>

        {children}

      </div>

    </div>
  );
};

NavLinks.propTypes = {
  links: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    icon: PropTypes.func.isRequired,
  }))).isRequired,
  children: PropTypes.node.isRequired,
};

export default NavLinks;
