import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import {
  listSelector, editorHelperValuesSelector, editorHelperActionsSelector,
  editorHelperDisableSelector, categoriesSelector,
} from '../services/selectors/snippets';
import {
  getList, createSnippet, editSnippet, removeSnippet, createCategory,
  editCategory, removeCategory, getCategories,
} from '../services/actions/snippets';
import createLoadingSelector from '../services/selectors/loading';
import { SNIPPETS } from '../constants/type';

const selector = createSelector(
  listSelector,
  createLoadingSelector(SNIPPETS.GET_LIST.base),
  categoriesSelector,
  createLoadingSelector(SNIPPETS.GET_CATEGORIES.base),
  editorHelperValuesSelector,
  editorHelperActionsSelector,
  editorHelperDisableSelector,
  (snippets, snippetsLoader, categories, categoriesLoader) => ({
    snippets,
    snippetsLoader,
    categories,
    categoriesLoader,
  }),
);

export const useSnippets = () => {
  const data = useSelector(selector);

  const onCreate = createSnippet;
  const onEdit = editSnippet;
  const onRemove = removeSnippet;
  const onCreateCategory = createCategory;
  const onEditCategory = editCategory;
  const onRemoveCategory = removeCategory;

  useEffect(() => {
    const { loading, loaded, error } = data.snippetsLoader;

    if (!loading && !loaded && !error) getList();
  }, [data.snippetsLoader]);

  useEffect(() => {
    const { loading, loaded, error } = data.categoriesLoader;

    if (!loading && !loaded && !error) getCategories();
  }, [data.categoriesLoader]);

  return {
    ...data,
    onCreate,
    onEdit,
    onRemove,
    onCreateCategory,
    onEditCategory,
    onRemoveCategory,
  };
};
