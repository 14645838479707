import React, { useMemo, useCallback } from 'react';
import cn from 'classnames';
import { confirmAlert } from 'react-confirm-alert';
import { ReactComponent as Lock } from '../../../../assets/icons/lock.svg';
import { ReactComponent as Edit } from '../../../../assets/icons/edit.svg';
import { ReactComponent as Undo } from '../../../../assets/icons/undo.svg';
import { ReactComponent as World } from '../../../../assets/icons/world.svg';
import { ReactComponent as Upload } from '../../../../assets/icons/upload.svg';
import { ReactComponent as PageEdit } from '../../../../assets/icons/page-edit.svg';
import { ReactComponent as RadioChecked } from '../../../../assets/icons/radio_checked.svg';
import Panel from '../../../../components/Panel';
import ButtonSVG from '../../../../components/ButtonSVG';
import { promptOptions } from '../../../../utils/promptOptions';
import { translate } from '../../../../utils/translate';
import { usePage } from '../../../../hooks/usePage';
import usePageStatus from '../../../../hooks/usePageStatus';
import styles from './styles.module.scss';

const HeaderPanel = ({ webId, pageId }) => {
  const { currentPage } = usePage(pageId, webId);

  const {
    onPublish,
    onUnpublish,
    onReset,
  } = usePageStatus();

  const data = {
    id: currentPage?.id,
    parent: currentPage?.parent,
    webId: currentPage?.website,
  };

  const handlePublish = useCallback(() => onPublish(data), [data, onPublish]);

  const handleUnpublish = useCallback(() => confirmAlert(promptOptions({
    buttons: [
      {
        label: 'No',
      },
      {
        label: 'Yes',
        color: 'danger',
        onClick: () => onUnpublish(data),
      },
    ],
  })), [data, onUnpublish]);

  const handleReset = useCallback(() => confirmAlert(promptOptions({
    buttons: [
      {
        label: 'No',
      },
      {
        label: 'Yes',
        color: 'danger',
        onClick: () => onReset(data),
      },
    ],
  })), [data, onReset]);

  const status = useMemo(() => {
    if (currentPage && currentPage.enable && currentPage.was_sync) {
      return (
        <div
          className={cn(styles.button, styles.status_info)}
        >
          <RadioChecked width={16} height={16} />
          {translate('Published')}
        </div>
      );
    }

    if (currentPage && !currentPage.enable && currentPage.was_sync) {
      return (
        <div
          className={styles.button}
        >
          <Lock width={16} height={16} />
          {translate('Not Published')}
        </div>
      );
    }

    return (
      <div
        className={cn(styles.button, styles.status_danger)}
      >
        <Edit width={16} height={16} />
        {translate('Edited')}
      </div>
    );
  }, [currentPage]);

  const actions = useMemo(() => {
    const was_sync = currentPage && currentPage.was_sync;
    const enable = currentPage && currentPage.enable;

    const publishButton = (
      <ButtonSVG
        className={styles.button}
        onClick={handlePublish}
      >
        <Upload />
        {translate('Public')}
      </ButtonSVG>
    );

    const unpublishButton = (
      <ButtonSVG
        className={styles.button}
        onClick={handleUnpublish}
      >
        <Upload />
        {translate('Unpublic')}
      </ButtonSVG>
    );

    const resetButton = (
      <ButtonSVG
        className={styles.button}
        onClick={handleReset}
      >
        <Undo />
        {translate('Reset changes')}
      </ButtonSVG>
    );

    return (
      <div className={styles.wrap}>
        {(!enable || !was_sync) && publishButton}
        {enable && was_sync && unpublishButton}
        {!was_sync && resetButton}
      </div>
    );
  }, [currentPage, handlePublish, handleUnpublish, handleReset]);

  /*
  is_public=T & is_sync=T - опубликовано и нет неопубликованных изменений
  is_public=T & is_sync=F - опубликовано и есть неопубликованные изменения
  is_public=F - не опубликовано
  */

  const publicStatus = useMemo(() => (currentPage?.enable ? (
    <div
      className={styles.button}
    >
      <World width={16} height={16} />
      {translate('Public')}
    </div>
  ) : (
    <div
      className={styles.button}
    >
      <PageEdit width={16} height={16} />
      {translate('Draft')}
    </div>
  )), [currentPage]);

  return (
    <Panel className={cn(styles.container, 'header')}>

      <div className={styles.wrap}>

        {publicStatus}

      </div>

      <div className={styles.wrap}>

        {status}

      </div>

      {actions}

    </Panel>
  );
};

export default HeaderPanel;
