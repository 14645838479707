import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { Tabs, TabPanel } from 'react-tabs';
import { ReactComponent as Save } from '../../../../assets/icons/save.svg';
import { ReactComponent as Trash } from '../../../../assets/icons/trash.svg';
import ModalTabList from '../../../../components/Modal/componets/ModalTabList';
import ModalContent from '../../../../components/Modal/componets/ModalContent';
import ModalHeader from '../../../../components/Modal/componets/ModalHeader';
import { Button } from '../../../../components/Button';
import ModalTab from '../../../../components/Modal/componets/ModalTab';
import { translate } from '../../../../utils/translate';
import { TEMPLATES } from '../../../../constants/type';
import createLoadingSelector from '../../../../services/selectors/loading';
import General from '../General';
import Props from '../Props';
import styles from './styles.module.scss';

const selector = createSelector(
  createLoadingSelector(TEMPLATES.CREATE.base),
  createLoadingSelector(TEMPLATES.EDIT.base),
  (createLoader, editLoader) => createLoader.loading || editLoader.loading,
);

const ContentModal = ({
  languages, isEdit, onClose, onRemove,
}) => {
  const loading = useSelector(selector);

  const general = useMemo(() => (
    <General />
  ), []);

  const props = useMemo(() => (
    <Props languages={languages} />
  ), [languages]);

  let tabs = [
    {
      title: translate('General'),
      content: general,
    },
  ];

  if (isEdit) {
    tabs = [
      ...tabs,
      {
        title: translate('Props'),
        content: props,
      },
    ];
  }

  return (
    <Tabs className={styles.tabs}>

      <ModalHeader
        title={translate(`${isEdit ? 'Edit' : 'Create'} template`)}
        onClose={onClose}
      >

        <div className={styles.wrap}>

          {ModalTabList({
            children: tabs.map(({ title }) => ModalTab({
              key: title,
              children: title,
            })),
          })}

          <div className={styles.buttons}>

            { isEdit && (
              <Button
                color="danger"
                className={styles.button}
                onClick={onRemove}
              >
                <Trash width={24} height={24} />
                {translate('Remove')}
              </Button>
            ) }

            <Button
              type="submit"
              className={styles.button}
              loading={loading}
            >
              <Save width={24} height={24} />
              {translate('Save')}
            </Button>

          </div>

        </div>

      </ModalHeader>

      <ModalContent>

        {tabs.map(({ title, content }) => (
          <TabPanel
            key={title}
          >
            {content}
          </TabPanel>
        ))}

      </ModalContent>

    </Tabs>
  );
};

ContentModal.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ContentModal;
