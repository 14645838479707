import React from 'react';
import Modal from '../../../../../../components/Modal/Modal';
import ModalHeader
  from '../../../../../../components/Modal/componets/ModalHeader';
import { translate } from '../../../../../../utils/translate';
import ModalContent
  from '../../../../../../components/Modal/componets/ModalContent';
import DirectoryForm from './DirectoryForm';
import styles from './styles.module.scss';

const DirectoryModal = ({ show, onClose, onSelect }) => (
  <Modal
    show={Boolean(show)}
    onClose={onClose}
    classNameContainer={styles.container}
  >

    <ModalHeader
      title={translate('Chose directory media')}
      onClose={onClose}
    />

    <ModalContent
      tag="div"
      className={styles.content}
    >

      <DirectoryForm
        onClose={onClose}
        onSelect={onSelect}
      />

    </ModalContent>

  </Modal>
);

export default DirectoryModal;
