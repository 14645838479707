import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Settings } from '../../../../../../assets/icons/settings.svg';
import { ReactComponent as Snippet } from '../../../../../../assets/icons/snippet.svg';
import { ReactComponent as Scheme } from '../../../../../../assets/icons/scheme.svg';
import { ReactComponent as Trash } from '../../../../../../assets/icons/trash.svg';
import ButtonSVG from '../../../../../../components/ButtonSVG';
import { translate } from '../../../../../../utils/translate';
import styles from './styles.module.scss';

const SnippetItem = ({
  item: { id, order }, snippet: { name, is_container }, onClick, onConfigure, onRemove,
}) => {
  const handleClick = () => onClick(id);

  return (
    <div className={styles.container}>

      <div
        className={styles.item}
        onClick={handleClick}
      >
        {order})

        <ButtonSVG className={styles.button}>
          {is_container
            ? <Scheme width={16} height={16} />
            : <Snippet width={16} height={16} />}
        </ButtonSVG>

        <span
          className={styles.title}
          title={name}
        >
          {name}
        </span>

      </div>

      <ButtonSVG
        id={id}
        className={styles.button}
        title={translate('Edit snippet')}
        onClick={onConfigure}
      >
        <Settings />
      </ButtonSVG>

      <ButtonSVG
        id={id}
        className={styles.button}
        title={translate('Remove snippet')}
        onClick={onRemove}
      >
        <Trash />
      </ButtonSVG>

    </div>
  );
};

SnippetItem.defaultProps = {
  onClick: () => {},
};

SnippetItem.propTypes = {
  onClick: PropTypes.func,
  onConfigure: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default SnippetItem;
