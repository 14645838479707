import { createSelector } from 'reselect';

/* SNIPPETS */
export const snippetsSelector = (state) => state.snippets;

export const listSelector = createSelector(
  snippetsSelector,
  (snippets) => snippets.list,
);

export const categoriesSelector = createSelector(
  snippetsSelector,
  (snippets) => snippets.categories,
);

export const snippetIdSelector = (state, snippetId) => snippetId;

export const snippetSelector = createSelector(
  snippetsSelector,
  (snippets) => snippets.snippet,
);

export const editorHelperSelector = createSelector(
  snippetsSelector,
  (snippets) => snippets.editorHelper,
);

export const editorHelperValuesSelector = createSelector(
  editorHelperSelector,
  (editorHelper) => editorHelper.values,
);

export const editorHelperActionsSelector = createSelector(
  editorHelperSelector,
  (editorHelper) => editorHelper.actions,
);

export const editorHelperDisableSelector = createSelector(
  editorHelperSelector,
  (editorHelper) => editorHelper.isDisable,
);

export const editorHelperTxtDisableSelector = createSelector(
  editorHelperSelector,
  (editorHelper) => editorHelper.errorsTxt,
);

export const keySelector = createSelector(
  snippetsSelector,
  (snippets) => snippets.key,
);
