import { AUTH } from '../../constants/type';

export const defaultState = {
  isFetching: false,
  isAuthorized: false,
};

export default (state = defaultState, action) => {
  const { type } = action;

  switch (type) {
    case AUTH.SIGN_IN.REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case AUTH.SIGN_IN.SUCCESS: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case AUTH.SIGN_IN.FAILURE: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case AUTH.SIGN_IN.base: {
      return {
        ...state,
        isAuthorized: true,
      };
    }
    case AUTH.SIGN_OUT.base: {
      return {
        ...state,
        isAuthorized: false,
      };
    }
    default:
      return state;
  }
};
