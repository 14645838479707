import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg';
import { Button } from '../../../Button';
import { translate } from '../../../../utils/translate';
import style from './styles.module.scss';

export const PreviewTooltip = ({
  id,
  idFor,
  title,
  handleAddSnippet,
  description,
  previewImg,
  delay,
  place,
}) => {
  const onAddSnippet = () => handleAddSnippet(id);

  return (
    <ReactTooltip
      className={style.tooltip}
      id={idFor}
      place={place}
      effect="solid"
      globalEventOff="click"
      delayHide={delay}
      clickable
    >
      <div className={style.tooltip_innreWrap}>
        <header className={style.tooltip_header}>
          <p className={style.tooltip_text}>{title}</p>
          {/*<span
            onClick={handleClose}
            className={style.tooltip_close}
          >
            <CloseIcon />
          </span>*/}
        </header>
        <div className={style.tooltip_preview}>
          {previewImg ? <img src={previewImg} alt={title} /> : <span>{translate('No Preview')}</span>}
        </div>
        <p className={style.tooltip_description}>
          {description}
        </p>
        { handleAddSnippet && (
          <Button
            onClick={onAddSnippet}
            type="button"
          >
            <PlusIcon width={14} height={14} />
            {translate('Paste on Page')}
          </Button>
        ) }
      </div>
    </ReactTooltip>
  );
};

PreviewTooltip.defaultProps = {
  delay: 300,
  title: translate('Picture top Text under'),
  description: translate('Use this snippet when you want to show picture and description'),
  previewImg: '',
  place: 'right',
  handleAddSnippet: undefined,
};

PreviewTooltip.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  title: PropTypes.string,
  handleAddSnippet: PropTypes.func,
  description: PropTypes.string,
  previewImg: PropTypes.string,
  delay: PropTypes.number,
  place: PropTypes.string,
};
