import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  getList,
} from '../services/actions/redirects';
import { websiteRedirectsSelector } from '../services/selectors/redirects';

export const useRedirects = (wId) => {
  const selector = useCallback((store) => websiteRedirectsSelector(store, wId), [wId]);

  const redirectList = useSelector((store) => selector(store));

  useEffect(() => {
    if (typeof wId === 'number' && !redirectList) getList(wId);
  }, [wId, redirectList]);

  return {
    redirectList: redirectList || [],
  };
};
