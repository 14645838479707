const getNestNameDirectory = (array, excludeCategoryId) => {
  const excludeArray = [];
  const maxRecursiveCount = 100;
  let currentRecursiveCount = 0;

  const exclude = (id) => {
    array
      .filter((item) => item.parent === id)
      .map((item) => {
        excludeArray.push(item.id);
        exclude(item.id);
        return '';
      });
  };

  if (excludeCategoryId) {
    excludeArray.push(excludeCategoryId);
    exclude(excludeCategoryId);
  }

  const getParentsName = (parent) => {
    currentRecursiveCount += 1;

    if (currentRecursiveCount < maxRecursiveCount) {
      try {
        const currentCategory = array.find((item) => Number(item.id) === Number(parent));
        return (currentCategory.parent ? `${getParentsName(currentCategory.parent)}/` : '') + currentCategory.name;
      } catch (error) {
        return '';
      }
    } else {
      currentRecursiveCount = 0;
    }

    return '';
  };

  return array.filter((item) => !excludeArray.includes(item.id))
    .map(({ id: categoryId, name, parent }) => ({
      label: (parent ? `${getParentsName(parent)}/` : '') + name,
      value: categoryId,
    }));
};

export default getNestNameDirectory;
