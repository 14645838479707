import React, {
  useState, useCallback, useMemo, useRef, useEffect,
} from 'react';
import ReactCrop from 'react-image-crop';
// import { ReactComponent as Crop } from '../../../../../assets/icons/crop.svg';
import { ReactComponent as Save } from '../../../../../assets/icons/save.svg';
import ButtonSVG from '../../../../ButtonSVG';
import ModalHeader from '../../../../Modal/componets/ModalHeader';
import ModalContent from '../../../../Modal/componets/ModalContent';
import styles from './styles.module.scss';

const CropContent = ({
  title, src, props, onClose, onEdit,
}) => {
  const imgRef = useRef(null);

  const [crop, setCrop] = useState({});

  const [maxParams, setMaxParams] = useState({});

  const [isFirst, setIsFirst] = useState(true);

  const params = useMemo(() => {
    const {
      d, w, h, maxw, maxh, fill,
    } = props;

    const arrayAspect = (d || '3x4').split('x');

    return {
      aspect: {
        isAuto: !d,
        directly: arrayAspect[0] / arrayAspect[1],
        reversibly: arrayAspect[1] / arrayAspect[0],
      },
      w: w && parseInt(w, 10),
      h: h && parseInt(h, 10),
      maxw: maxw && parseInt(maxw, 10),
      maxh: maxh && parseInt(maxh, 10),
      fill: typeof fill === 'boolean' ? fill : true,
    };
  }, [props]);

  const handleEdit = useCallback(() => {
    const {
      unit, x, y, width, height,
    } = crop;

    let data;

    if (unit === '%') {
      const w1p = (imgRef.current.naturalWidth / 100);
      const h1p = (imgRef.current.naturalHeight / 100);

      const cw = Math.round(w1p * width);
      const ch = Math.round(h1p * height);
      const left = Math.round(w1p * x);
      const top = Math.round(h1p * y);

      data = {
        top,
        left,
        cw,
        ch,
      };
    } else {
      data = {
        top: y,
        left: x,
        cw: width,
        ch: height,
      };
    }

    onEdit(data);

    onClose();
  }, [crop, onEdit, onClose]);

  const absoluteToPercent = useCallback(({
    w, h, aspect, img,
  }) => {
    const width = w && (
      img.naturalWidth < w
        ? 100
        : 100 - ((img.naturalWidth - w) / img.naturalWidth) * 100
    );

    const height = h && (
      img.naturalHeight < h
        ? 100
        : 100 - ((img.naturalHeight - h) / img.naturalHeight) * 100
    );

    const initCrop = {
      unit: '%',
      start: true,
      x: 0,
      y: 0,
    };

    if (aspect) initCrop.aspect = aspect;
    if (width) initCrop.width = width;
    if (height) initCrop.height = height;

    setCrop(() => initCrop);
  }, []);

  useEffect(() => {
    if (crop.width && crop.height && crop.x === 0 && crop.y === 0 && isFirst) {
      setCrop({
        ...crop,
        x: (100 - crop.width) / 2,
        y: (100 - crop.height) / 2,
      });

      setIsFirst(false);
    }
  }, [crop, isFirst]);

  const onLoaded = useCallback((img) => {
    imgRef.current = img;

    const {
      aspect: { isAuto, directly }, w, h, maxw, maxh, // fill,
    } = params;

    const aspect = !isAuto && directly;

    if (img) {
      const scaleW = img.width / img.naturalWidth;
      const scaleH = img.height / img.naturalHeight;

      if (maxw || maxh) {
        setMaxParams({
          width: maxw && maxw * scaleW,
          height: maxh && maxh * scaleH,
        });
      }

      if ((w || maxw) && (h || maxh)) {
        if (w && h) {
          absoluteToPercent({ w, h, img });
        } else if (w && maxh) {
          absoluteToPercent({ w, img, aspect });
        } else if (maxw && h) {
          absoluteToPercent({ h, img, aspect });
        } else if (maxw && maxh) {
          absoluteToPercent({ w: maxw, h: maxh, img });
        }
      } else if (w) {
        absoluteToPercent({ w, img, aspect });
      } else if (h) {
        absoluteToPercent({ h, img, aspect });
      } else if (maxw) {
        absoluteToPercent({ w: maxw, img, aspect });
      } else if (maxh) {
        absoluteToPercent({ h: maxh, img, aspect });
      } else {
        absoluteToPercent({
          w: img.naturalWidth, h: img.naturalHeight, img, aspect,
        });
      }

      return false;
    }

    return true;
  }, [params, absoluteToPercent]);

  const handleChange = useCallback((_, percentCrop) => setCrop(percentCrop), []);

  const locked = useMemo(() => {
    if (!(params.w && params.h)) return false;

    return !(maxParams.width || maxParams.height);
  }, [params, maxParams]);

  return (
    <>
      <ModalHeader
        title={title}
        onClose={onClose}
      >

        <div className={styles.container}>

          <div>

            {/* <ButtonSVG
              className={styles.button}
              onClick={handleEdit}
            >
              <Crop width={24} height={24} />
            </ButtonSVG> */}

          </div>

          <div>

            <ButtonSVG
              className={styles.button}
              onClick={handleEdit}
            >
              <Save width={24} height={24} />
            </ButtonSVG>

          </div>

        </div>

      </ModalHeader>

      <ModalContent
        className={styles.content}
      >

        <div className={styles.wrap}>

          <ReactCrop
            src={src}
            locked={locked}
            crop={crop}
            onChange={handleChange}
            maxWidth={maxParams.width}
            maxHeight={maxParams.height}
            onImageLoaded={onLoaded}
          />

        </div>

      </ModalContent>
    </>
  );
};

export default CropContent;
