import React, { useMemo } from 'react';
import { ErrorMessage } from 'formik';
import Title from '../../../../components/Title/Title';
import Select from '../../../../components/Select';
import Tooltip from '../../../../components/Tooltip';
import Subtitle from '../../../../components/Subtitle/Subtitle';
import { Input } from '../../../../components/Input';
import Mandatory from '../../../../components/Mandatory';
import { translate } from '../../../../utils/translate';
import Row from '../../../ModalWebsite/components/Row';
import PageSelect from '../../../LeftSidebar/components/PagesTab/components/PageSelect';
import ErrorTooltip from '../../../ModalWebsite/components/ErrorTooltip';
import PageInputName from '../PageInputName';
import PageInputSlug from '../PageInputSlug';
import PageInputLink from '../PageInputLink';
import styles from './styles.module.scss';

const General = ({
  editingSlug, currentWebsite, contentTypes, currentPage, setEditingSlug,
}) => {
  const pageSelect = useMemo(() => (
    <PageSelect
      name="parent"
      placeholder={translate('Location')}
      webId={currentWebsite?.id}
      pageId={currentPage?.id}
      filterChild
    />
  ), [currentPage, currentWebsite]);

  return (
    <section>

      <Title className={styles.title}>{ translate('General') }</Title>

      <Subtitle>

        { translate('Name') }

        { ' ' }

        <Mandatory title={translate('Mandatory')} />

        <Tooltip
          title={translate('Give this page a name to define its purpose. Example: "Page dryft.se/index"')}
        />

      </Subtitle>

      <Row
        first={(
          <PageInputName
            editingSlug={editingSlug}
          />
        )}
        second={<ErrorMessage name="name" component={ErrorTooltip} />}
      />

      <Subtitle>

        { translate('Name for URL') }

        { ' ' }

        <Mandatory title={translate('Mandatory')} />

        <Tooltip
          title={translate('Slug - is the name of the page that is currently not available. Example: index. Note that Slug automatically copies the name of redirect, but you can change it once you unchecked the box beside.')}
        />

      </Subtitle>

      <Row
        first={(
          <PageInputSlug
            editingSlug={editingSlug}
            setEditingSlug={setEditingSlug}
          />
        )}
        second={<ErrorMessage name="slug" component={ErrorTooltip} />}
      />

      <Subtitle>

        { translate('Location') }

        <Tooltip
          title={translate('Specify the path where absent page was or should be located. Example: dryft.se. Note that Location + Slug should create a link to unavailable page from which user should be redirected to other page.')}
        />

      </Subtitle>

      <Row
        first={pageSelect}
        second={(
          <ErrorMessage name="parent" component={ErrorTooltip} />
        )}
      />

      <Subtitle>

        { translate('Link') }

        <Tooltip
          title={translate('Check that final link "Link" is the same that a link on unavailable page, from which user should be redirected to other page.')}
        />

      </Subtitle>

      <Row
        first={(
          <PageInputLink
            placeholder={translate('Link')}
            currentWebsite={currentWebsite}
          />
        )}
        second={<ErrorMessage name="link" component={ErrorTooltip} />}
      />

      <Subtitle>
        { translate('Content type') }
        { ' ' }
        <Mandatory title={translate('Mandatory')} />
      </Subtitle>

      <Row
        first={(
          <Select
            name="content_type"
            id="content_type"
            options={contentTypes}
            placeholder={translate('Content type')}
            dark
          />
        )}
        second={(
          <ErrorMessage name="content_type" component={ErrorTooltip} />
        )}
      />

    <Row
        first={(
          <Input
            type="checkbox"
            name="allow_iframe"
            id="allow_iframe"
            label="Allow this page to open in IFRAME"
            invert
          />
        )}
      />

      
      <Row
        first={(
          <Input
            type="checkbox"
            name="show_in_menu"
            id="show_in_menu"
            label="Show in menu"
            invert
          />
        )}
      />

      <Subtitle>
        { translate('Menu label') }

        <Tooltip
          title={translate(`You can enter Menu item name in following format: Name1\\Name2\\Name3, where:<br>
                                 Name1 - menu item of the first level. It will be placed right on the menu panel.<br>
                                 Name2 - menu item of the second level, sub-menu. It will be placed within dropdown list of Name1 menu item.<br>
                                 Name3 - menu item of the third level. It will be placed within dropdown list of Name2 menu item.<br>
                                 You may use only Name1 or Name1\\Name2 menu levels as well.`)}
        />
      </Subtitle>

      <Row
        first={(
          <Input
            type="text"
            name="label"
            id="label"
            placeholder={translate('Menu label')}
            styleType="2"
          />
        )}
        second={<ErrorMessage name="label" component={ErrorTooltip} />}
      />

      {/* <hr className={styles.hr} />*/}
    </section>
  );
};

export default General;
