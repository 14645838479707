import React, { useState, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import OutsideClickHandler from 'react-outside-click-handler';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ReactComponent as ArrowUpIcon } from '../../../../assets/icons/arrow-up.svg';
import { ReactComponent as ArrowDownIcon } from '../../../../assets/icons/arrow-down.svg';
import ButtonSVG from '../../../../components/ButtonSVG';
import { InputSearch } from '../../../../components/InputSearch';
import { translate } from '../../../../utils/translate';
import useSiteSelect from '../../../../hooks/useSiteSelect';
import { useWebsitesTab } from '../../../../hooks/useWebsitesTab';
import SiteTitle from './components/SiteTitle';
import styles from './styles.module.scss';

/* eslint-disable react/jsx-props-no-spreading */
export const SiteSelect = () => {
  const { selectedWebsiteId, selectedPageId, onSelectPage } = useSiteSelect();

  const { websites } = useWebsitesTab();

  const matchWebsite = useRouteMatch('/website/:webId');

  const matchPage = useRouteMatch('/website/:webId/page/:pageId');

  const match = matchPage || matchWebsite;

  const params = match ? match.params : {};

  const [isOpen, setOpen] = useState(false);

  const [value, setValue] = useState('');

  const onClick = useCallback(() => {
    setOpen(false);

    onSelectPage();
  }, [onSelectPage]);

  const handleClick = useCallback(() => setOpen(!isOpen), [isOpen]);

  const handleClose = () => setOpen(false);

  const getArrow = useMemo(() => (
    isOpen
      ? <ArrowUpIcon width={10} height={5} />
      : <ArrowDownIcon width={10} height={5} />
  ), [isOpen]);

  const getContent = useMemo(() => (
    websites.length > 0 && websites.map(({ id, name }) => (
      <li
        key={id}
        onClick={onClick}
        className={styles.siteSelect_listItem}
      >

        <Link to={`/website/${id}`}>{name}</Link>

      </li>
    ))
  ), [websites, onClick]);

  return (
    <div className={styles.siteSelect_wrap}>

      <OutsideClickHandler
        onOutsideClick={handleClose}
      >

        <div
          onClick={handleClick}
          className={styles.siteSelect_header}
        >

          <p className={styles.siteSelect_choosen}>

            <SiteTitle
              {...params}
              websites={websites}
              websiteId={selectedWebsiteId}
              pageId={selectedPageId}
            />

          </p>

          <ButtonSVG className={styles.icon}>
            {getArrow}
          </ButtonSVG>

        </div>

        {isOpen && (
          <div className={styles.siteSelect_dropdown}>

            <InputSearch
              className={styles.search}
              onChange={setValue}
              value={value}
              placeholder={translate('Search website')}
            />

            <PerfectScrollbar>

              <ul className={styles.siteSelect_listWrap}>
                {getContent}
              </ul>

            </PerfectScrollbar>

          </div>
        )}
      </OutsideClickHandler>

    </div>
  );
};
