import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { templateSelector } from '../services/selectors/templates';
import { languagesSelector } from '../services/selectors/websites';
import createLoadingSelector from '../services/selectors/loading';
import { WEBSITES } from '../constants/type';
import { getLanguages } from '../services/actions/websites';
import {
  createTemplate, removeTemplate, editTemplate,
} from '../services/actions/templates';

const selectorTemplate = createSelector(
  languagesSelector,
  createLoadingSelector(WEBSITES.GET_LANGUAGES.base),
  templateSelector,
  (languages, languagesLoader, template) => ({
    languages,
    languagesLoader,
    template,
  }),
);

const useTemplate = (id) => {
  const selector = useCallback((store) => selectorTemplate(store, id), [id]);

  const data = useSelector(selector);

  useEffect(() => {
    const { loading, loaded, error } = data.languagesLoader;

    if (!loading && !loaded && !error) {
      getLanguages();
    }
  }, [data.languages, data.languagesLoader]);

  const onCreate = createTemplate;
  const onEdit = editTemplate;
  const onRemove = removeTemplate;

  return {
    ...data,
    onCreate,
    onEdit,
    onRemove,
  };
};

export default useTemplate;
