import React from 'react';
import AssetItem from '../AssetItem';

const AssetsList = ({ items, onChose }) => items.map((item) => (
  <AssetItem
    key={item.relative_path}
    item={item}
    onChose={onChose}
  />
));

export default AssetsList;
