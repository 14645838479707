import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { confirmAlert } from 'react-confirm-alert';
import { promptOptions } from '../../../../../../utils/promptOptions';
import styles from './styles.module.scss';

const MediaForm = ({ onRemove, children, hide }) => {
  const schema = Yup.object().shape({
    media: Yup.lazy((valueInput) => {
      if (typeof valueInput === 'string') {
        return Yup.string();
      }

      return Yup.mixed()
        .test('fileType', 'Unsupported File Format', (value) => value && ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'].includes(value.type))
        .required();
    }),
  });

  const handleSubmit = ({ media: data }, actions) => {
    confirmAlert(promptOptions({
      buttons: [
        {
          label: 'No',
        },
        {
          label: 'Yes',
          color: 'danger',
          onClick: () => onRemove(data, actions),
        },
      ],
    }));
  };

  return (
    <Formik
      initialValues={{
        media: [],
      }}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >

      <Form className={cn([styles.form, hide && styles.form_hide])}>
        {children}
      </Form>

    </Formik>
  );
};

MediaForm.propTypes = {
  onRemove: PropTypes.func.isRequired,
};

export default MediaForm;
