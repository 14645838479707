import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../Modal/Modal';
import ModalHeader from '../../Modal/componets/ModalHeader';
import ModalContent from '../../Modal/componets/ModalContent';
import ContentModal from './components/ContentModal';
import { translate } from '../../../utils/translate';
import styles from '../styles.module.scss';

/* eslint-disable react/jsx-props-no-spreading */
const FolderModal = (props) => {
  const { show, onClose } = props;

  const isEdit = show && (typeof show === 'number' || typeof show === 'string');

  const title = isEdit ? 'Edit folder' : 'New folder';

  return (
    <Modal
      show={Boolean(show)}
      classNameContainer={styles.modal}
      onClose={onClose}
    >

      <ModalHeader
        className={styles.header}
        title={translate(title)}
        onClose={onClose}
      />

      <ModalContent
        className={styles.content}
        tag="div"
      >

        <ContentModal
          isEdit={isEdit}
          {...props}
        />

      </ModalContent>

    </Modal>
  );
};

FolderModal.defaultProps = {
  directories: [],
  show: false,
  onRemove: undefined,
};

FolderModal.propTypes = {
  show: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]),
  directories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      name: PropTypes.string,
      parent: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        () => null,
      ]),
    }),
  ),
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
};

export default FolderModal;
