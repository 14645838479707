import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import {
  Tabs, TabList, Tab, TabPanel,
} from 'react-tabs';
import { ReactComponent as Save } from '../../../../assets/icons/save.svg';
import { Button } from '../../../../components/Button';
import ModalHeader from '../../../../components/Modal/componets/ModalHeader';
import ModalContent from '../../../../components/Modal/componets/ModalContent';
import { translate } from '../../../../utils/translate';
import General from '../General';
import Languages from '../Languages';
import FileManager from '../FileManager';
import WebsiteDefaults from '../WebsiteDefaults';
import SeoMeta from '../SeoMeta';
import styles from './styles.module.scss';
import Redirects from '../Redirects';

const ContentModal = ({
  isEdit, webId, domainOptions, languagesList, tabIndex, setTabIndex, onClose,
}) => {
  const { isValid, dirty } = useFormikContext();

  const general = useMemo(() => (
    <General
      domainOptions={domainOptions}
    />
  ), [domainOptions]);

  const languages = useMemo(() => (
    <Languages
      languagesList={languagesList}
      isEdit={isEdit}
    />
  ), [isEdit, languagesList]);

  const websiteDefaults = useMemo(() => <WebsiteDefaults />, []);

  const fileManager = useMemo(() => <FileManager webId={webId} />, [webId]);

  const redirects = useMemo(() => <Redirects webId={webId} />, [webId]);

  const tabs = [
    {
      title: translate('General'),
      content: general,
    },
    {
      title: translate('Languages'),
      content: languages,
    },
    {
      title: translate('Website defaults'),
      content: websiteDefaults,
    },
    {
      title: translate('Seo Meta'),
      content: <SeoMeta />,
    },
  ];

  if (isEdit) {
    tabs.push({
      title: translate('File manager'),
      content: fileManager,
    });
    tabs.push({
      title: translate('Redirects'),
      content: redirects,
    });
  }

  return (
    <Tabs
      selectedIndex={tabIndex}
      onSelect={setTabIndex}
      className={styles.tabs}
    >

      <ModalHeader title={translate(`${isEdit ? 'Edit' : 'Creating a new'} Website`)} onClose={onClose}>

        <div className={styles.headerWrap}>

          <TabList className={styles.tabList}>
            { tabs.map(({ title }) => (
              <Tab
                key={title}
                className={styles.item}
                selectedClassName={styles.active}
              >
                { title }
              </Tab>
            )) }

          </TabList>

          <Button type="submit" style={{ width: 104 }} disabled={!(isValid && dirty)}>
            <Save />
            { translate('Save') }
          </Button>

        </div>

      </ModalHeader>

      <ModalContent>

        { tabs.map(({ content }, i) => (
          <TabPanel key={i}>
            {content}
          </TabPanel>
        )) }

      </ModalContent>

    </Tabs>
  );
};

ContentModal.propTypes = {
  tabIndex: PropTypes.number.isRequired,
  setTabIndex: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  webId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  onClose: PropTypes.func.isRequired,
  languagesList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      iso: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default ContentModal;
