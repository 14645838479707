export const API_SIGN_IN_URL = 'web/auth/login/';

export const API_WEBSITE = {
  FILES_URL: '/web/website/static/',
  LIST_URL: 'web/website/',
  CATEGORIES_URL: 'web/website-category/',
  LANGUAGES_URL: '/web/language/',
  DOMAIN_URL: 'web/domain/',
};

export const API_PAGE_URL = 'web/page/';

export const API_PAGE_PREVIEW = (id, key) => `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_API_URL}/preview/page/${id}/?key=${key}`;
export const API_PAGE_REAL_PREVIEW = (id, key) => `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_API_URL}/preview/page/public/${id}/?key=${key}`;
// export const API_SNIPPET_PREVIEW = (id, key) => `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_API_URL}/preview/snippet/${id}/?key=${key}`;

export const API_SNIPPET_PREVIEW = '/preview/snippet/';

export const API_SNIPPETS = {
  LIST_URL: '/snippets/snippet/',
  CATEGORIES_URL: '/web/snippet-category/',
};

export const API_SNIPPETS_AT_PAGE_URL = '/snippets/snippet-at-page/';

export const API_MEDIA = {
  ALL_URL: '/web/media/',
  CATEGORIES_URL: '/web/media/catalog/',
  BIG_MEDIA: '/web/upload/',
};

export const API_REDIRECT = 'web/redirect/';
