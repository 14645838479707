import React from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { ReactComponent as Logo } from '../../../../assets/images/sign-in-logo.svg';
import { ReactComponent as Lock } from '../../../../assets/images/lock.svg';
import { Input } from '../../../../components/Input';
import { Button } from '../../../../components/Button';
import { translate } from '../../../../utils/translate';
import { useAuth } from '../../../../hooks/useAuth';
import styles from './styles.module.scss';

const SignIn = () => {
  const {
    loading,
    onSignIn,
  } = useAuth();

  const handleSubmit = (data, { setFieldError }) => {
    onSignIn(data, { setFieldError });
  };

  const initValue = {
    username: '',
    password: '',
    remember: false,
  };

  const schema = Yup.object().shape({
    username: Yup.string()
      .min(5)
      .required(),
    password: Yup.string()
      .min(5)
      .required(),
    remember: Yup.boolean(),
  });

  return (
    <div className={styles.container}>

      <Logo className={styles.logo} />

      <Lock className={styles.lock} />

      <h2 className={styles.title}>{translate('Log In')}</h2>

      <Formik
        onSubmit={handleSubmit}
        initialValues={initValue}
        validationSchema={schema}
        enableReinitialize
      >
        <Form noValidate>

          <span className={styles.errors}><ErrorMessage name="username" /></span>

          <Input
            name="username"
            id="username"
            placeholder="User name"
            autoComplete="current-username"
            className={styles.inputEmailContainer}
            classNameControl={styles.input}
            dark
          />

          <span className={styles.errors}><ErrorMessage name="password" /></span>

          <Input
            type="password"
            name="password"
            id="password"
            placeholder="Password"
            autoComplete="current-password"
            className={styles.inputPasswordContainer}
            classNameControl={styles.input}
            dark
          />

          <Input
            type="checkbox"
            name="remember"
            id="remember"
            label="Remember me"
            className={styles.inputRememberContainer}
          />

          <Button type="submit" loading={loading}>
            <Lock width={11} />
            {translate('Log In')}
          </Button>

        </Form>

      </Formik>

    </div>
  );
};

export default SignIn;
