import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../Modal/Modal';
import CropContent from './components/CropContent';
import { translate } from '../../../utils/translate';
import styles from './styles.module.scss';

const CropModal = ({
  show, title, src, props, onClose, onEdit,
}) => (
  <Modal
    classNameContainer={styles.container}
    show={Boolean(show)}
    onClose={onClose}
  >

    <CropContent
      title={title}
      src={src}
      props={props}
      onClose={onClose}
      onEdit={onEdit}
    />

  </Modal>
);

CropModal.defaultProps = {
  title: translate('Picture editor'),
  src: '',
};

CropModal.propTypes = {
  show: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  title: PropTypes.string,
  src: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default CropModal;
