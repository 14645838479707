export const sortItemsByName = (a, b) => {
  try {
    const charA = a.name[0].toLowerCase();
    const charB = b.name[0].toLowerCase();

    if (charA === charB) return ((a.name > b.name) ? 1 : -1);

    return (charA > charB) ? 1 : -1;
  } catch (error) {
    console.warn('sortItems empty filed name', error, a, b);
    return 0;
  }
};

export const sortByName = (a, b) => {
  try {
    const charA = a[0].toLowerCase();
    const charB = b[0].toLowerCase();

    if (charA === charB) return ((a > b) ? 1 : -1);

    return (charA > charB) ? 1 : -1;
  } catch (error) {
    console.warn('sortItems empty name', error, a, b);
    return 0;
  }
};

export const sortSnippets = (a, b) => {
  try {


    if (a.is_container && b.is_container) {
      return 0;
    }

    if (a.is_container || b.is_container) {
      return -1;
    }

    const charA = a.short_name[0] ?  a.short_name[0].toLowerCase() : '';
    const charB = b.short_name[0] ? b.short_name[0].toLowerCase() : '';

    if (charA === charB) return ((a.short_name > b.short_name) ? 1 : -1);

    return ((charA > charB) ? 1 : -1);
  } catch (error) {
    console.warn('sortItems empty filed short_name', error, a, b);
    return 0;
  }
};

export const sortItemsCategories = (a, b) => {
  try {
    const charA = a.name[0].toLowerCase();
    const charB = b.name[0].toLowerCase();

    if (charA === charB) return ((a.name > b.name) ? 1 : -1);

    return ((charA > charB) ? 1 : -1);
  } catch (error) {
    console.warn('sortItems empty filed name', error, a, b);
    return 0;
  }
};
