import React, {
  useMemo, useState, useCallback, Fragment,
} from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Collapsible from 'react-collapsible';
import { isArray } from 'lodash';
import styles from './styles.module.scss';

const sortItems = (a, b) => {
  if (a.category && b.category) return 0;
  if (!a.category && !b.category) return 0;
  return !b.category ? -1 : 0;
};

const AccordionWrap = ({
  item,
  children,
  noGap,
  isOpen,
  withBorder,
  wrapper,
  setOpening,
  setClosing,
}) => {
  const Wrapper = wrapper;
  // console.log('CHIDREN', item, children);

  const content = useMemo(() => (
    <Collapsible
      open={isOpen}
      lazyRender
      triggerTagName="div"
      triggerDisabled={!children}
      classParentString={styles.wrap}
      contentOuterClassName={cn(styles.content, { [styles.noGap]: noGap })}
      contentInnerClassName={cn(styles.inner, { [styles.withBorder]: withBorder })}
      trigger={React.cloneElement(item.header, { isOpen })}
      onOpening={setOpening}
      onClosing={setClosing}
    >
      {children}
    </Collapsible>
  ), [item, noGap, withBorder, isOpen, children, setOpening, setClosing]);

  return wrapper ? (
    <Wrapper
      item={item}
    >
      {content}
    </Wrapper>
  ) : content;
};

export const Accordion = ({
  item, withBorder, noGap, wrapper, onOpen,
}) => {
  const [isOpen, setOpen] = useState(false);

  const setClosing = () => setOpen(false);

  const setOpening = useCallback(() => {
    if (item && item.id) onOpen(item.id);
    // console.log('setOpening', item.content);
    setOpen(true);
  }, [onOpen, item]);

  const getContent = useMemo(() => (isArray(item.content)
    ? item.content.sort(sortItems).map((obj, index) => (!obj.header
      ? (
        <Fragment key={index}>
          {obj.content}
        </Fragment>
      )
      : (
        <Accordion
          key={index}
          item={obj}
          withBorder={withBorder}
          wrapper={wrapper}
          onOpen={onOpen}
        />
      )))
    : item.content
  ), [onOpen, withBorder, wrapper, item]);

  return useMemo(() => (item.header
    ? (
      <AccordionWrap
        item={item}
        noGap={noGap}
        wrapper={wrapper}
        isOpen={isOpen}
        withBorder={withBorder}
        setOpening={setOpening}
        setClosing={setClosing}
      >
        {getContent}
      </AccordionWrap>
    )
    : getContent
  ), [setOpening, isOpen, item, getContent, withBorder, noGap, wrapper]);
};

Accordion.defaultProps = {
  withBorder: true,
  noGap: false,
  wrapper: undefined,
  onOpen: () => {},
};

/* const objectShape = PropTypes.shape({
  header: PropTypes.element,
  content: PropTypes.element.isRequired,
}); */

Accordion.propTypes = {
  noGap: PropTypes.bool,
  withBorder: PropTypes.bool,
  wrapper: PropTypes.func,
  onOpen: PropTypes.func,
  // item: PropTypes.oneOf([objectShape, PropTypes.arrayOf(objectShape)]),
};
