import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export default function useAction(action) {
  const dispatch = useDispatch();

  return useCallback(
    (...params) => {
      dispatch(action(...params));
    },
    [action, dispatch],
  );
}
