import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

/* eslint-disable react/jsx-props-no-spreading */
const InputUpload = forwardRef((props, ref) => {
  const { name } = props;

  const [field = {}, , helpers = {}] = useField(name);

  const handleChange = (e) => {
    const file = e.currentTarget.files[0];

    helpers.setTouched();

    helpers.setValue(file);

    field.onBlur('');
  };

  return (
    <input
      type="file"
      ref={ref}
      name={name}
      onBlur={field.onBlur}
      onChange={handleChange}
      style={{ display: 'none' }}
      {...props}
    />
  );
});

export const InputUploadUnControlled = forwardRef((props, ref) => {
  const { onChange, ...rest } = props;

  return (
    <input
      {...rest}
      type="file"
      ref={ref}
      onChange={onChange}
      style={{ display: 'none' }}
    />
  );
});

InputUploadUnControlled.defaultProps = {
  onChange: () => {},
};

InputUploadUnControlled.propTypes = {
  onChange: PropTypes.func,
};

InputUpload.defaultProps = {
  name: '',
};

InputUpload.propTypes = {
  name: PropTypes.string,
};

export default InputUpload;
