import React, { useState, useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import { ReactComponent as AddDirectory } from '../../../../assets/icons/add-directory.svg';
import { ReactComponent as RedirectIcon } from '../../../../assets/icons/redirect.svg';
import { ReactComponent as Plus } from '../../../../assets/icons/plus.svg';
import ButtonSVG from '../../../../components/ButtonSVG';
import ButtonPanel from '../../../../components/Button/components/ButtonPanel';
import RedirectModal from '../../../../components/CustomModals/RedirectModal';
import { SidebarTab } from '../../../../components/SidebarTabs/SidebarTab';
import { InputSearch } from '../../../../components/InputSearch';
import { InputUnControlled } from '../../../../components/Input';
import { SidebarPanel } from '../../../../components/SidebarTabs/SidebarPanel';
import VirtualPageModal from '../../../../components/CustomModals/VirtualPageModal';
import { translate } from '../../../../utils/translate';
import { useWebsite } from '../../../../hooks/useWebsite';
import { useModal } from '../../../../hooks/useModal';
import { PAGES_ROUTE } from '../../../../constants/routes';
import ModalPage from '../../../ModalPage';
import PageList from './components/PageList';
import styles from './styles.module.scss';

const PagesTab = ({ hide }) => {
  const { params: { webId } } = useRouteMatch(PAGES_ROUTE) || { params: {} };

  const [modalRedirect, openModalRedirect, closeModalRedirect] = useModal(false);

  const [modalFolder, openModalFolder, closeModalFolder] = useModal(false);

  const [modalPage, openModal, closeModal] = useModal(false);

  const [search, setSearch] = useState('');

  const { currentWebsite } = useWebsite(+webId);

  const folderModal = useMemo(() => (
    <VirtualPageModal
      show={modalFolder}
      currentWebsite={currentWebsite}
      onClose={closeModalFolder}
    />
  ), [modalFolder, currentWebsite, closeModalFolder]);

  const pageModal = useMemo(() => (
    <ModalPage
      show={modalPage}
      currentWebsite={currentWebsite}
      onClose={closeModal}
    />
  ), [modalPage, currentWebsite, closeModal]);

  const redirectModal = useMemo(() => (
    <RedirectModal
      show={modalRedirect}
      currentWebsite={currentWebsite}
      onClose={closeModalRedirect}
    />
  ), [modalRedirect, currentWebsite, closeModalRedirect]);

  const [isByContent, setIsByContent] = useState(false);

  return (
    <SidebarTab
      title={translate('Pages')}
      hide={hide}
      iconsContent={(
        <>

          <ButtonSVG
            onClick={openModalRedirect}
          >
            <RedirectIcon width={20} height={20} />
          </ButtonSVG>

          <ButtonSVG
            onClick={openModalFolder}
          >
            <AddDirectory width={20} height={20} />
          </ButtonSVG>

        </>
      )}
      headerContent={(
        <>
          <InputSearch
            className={styles.search}
            value={search}
            onChange={setSearch}
            placeholder={translate('Search page')}
          />

          <br />

          <InputUnControlled
            checked={isByContent}
            type="checkbox"
            id="by content"
            name="by content"
            onChange={() => { setIsByContent(!isByContent); }}
            label={translate('By content')}
            styleType={3}
            invert
          />
        </>
      )}
    >
      <SidebarPanel>

        <PageList
          search={search}
          isByContent={isByContent}
          websiteId={currentWebsite?.id}
          openModal={openModal}
          openModalFolder={openModalFolder}
          openModalRedirect={openModalRedirect}
        />

      </SidebarPanel>

      <ButtonPanel
        title={translate('Create Page')}
        onClick={openModal}
      >

        <Plus width={24} height={24} />

        {translate('Create a new Page')}

      </ButtonPanel>

      {redirectModal}

      {folderModal}

      {pageModal}

    </SidebarTab>
  );
};

export default PagesTab;
