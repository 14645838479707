import { actionPage } from '../services/actions/pages';

const usePageStatus = () => {
  const onPublish = (requestData) => actionPage(requestData, 'publish');
  const onUnpublish = (requestData) => actionPage(requestData, 'unpublish');
  const onReset = (requestData) => actionPage(requestData, 'reset');

  return {
    onPublish,
    onUnpublish,
    onReset,
  };
};

export default usePageStatus;
